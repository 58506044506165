$ns: ".news-single";
$blockQuotePrimary: #f7f7f7;

@font-face {
	font-family: "ss-social-regular";
	src: url("../fonts/ss-social-regular.woff") format('woff');
}

.page-news-single {
	overflow-x: hidden;

	.layout,
	.footer {
		overflow-x: hidden;
	}

	.header {
		&:not(.-upscrolled) {
			.header__menu-link.-highlighted {
				&.-highlighted {
					&:hover {
						background-color: rgba($brandColor2, 0.2);
					}
				}
			}

			.complex-link.-skin-black {
				#{$ns} {
					&__line {
						background-color: $brandColor1;

						@include large {
							background-color: initial;
						}
					}
				}
			}
		}
	}

	.header.-upscrolled {
		.header__logo {
			.ico {
				color: $black;
			}
		}
	}
}

#twitter-widget-0,
#twitter-widget-1,
#twitter-widget-2 {
	width: 100% !important;
}

#{$ns} {

	&__main {
		position: relative;

		@include large {
			padding-left: 50%;
		}

		&-cover {
			position: relative;

			&:before {
				content: "";
				display: block;
				padding-top: 100%;

				@include large {
					content: initial;
					display: block;
					padding-top: auto;
				}
			}

			@include large {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 50%;
				height: 100vh;

				&.-fixed {
					position: fixed;
				}
			}

			.cover {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}

		&-article {
			border-bottom: 0.1rem solid rgba($brandColor9, 0.25);
			padding-top: 5rem;

			@include medium {
				padding: 10.9rem 0 0 0;
			}

			@include large {
				padding: 20.5rem 17.95% 0 13.4%;
			}

			@include xxlarge {
				padding: 20.5rem 12% 0 13.4%;
			}

			.container {
				@include large {
					width: 100%;
				}
			}

			&-heading {
				padding-bottom: 5rem;

				@include medium {
					padding-bottom: 2.3rem;
				}

				.subhead {
					margin-bottom: 0.8rem;
					margin-top: 9rem;
				}

				h1 {
					font-weight: normal;
					font-family: $Ogg;
					color: $brandColor1;
					padding: 0;
					margin: 0 0 1rem;
					font-size: 2.8rem;
					line-height: 4rem;

					@include medium {
						font-size: 4.4rem;
						line-height: 6rem;
					}
				}

				.news-info {
					color: $brandColor4;
					font-size: 1.4rem;
					line-height: 2rem;

					@include medium {
						font-size: 1.7rem;
						line-height: initial;
					}
				}
			}

			&-body {
				font-size: 1.6rem;
				line-height: 2.9rem;
				letter-spacing: -0.01rem;
				padding-bottom: 8rem;

				ul {
					padding-left: 2rem;
				}

				h2 {
					padding: 0;
					margin: 5rem 0;
					font-weight: 600;
					font-size: 1.7rem;
					line-height: 2.9rem;
				}

				p,
				> div {
					margin: 0rem 0;
				}

				> p + p {
					margin-top: 4.5rem;
				}

				img {
					display: block;
					margin: 6rem 0 6rem 0rem;
					position: relative;
					left: 50%;
					transform: translateX(-50%);
					max-width: 100%;

					@include large {
						max-width: initial;
						width: 100%;
					}
				}

				hr {
					display: block;
					padding: 0;
					margin: 5.2rem 0;
					border: none;
					width: 100%;
					height: 0.1rem;
					background-color: rgba($brandColor9, 0.25);
				}


				blockquote {
					overflow: hidden;
					margin: 8rem 0 8rem;
					padding: 5.2rem !important;
					background-color: $blockQuotePrimary;
					color: $brandColor1;
					letter-spacing: 0;
					padding: 3.2rem;
					margin-left: -2rem;
					margin-right: -2rem;

					@include medium {
						padding: 0;
						margin-left: 0;
						margin-right: 0;
					}

					+ blockquote {
						margin-top: 0;
						padding-top: 0;
						border-top-width: 0;
					}

					&:last-child {
						margin-bottom: 0;
						padding-bottom: 0;
						border-bottom-width: 0;
					}

					p + p {
						margin-top: 3.9rem;
					}

					p:not(:last-child),
					p:only-child {
						font-family: $GTAmericaMono;
						font-style: italic;
						font-size: 1.8rem;
						font-weight: 300;
						line-height: 1.89;
						line-height: 3.4rem;
						opacity: 0.6;

						a {
							padding: 0 0.2rem 0 0;
						}
					}

					p:last-child:not(:only-child) {
						font-family: $SharpSans;
						float: right;
						right: 0;
						position: relative;
						white-space: pre;
						font-size: 1.7rem;
						line-height: 1.53;
						min-width: 21.9rem;
						opacity: 0.6;

						b,
						strong {
							font-weight: 600;
						}

						&:before {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							right: 100%;
							margin: 1.8rem 1.3rem 0 0;
							width: 3.1rem;
							height: 0.1rem;
							background-color: $brandColor1;	
						}
					}
				}

				> :first-child {
					margin-top: 0 !important;
				}

				> :last-child {
					margin-bottom: 0 !important;
				}

				.tagline {
					opacity: 0.35;
					font-family: $GTAmericaMono;
					font-size: 1.4rem;
					font-weight: 300;
					font-style: italic;
					font-stretch: normal;
					line-height: 1;
					letter-spacing: normal;
					color: $brandColor7;
					margin: -4rem 0rem 7rem 0rem;
				}

				h5 {
					font-family: $SharpSans;
					font-size: 1.7rem;
					font-weight: 600;
					line-height: 1.71;
					color: $brandColor7;
					margin: 5rem 0rem;
				}

				.features {
					min-width: 100%;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;

					@include medium {
						flex-wrap: nowrap;
					}

					.features__list-a,
					.features__list-b {
						flex: 1;
					}

					p {
						font-size: 1.6rem;
						font-weight: 500;
						line-height: 1.81;
						color: $brandColor7;
						padding-right: 17.5rem;
						letter-spacing: normal;
						text-decoration: underline;
					}
				}

			}

			.afterword {
				border-top: 0.1rem solid rgba($brandColor9, 0.25);
				padding: 7rem 0;

				@include medium {
					padding: 14.5rem 0;
				}

				&__heading {
					color: $brandColor4;
					font-weight: 300;
				}

				&_subheading {
					font-weight: 500;
				}

				&__links {
					display: flex;
					align-items: center;

					.complex-link__blank img {
						width: calc(100% + 0.2rem);
						margin-left: -0.1rem;
						margin-top: -0.1rem;
						margin-right: -0.1rem;
					}

					a {
						margin-right: 2.5rem;
						font-size: 1.7rem;
						color: $brandColor5;
					}
				}

				&__heading,
				&__subheading {
					font-size: 3rem;
					line-height: 4rem;

					@include medium {
						font-size: 3.4rem;
						line-height: 5.2rem;
					}
				}
			}
		}
	}

	&__alt {
		.container {
			width: 100%;
			margin-top: 4.8rem;
		}

		#{$ns}__main-article {
			border-bottom: 0.1rem solid rgba($brandColor9, 0.25);

			@include large {
				padding: 2.4rem;
			}

			&-body {
				font-size: 1.6rem;
				line-height: 2.9rem;
				letter-spacing: -0.01rem;
				padding-bottom: 0rem;
			}
		}

		#{$ns}__main-article-heading {
			padding-left: 2.4rem;

			.subhead {
				margin-bottom: 0.8rem;
				margin-top: 9rem;
			}

			h1 {
				font-weight: normal;
				font-family: $Ogg;
				color: $brandColor1;
				padding: 0;
				margin: 0 0 1rem;
				font-size: 2.8rem;
				line-height: 4rem;

				@include medium {
					font-size: 4.4rem;
					line-height: 6rem;
				}
			}
		}
		
		
		.news-list__announcement-headline {
			font-family: $Ogg;
			color: $brandColor1;
			font-size: 2.3rem;
			line-height: 3.5rem;

			@include medium {
				font-size: 3rem;
				line-height: 4.1rem;
			}

			@include xlarge {
				font-size: 3.2rem;
				line-height: 5.2rem;
			}
		}

		.news-list__social {
			display: block;

			@include large {
				display: initial;
			}
		}

		.news-list__social-inner {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			margin: 0 2.4rem;
			position: relative;
		}
	}

	&__related {
		padding: 11.4rem 0 14rem;

		.news-list {
			padding-top: 2.3rem;
		}

		&-without-headline {
			padding: 0rem;

			.news-list {
				padding-top: 0rem;
			}
		}
	}

	&__nav {
		border-top: 0.1rem solid rgba($brandColor9, 0.25);
		text-align: center;

		@include large {
			display: flex;
		}

		&-block {
			box-sizing: border-box;
			padding: 2rem;

			@include large {
				width: 50%;
			}

			& + & {
				border-top: 0.1rem solid rgba($brandColor9, 0.25);

				@include large {
					border-left: 0.1rem solid rgba($brandColor9, 0.25);
					border-top: initial;
				}
			}

			&-link {
				display: block;
				position: relative;
				height: 23rem;

				@include large {
					height: 46rem;
				}

				&-arrow {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					transition-property: opacity, visibility;
					transition-duration: $speed;
					line-height: 4rem;
					color: $brandColor1;
					padding-bottom: 1.8rem;
					white-space: nowrap;
					font-size: 3rem;

					@include medium {
						font-size: 3.4rem;
					}
				}

				&-preview {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					opacity: 0;
					visibility: hidden;
					transition-property: opacity, visibility;
					transition-duration: $speed;
					position: relative;

					&:after {
						content: "";
						display: block;
						position: absolute;
						z-index: 2;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba($brandColor1, 0.9);
					}

					.cover {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: 1;
					}

					&-title {
						display: block;
						position: relative;
						z-index: 3;
						color: $brandColor2;
						font-size: 2.55rem;
						line-height: 3.9rem;
						text-decoration: underline;
						margin: 2rem;
						font-size: 2rem;
						padding: 1.5rem;

						@include medium {
							font-size: initial;
							padding: 0;
						}
					}
				}

				&:hover & {
					&-arrow {
						opacity: 0;
						visibility: hidden;
					}

					&-preview {
						opacity: 1;
						visibility: visible;
					}

				}
			}
		}
	}

	&__share {
		height: 6rem;
		width: 30rem;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		background-color: $brandColor8;
		border: 0.02rem solid rgba($brandColor4, 0.3);
		text-align: center;
		font-size: 0;
		line-height: 0;
		margin-bottom: 8rem;

		@include medium {
			margin-bottom: 14.4rem;
		}

		&-title {
			display: flex;
			border-right: 0.1rem solid rgba($brandColor9, 0.25);
			padding-right: 2.8rem;
			height: 100%;
			justify-content: center;
			align-items: center;

			p {
				text-transform: uppercase;
				font-weight: 600;
				font-size: 1.1rem;
				line-height: 0.91;
				letter-spacing: 0.12rem;
				color: $brandColor4;
			}
		}

		a {
			display: flex;
			text-align: center;
			font-size: 0;
			line-height: 0;
			font-size: 1.6rem;
			font-family: "ss-social-regular", sans-serif;
			color: $brandColor7;
			height: 2.2rem;
			width: 2.2rem;
			position: relative;
		  	
			img {
				display: block;
				width: 100%;
				height: auto;
			}

			html.mobile & {
				background-color: $brandColor1;
			}

			html.no-mobile & {
				@include fluxBg;
			}
		}
	}
}

@-moz-document url-prefix() {
	#{$ns}__share a img {
		width: calc(100% + 0.2rem);
		margin-left: -0.1rem;
		margin-right: -0.1rem;
	}
}

@supports (-ms-ime-align: auto) {
	#{$ns}__share {
		justify-content: space-around;
	}
}

// isMobile library CSS override 

html.no-mobile #{$ns}__share a:not(:hover) {
	background: $brandColor1;
}
