$ns: ".dropdown";

#{$ns} {
    margin-bottom: 4rem;

    @include medium {
        margin-bottom: 9rem;
    }

    &__tabs {
        padding-bottom: 4rem;
    }

    &__category {

        & + & {
            border-top: 0.1rem solid rgba($brandColor1, 0.1);
        }

        &-header {
            display: flex;
            align-items: center;
            position: relative;
            padding: 3.5rem 0 3.6rem;
            border: none;
            background: none;
            width: 100%;
            text-align: left;
        }

        &-name {
            flex: 1 1 auto;
            font-weight: 500;
            line-height: 1em;
            font-size: 2.7rem;

            @include medium {
                font-size: 4rem;
            } 
        }

        &-openings {
            display: none;
            flex: 0 0 auto;
            width: 41.6rem;
            color: $brandColor7;
            font-weight: 500;
            font-size: 2rem;
            line-height: 2rem;
            padding-top: 0.4rem;

            @include medium {
                display: initial;
            }
        }

        &-toggler {
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1.7rem;
            width: 2rem;
            height: 2rem;
            font-size: 0;
            line-height: 0;
            margin-top: 0.4rem;
          
            i {
                display: block;
                position: absolute;
                background-color: $brandColor4;
                transition-property: transform, opacity;
                transition-duration: $speed * 2;
                transform: rotate(-90deg);
          
                &:first-child {
                    width: 0.2rem;
                    height: 100%;
                    top: 0;
                    left: 0.9rem;
                }
          
                &:last-child {
                    width: 100%;
                    height: 0.2rem;
                    top: 0.9rem;
                    left: 0;
                }
            }
        }

        &-body {
            overflow: hidden;
            max-height: 0;
            transition: max-height $speed * 2;
        }

        &-list {
            @include medium {
                float: right;
                width: 41.6rem;
            }

            &-item {
                border-top: 0.1rem solid rgba($brandColor1, 0.1);
                padding: 2.4rem 0 2.7rem;

                &-title {
                    font-weight: 600;
                    font-size: 2.2rem;
                    line-height: 2.4rem;
                }

                &-subtitle {
                    font-size: 2rem;
                }
            }
        }


        &.-active {
            #{$ns} {
                &__category {
                    &-toggler {
                        i {
                            transform: rotate(90deg);

                            &:last-child {
                                opacity: 0;
                            }
                        }
                    }

                    &-body {
                        max-height: none;
                    }
                }
            }
        }
          
    }
}