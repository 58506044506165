.pillars {
    @include medium {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 -2.9rem -1rem; 
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 0 0 2.3rem 0;

        @include medium {
            width: 33.33333%;
            padding: 2.1rem 0 3.4rem 1rem;
            box-sizing: border-box;
        }

        &-icon {
            flex: 0 0 auto;
            font-size: 0;
            line-height: 0;
            padding-right: 1.2rem;

            @include medium {
                padding-right: 1.5rem;
            }

            picture,
            img {
                width: 2.7rem;
                height: 2.7rem;

                @include medium {
                    width: 4.2rem;
                    height: 4.2rem;
                }
            }
        }


        &-value {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.6rem;

            @include medium {
                font-size: 2rem;
                line-height: 2.8rem;
            }
        }
    }
}

