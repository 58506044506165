.progressbar {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    height: 0.3rem;
    overflow: hidden;
    max-width: 100vw;

    &__line {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background-image: linear-gradient(to right, #00b2ff 0%, #6600cf 20%, #ff0000 40%, #ff9900 60%, #ffff00 80%, #00c800 100%);
    }
}
