$ns: ".page-title";

#{$ns} {
    color: $brandColor1;

    &__container {
        display: flex;
        flex-direction: column;
        margin-top: 6.8rem;
        margin-bottom: 9.3rem;
        padding: 2rem 0rem;

        @include medium {
            flex-direction: row;
            margin-top: 16.3rem;
            margin-bottom: 8.8rem;
            padding: 1rem 0rem;
            justify-content: flex-start;
        }
        
        &.-centered {
            @include medium {
                align-items: center;
            }
        }

        &.-inverted-mobile {
            flex-direction: column-reverse;

            @include medium {
                flex-direction: row;
            }

            #{$ns} {
                &__text {
                    padding-top: 0;
                }

                &__headlines {
                    padding-top: 2.5rem;
                }
            }
        }
    }

    h1,
    &__title {
        font-size: 4rem;
        line-height: 6rem;

        @include medium {
            font-size: 7rem;
            line-height: 4rem;
        }
    }
}
