.clients {
    text-align: center;
    margin: 5.2rem 0 9.1rem;

    @include medium {
        margin: 8.2rem 0 8rem;
    }

    &__group {
        & + & {
            margin-top: 5.6rem;

            @include medium {
                margin-top: 12.9rem;
            }
        }

        &-title {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.6rem;
            margin-bottom: 0.5rem;

            @include medium {
                font-size: 2rem;
                line-height: 3.1rem;
                margin-bottom: 3.4rem;
            }   
        }

        &-logos {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;

            @include medium {
                flex-wrap: nowrap;
                justify-content: center;
            }
        }

        &-logo {
            position: relative;
            width: 42%;
              
            &:last-child:nth-child(2n-1) {
                width: 100%;
            }

            @include medium {
                width: 20.3rem;

                &:last-child:nth-child(2n-1) {
                    width: 20.3rem;
                }

                & + & {
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 0.1rem;
                        height: 4rem;
                        margin-top: -2rem;
                        background-color: rgba($brandColor1, 0.1);
                    }
                }  
            }

            figure {
                display: flex;
                align-items: center;
                position: relative;
                height: 10rem;
                font-size: 0;

                @include medium {
                    padding: 0 1rem;
                }

                img {
                    display: block;
                    margin: auto;
                    max-width: 100%;
                }

                .resize {
                    display: block;
                    margin: auto;
                    max-width: 12.5rem;
                    max-height: 100%;
                    object-fit: contain;
                }
              
                figcaption {
                    display: none;
                    position: absolute;
                    bottom: -0.4rem;
                    left: 0;
                    font-weight: 500;
                    font-size: 1.1rem;
                    line-height: 1.6rem;
                    color: rgba($brandColor3, 0.64);
                    text-align: center;
                    width: 100%;

                    @include medium {
                        display: block;
                    }
                }
            }
        }     
    }
}
