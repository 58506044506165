$ns: ".fos";

#{$ns} {
    margin: -2.2rem;
    padding: 2.2rem;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    overflow: -moz-scrollbars-none;

    @include medium {
        margin: 0;
        padding: 0;
        overflow: visible;
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;

        @include large {
            width: auto;
            height: auto;
            opacity: initial;
            visibility: initial;
        }
    }

    &__links {
        display: grid;
        width: calc(300vw - 2.2rem * 5);
        border-right: 2.2rem solid transparent;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;

        @include medium {
            width: auto;
            border-right: initial;
        }

        @include large {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 1.1rem 1.3rem;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        background-color: $brandColor2;
        box-shadow: 0 0 0.6rem 0 rgba($brandColor1, 0.03), 0 0.3rem 1.5rem 0 rgba($brandColor1, 0.06);
        border-radius: 0.1rem;
        font-weight: 600;
        color: $brandColor1;
        transition-property: box-shadow, transform;
        transition-duration: $speed;
        font-size: 1.3rem;
        line-height: 1.8rem;
        height: 5rem;
        padding: 0 2rem 0.4rem;

        @include medium {
            font-size: 1.7rem;
            line-height: 2.8rem;
            height: 6.8rem;
            padding: 0 9.5% 0.4rem;
        }
         
        > span {
            display: block;
        }

        &-anchor {
            background: none;

            html.no-mobile & {
                @include fluxText;
            }
        }
    }
}

// isMobile library CSS override 

html.no-mobile #{$ns}__link:not(:hover) #{$ns}__link-anchor {
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: $brandColor1;
}

html.no-mobile #{$ns}__link:hover {
    box-shadow: 0 0.3rem 2rem 0 rgba($brandColor1, 0.17);
    transform: translateY(-0.5rem);
}