// ------------
// INDEX
// ------------
// ANCHOR • 01 • Fonts
// ------
// ANCHOR • 02 • Brand Colors
// ------
// ANCHOR • 03 • Social Colors
// ------
// ANCHOR • 04 • Animations
// ------
// ANCHOR • 05 • Breakpoints
// ------
// ANCHOR • 06 • Cloudfront CDN URL
// ------

// ANCHOR • 01 • Fonts
// ------

$sans: "Arial", sans-serif;
$serif: "Times New Roman", serif;
$SharpSans: "Sharp Sans No1", $sans;
$GTAmericaMono: "GT America Mono", $sans;
$Ogg: "Ogg", $serif;

// ANCHOR • 02 • Brand Colors
// ------

$black: #000000;
$white: #ffffff;

$text: $black;
$error: #ff001f;

$brandColor1: $black;
$brandColor2: $white;
$brandColor3: #717171;
$brandColor4: #a4a4a4;
$brandColor5: #ff00d5;
$brandColor6: #8a8a8a;
$brandColor7: #3c3c3c;
$brandColor8: #fdfdfd;
$brandColor9: #bfbfbf;
$brandColor10: #666666;

// ANCHOR • 03 • Social Colors
// ------

$facebook: '#3b5998';
$twitter: '#1da1f2';
$creativeMarket: '#8ba753';
$slack: '#e9a820';
$skype: '#00aff0';
$instagram: '#833ab4';
$dribbble: '#ea4c89';
$behance: '#1769ff';
$linkedin: '#0077b5';

// ANCHOR • 04 • Animations
// ------

$speed: 150ms;

// ANCHOR • 05 • Breakpoints
// ------

$small: 0; 
$smedium: 480px;
$medium: 768px; 
$mobile: 767px;
$tablet: 1023px;
$large: 1024px; 
$xlarge: 1280px; 
$xxlarge: 1440px;
$uber: 2000px;

// ANCHOR • 06 • Cloudfront CDN URL
// ------

$assetBaseUrl: "https://web-cdn-prod.levinriegner.com";

