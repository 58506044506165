$ns: ".brief";
$briefPrimary: #0e0f0f;

#{$ns} {
    width: 100%;
    position: relative;
    margin: auto;
    transition: opacity $speed * 4 $speed * 5;

    &__input {
        height: 5rem;
        position: relative;

        input {
            display: block;
            width: 100%;
            height: 100%;
            background: none;
            border: none;
            font: 500 1.7rem/2.4rem $SharpSans;
            color: $brandColor2;
            box-sizing: border-box;
            padding: 0 3rem 0.4rem 0;

            @include medium {
                padding: 0 10rem 0 0;
            }

            @include large {
                padding: 0 10rem 0.7rem 0;
            }

            &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
                color: $brandColor2;
            }

            &:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 3rem $briefPrimary inset;
                -webkit-text-fill-color: $brandColor2 !important;
            }
        }

        &-placeholder {
            pointer-events: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-bottom: 0.4rem;
            color: $brandColor2;
            font-weight: 500;
            font-size: 1.7rem;
            line-height: 2.4rem;
        
            b {
                font-weight: 600;
            }

            @include large {
                left: 0.7rem; 
            }
        }

        &-border {
            @include fluxBg;
            position: absolute;
            z-index: 4;
            bottom: 0;
            left: 0;
            overflow: hidden;
            width: 100%;
            height: 0.2rem;
            transition: height $speed * 3;

            &-gradient {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity $speed * 3;
            }

            .subhead {
                color: $brandColor2;
                position: absolute;
                left: 2rem;
                top: 50%;
                transform: translateY(100%);
                transition: transform $speed * 2 $speed * 2;
                font-size: 2rem;
                margin-top: -0.2rem;
            }
        }

    }

    &__control {
        position: relative;
        display: block;
        position: absolute;
        z-index: 3;
        transition-property: visibility, opacity;
        transition-duration: $speed * 2;
        top: 1.2rem;
        right: 0.5rem;
    }

    &__button {
        border: none;
        background: none;
        font: 500 1.7rem/2.4rem $SharpSans;
        display: block;
        height: 2.4rem;
        transition: color $speed * 3;
        color: rgba($brandColor2, 0.29);
      
        &[disabled] {
            pointer-events: none;
        }
    }

    &__error {
        position: absolute;
        display: none;
        color: $error;
        font-size: 1.4rem;
        line-height: 2.4rem;
        text-align: left;
        visibility: hidden;
        box-sizing: border-box;
      
        img {
          position: relative;
          top: 0.1rem;
          margin-right: 0.6rem;
          flex: 0 0 auto;
        }
      
        &.-active {
          display: flex;
          visibility: visible;
          padding-top: 0.9rem;
        }
    }

    &.-active {
        #{$ns}__input {
          &:before, 
          &:after {
            opacity: 0.5;
          }
    
          &:before {
            transform: translateX(-100%);
          }
    
          &:after {
            transform: translateX(100%);
          }
        }
    
        #{$ns}__control {
          transform: none;
        }
    
        #{$ns}__button {
          color: $brandColor3;
        }
    }

    &.-focused,
    &.-filled {
        #{$ns} {
            &__input-border {
                height: 0.2rem;
                &-gradient {
                    opacity: 1;
                }
            }
        }
    }

    &.-filled {
        #{$ns} {
            &__button {
                color: $brandColor2;
            }
            &__input-placeholder {
                display: none;
            }
        }
    }

    &.-sent {
        #{$ns} {
            &__control {
                opacity: 0;
                visibility: hidden;
            }

            &__input-border {
                height: 100%;

                &-gradient {
                    opacity: 1;
                }

                .subhead {
                    transform: translateY(-50%);
                }
            }
        }
    }
    
}
  