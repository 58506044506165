.infopage {
    padding: 7.8rem 0 10rem 0;

    @include medium {
        padding: 7.8rem 0 31.2rem 0;
    }

    &__date {
        color: $brandColor4;
        font-family: $GTAmericaMono;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 1.8rem;
        letter-spacing: -0.05rem;
        margin: -2.3rem 0 0.5rem;
    }

    &__cookies {
        font-size: 0;
        line-height: 0;
        text-align: center;
      
        img {
          display: inline-block;
          vertical-align: top;
          max-width: 100%;
        }
    }

    .text {
        padding-top: 1.4rem;
    }

    .grid + .grid {
        margin-top: 7.2rem;
    }
}
