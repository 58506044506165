$ns: ".section-gradient";

#{$ns} {
    @include fluxBg;
    color: $brandColor2;

    a {
        color: $brandColor2;
    }

    &__container {
        display: flex;
        flex-direction: column;
        padding-bottom: 9.2rem;

        @include medium {
            flex-direction: row;
            padding-bottom: 18.8rem;
            justify-content: space-between;
        }

        &.-centered {
            @include medium {
                align-items: center;
            }
        }

        &.-inverted-mobile {
            flex-direction: column-reverse;

            #{$ns}__text {
                padding-top: 0;
            }
            #{$ns}__headlines {
                padding-top: 2.5rem;
            }

            @include medium {
                flex-direction: row;

                #{$ns}__text {
                    padding-top: 0;
                }
                #{$ns}__headlines {
                    padding-top: 0;
                } 
            }
        }
    }

    &__headlines {
        img {
            display: block;
            max-width: 100%;
            margin-bottom: 1.5rem;
        }

        &.-spacious {
            margin-bottom: 5.3rem;

            @include medium {
                margin-bottom: 0;
            }
        }
    } 

    &__text {
        padding: 2.5rem 0 0 0;
        
        @include medium {
            flex: 0 0 auto;
            padding: 2rem 0 0 2rem;
            width: 43%;
        }

        @include large {
            width: 41.5rem;
        }

        &-more {
            margin-top: 4.4rem;

            @include medium {
                margin-top: 3.9rem;
            }
        }
    }

    &__offer {
        text-align: center;
        padding: 9.3rem 0 10rem;

        @include medium {
            padding: 10.4rem 0 11.9rem;
        }

        &-buttons {
            margin-top: 1.2rem;
        }

        &-button {
            display: inline-block;
            vertical-align: top;
            width: 20.2rem;
            height: 4.3rem;
            box-sizing: border-box;
            border: 0.1rem solid rgba($brandColor2, 0.5);
            border-radius: 0.1rem;
            text-transform: uppercase;
            color: $brandColor2;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 4.1rem;
            letter-spacing: 0.25rem;
            margin-left: 1.25rem;
            margin-right: 1.25rem;
            transition: background-color $speed;
            margin-top: 1rem;

            @include medium {
                margin-top: 2.9rem;
            }

            html.mobile & {
                color: $brandColor2;
            }

            &-value {
                html.no-mobile & {
                    @include fluxText;
                }
            }
        }
    }

    &:first-child #{$ns}__container {
        padding-top: 9rem;

        @include medium {
            padding-top: 16.3rem;
        }
    }

    &:last-child:not(.-black) {
        box-shadow: 0 0 1rem rgba($brandColor2, 0.15);
    }

    &.-black {
        background: $brandColor1;
        color: $brandColor2;
    }
}

// isMobile library CSS override 

html.no-mobile #{$ns}__offer-button:not(:hover) #{$ns}__offer-button-value {
    -webkit-text-fill-color: $brandColor2;
    background: none;
}
html.no-mobile #{$ns}__offer-button:hover {
    background-color: $brandColor2;
}