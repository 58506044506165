$ns: ".teaching-articles";

#{$ns} {
    height: 61rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: -0.5rem 0;

    &__entry {
        box-sizing: border-box;
        padding: 0.5rem 0;
        height: 33.33333%;
        transition: transform $speed;

        &-link {
            display: flex;
            align-items: center;
            color: $brandColor1;
            height: 100%;
            background-color: $brandColor2;
            box-shadow: 0.3rem 0.5rem 1.4rem rgba($brandColor1, 0.06);
        }

        &-preview {
            display: block;
            flex: 0 0 auto;
            width: 45.5%;
            height: 100%;
            background-position: 50% 50%;
            background-size: cover;
            object-fit: contain;
        }

        &-details {
            display: block;
            flex: 1 1 auto;
            margin: 0 2.5%;

            @include large {
                margin: 0 5.5%;
            }
        }

        &-title {
            display: block;
            position: relative;
            overflow: hidden;
            font-weight: 600;
            font-size: 1.7rem;
            line-height: 2.6rem;
            margin: 0.8rem 0 0 0;

            @include large {
                margin: 0 0 0.9rem 0;
            }
        }

        &-title,
        .news-info {
            html.no-mobile & {
                @include fluxText;
            }

            p {
                font-size: 1.3rem;
            }
        }
    }
}

// isMobile library CSS override 

html.no-mobile #{$ns}__entry-link:not(:hover) #{$ns}__entry-title {
    -webkit-text-fill-color: $brandColor1;
}

html.no-mobile #{$ns}__entry-link:not(:hover) .news-info {
    -webkit-text-fill-color: $brandColor6;
    background: none;
    -webkit-background-clip: initial;
}

html.no-mobile #{$ns}__entry-link:not(:hover) #{$ns}__entry-title {
    background: none;
    -webkit-background-clip: initial;
}

html.no-mobile #{$ns}__entry:hover {
    transform: translateY(-0.5rem);
}
