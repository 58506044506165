.tabs {
  border-bottom: 0.1rem solid rgba($brandColor1, 0.1);

  &__list {
    margin-bottom: -0.1rem;

    &-item {
      display: inline-block;
      vertical-align: top;
      border: none;
      background: none;
      border-bottom: 1px solid transparent;
      font: 500 1.6rem/2rem $SharpSans;
      padding: 1.4rem 0;
      letter-spacing: 0;
      margin-right: 2.7rem;

      &.-active {
        border-bottom-color: $brandColor1;
      }
    }

    > h1 {
      font-size: 1.2rem;
    }
  }
}
  