/* Mixins */
@mixin hover {
  html.no-mobile &:hover {
    @content;
  }
}
  
@mixin not-hover {
  html.no-mobile &:not(:hover) {
    @content;
  }
}
@mixin webfont($family, $file, $weight: 'normal', $style: 'normal') {
  @font-face {
    font-family: $family;
    src: url('../fonts/' + $file + '.woff2') format('woff2'),
    url('../fonts/' + $file + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin webfont2($family, $file, $weight: 'normal', $style: 'normal') {
  @font-face {
    font-family: $family;
    src: url('../fonts/' + $file + '.otf') format('opentype');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin fluxGradient($duration) {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient $duration linear infinite; 
}

@mixin fluxText($duration: 30s) {
  @include fluxGradient($duration);
  
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin fluxBg($duration: 30s) {
  @include fluxGradient($duration);
  background-color: #0369FB;
}

@mixin addArrow {
  border: none;
  &:after {
    content: "→";
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.6rem;
    transition: transform $speed linear
  }
  &:hover:after {
    @include fluxText();
    transform: translateX(0.2rem);
  }
}