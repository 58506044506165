$ns: ".teaching-tile";

#{$ns} {
    display: block;
    position: relative;
    color: $brandColor2;
    transition-property: opacity, transform;
    transition-duration: $speed;
    background-position: 50% 50%;
    background-size: cover;
    height: 47.7rem;

    @include medium {
        height: 100%;
    }

    .section:not(.aos-animate) & {
        background: none !important;
    }

    &__details {
        display: block;
        position: absolute;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 2.6rem 2.1rem;

        @include medium {
            padding: 1.6rem 3rem;
        }

        &-headline {
            display: block;
            font-family: $Ogg;
            font-size: 2.2rem;
            line-height: 3.4rem;
            padding-bottom: 1.6rem;

            @include medium {
                font-size: 3rem;
                line-height: 4.1rem;
                padding-bottom: 2rem;
            }
        }
    }    
}

// isMobile library CSS override 

html.no-mobile #{$ns}:hover {
    transform: translateY(-0.5rem);
    opacity: 0.85;
}
