$ns: ".brief-section";
$designBriefPrimary: #0f1010;
$designBriefSecondary: #0e0f0f;


#{$ns} {
    margin: 9.7rem 0 8.1rem;

    @include medium {
        margin: 15rem 0 19rem;
    }

    &__container {
        background-color: $designBriefPrimary;
        color: $brandColor2;
        border-radius: 0.3rem;
        position: relative;

        @include medium {
            padding: 7.5rem 5rem 0;
        }

        @include large {
            display: flex;
            padding: 10.7rem 10.4rem 0 0;
        }

        &-spine {
            position: absolute;
            bottom: 100%;
            background-color: $designBriefPrimary;
            border-radius: 0.3rem 0.3rem 0 0;
            width: 16.9rem;
            height: 0.9rem;
            left: 2.7rem;

            @include medium {
                width: 31.4rem;
                height: 1.3rem;
                left: 9.4rem;
            }

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                width: 0;
                height: 0;
                border-style: solid;
                margin: 0.1rem -0.1rem;
            }

            &:before {
                border-width: 0 0 1.3rem 1.5rem;
                border-color: transparent transparent $brandColor1 transparent;
                right: 100%;
            }

            &:after {
                border-width: 1.3rem 0 0 1.5rem;
                border-color: transparent transparent transparent $designBriefPrimary;
                left: 100%;
            }

        }   
    }

    &__title {
        flex: 0 0 auto;
        line-height: 1;
        font-family: $SharpSans;
        font-weight: 500;
        white-space: nowrap;
        font-size: 4rem;
        padding: 5rem 2.6rem 0;
       
        @include medium {
            font-size: 6rem;
            padding: 5rem 2.6rem 5rem 0;
        }
        
        @include large {
            padding: 0.2rem 10.4rem 0;
        }

        span {
            font-family: $Ogg;

            @include large {
                display: block;
            }

            @include xlarge {
                margin: 0 0.105em 0 -0.105em;
                display: initial;
            }
        }
    }

    &__info {
        padding: 3.4rem 2.8rem 0;

        @include medium {
            padding: 0;
        }
    }

    &__about {
        padding-bottom: 5rem;

        @include medium {
            padding-bottom: 5.6rem;
        }
    }

    &__form {
        width: 100%;
        position: relative;
        margin: auto;
        transition: opacity $speed * 4 $speed * 5;

        @include medium {
            padding-bottom: 2.2rem;
        }

        &-control {
            position: relative;
            display: block;
            position: absolute;
            z-index: 3;
            transition-property: visibility, opacity;
            transition-duration: $speed * 2;
            top: 1.8rem;
            right: 0;
        }

        &-button {
            border: none;
            background: none;
            font: 500 1.7rem/2.4rem $SharpSans;
            display: block;
            height: 2.4rem;
            transition: color $speed * 3;
            color: rgba($brandColor2, 0.29);

            &[disabled] {
                pointer-events: none;
            }
        }

        &-error {
            color: $error;
            font-size: 1.4rem;
            line-height: 2.4rem;
            text-align: left;
            visibility: hidden;
            box-sizing: border-box;
            height: 8rem;

            @include medium {
                height: 9.9rem;
            }

            img {
                position: relative;
                top: 0.1rem;
                margin-right: 0.6rem;
                flex: 0 0 auto;
            }

            &.-active {
                display: flex;
                visibility: visible;
                padding-top: 0.9rem;
            }
        }

        &-input {
            height: 5rem;
            position: relative;

            input {
                display: block;
                width: 100%;
                height: 100%;
                background: none;
                border: none;
                font: 500 1.7rem/2.4rem $SharpSans;
                color: $brandColor2;
                box-sizing: border-box;
                padding-right: 3rem;

                #{$ns}__form-input input {
                    @include medium {
                        padding-right: 10rem;
                    }
                }  

                @include medium {
                    padding-right: 0;
                }

                &::-webkit-input-placeholder {
                    color: $brandColor2;
                }

                &::-moz-placeholder {
                    color: $brandColor2;
                }

                &:-ms-input-placeholder {
                    color: $brandColor2;
                  }

                &:-moz-placeholder {
                    color: $brandColor2;
                }

                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 3rem $designBriefSecondary inset;
                }

                &:-webkit-autofill {
                    -webkit-text-fill-color: $brandColor2 !important;
                }
            }

            &-border {
                position: absolute;
                z-index: 4;
                bottom: 0;
                left: 0;
                overflow: hidden;
                width: 100%;
                height: 0.1rem;
                background-color: rgba($brandColor2, 0.15);
                transition: height $speed * 3;

                .subhead {
                    color: $brandColor2;
                    position: absolute;
                    left: 2rem;
                    top: 50%;
                    transform: translateY(100%);
                    transition: transform $speed * 2 $speed * 2;
                    font-size: 2rem;
                    margin-top: -0.2rem;
                }

                &-gradient {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: opacity $speed * 3;
                }
            }
        }

        &.-active {
            #{$ns}__form-input:before,
            #{$ns}__form-input:after {
                opacity: 0.5;
            }

            #{$ns}__form-input:before {
                transform: translateX(-100%);
            }

            #{$ns}__form-input:after {
                transform: translateX(100%);
            }

            #{$ns}__form-control {
                transform: none;
            }

            #{$ns}__form-button {
                color: $brandColor3;
            }
        }

        &.-focused-input-border,
        &.-filled #{$ns}__form-input-border {
            height: 0.2rem;
        }

        &.-focused-input-border-gradient,
        &.-filled #{$ns}__form-input-border-gradient {
            opacity: 1;
        }

        &.-filled #{$ns}__form-button {
            color: $brandColor2;
        }

        &.-sent {
            #{$ns}__form-control {
                opacity: 0;
                visibility: hidden;
              }

            #{$ns}__form-input-border {
                height: 100%;
            }

            #{$ns}__form-input-border-gradient {
                opacity: 1;
            }

            #{$ns}__form-input-border .subhead {
                transform: translateY(-50%);
            }
        }

    }
}