.teasr {
    padding: 1rem 0 0 0;

    @include medium {
        padding: 1.5rem 0;
    }

    &__cover {
        img {
            width: 100%;
        }

        margin-bottom: 2.9rem;

        @include medium {
            margin-bottom: 0;
        }
    }

    &__text {
        padding: 0 0 1rem 0;

        @include medium {
            padding: 0 0 0 17.2%;
        }

        h2 {
            margin-bottom: 2.3rem;

            @include medium {
                margin-bottom: 3.5rem;
            }
        }
    }

}
