$ns: ".complex-link";

#{$ns} {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    &__line {
        display: block;
        position: absolute;
        top: 85%;
        left: 0;
        width: 100%;
        height: 0.1rem;
        pointer-events: none;
    }

    &__blank {
        display: inline-block;
        vertical-align: middle;
        width: 1.3rem;
        height: 1.3rem;
        font-size: 0;
        line-height: 0;

        img {
            display: block;
        }

        html.mobile & {
            background-color: $brandColor4;
        }

        html.no-mobile & {
            @include fluxBg;
        }
    }

    &.-skin-white {
        color: $brandColor2;

        #{$ns}__line {
            background-color: $brandColor2;
        }   
    }

    &.-skin-gray {
        html.mobile & {
            color: $brandColor4;
        }

        html.no-mobile & {
            @include fluxText;
        }

        #{$ns} {
            &__line {
                @include fluxBg;
            }
        }
    }

    &.-skin-black {
        html.mobile & {
            color: $brandColor1;
        }

        html.no-mobile & {
            @include fluxText;
        }

        #{$ns} {
            &__line {
                @include fluxBg;
            }
        }
    }

    &.-skin-gradient {
        @include fluxText;

        #{$ns} {
            &__line {
                @include fluxBg;
            }

            &__blank {
                @include fluxBg;
            }
        }
    }

    &.-role-more {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.4rem;
        letter-spacing: 0.292rem;

        @include medium {
            font-size: 1.6rem;
            letter-spacing: 0.333rem;
        }
    }
}

// isMobile library CSS override 

html.no-mobile #{$ns}.-skin-gray:not(:hover) {
    -webkit-text-fill-color: $brandColor4;
    background: none;
}

html.no-mobile #{$ns}.-skin-black:not(:hover) {
    -webkit-text-fill-color: $brandColor2;
    background: none;
}

@include medium {
    html.no-mobile #{$ns}.-skin-black:not(:hover) {
        -webkit-text-fill-color: $brandColor1;
    }
}

html.no-mobile #{$ns}.-skin-gradient #{$ns}__blank:not(:hover) {
    @include fluxBg;
}

html.no-mobile #{$ns}:not(:hover) #{$ns}__blank {
    background:  $brandColor4;
}