$ns: ".news-list";
$featured: ".featured";
$searchOverlaySpeed: $speed * 3;

#{$featured}-tap {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;

    @include large {
        display: none;
    }
}

#{$ns} {
    padding-top: 9rem;

    @include medium {
        padding-top: 14rem;
    }

    &-without-headline {
        padding-top: 0rem;
    }

    &__search {
        margin-bottom: 2.4rem;

        &-toggler {
            position: relative;
        }

        &-handler {
            display: inline-block;
            vertical-align: top;
            position: relative;
            height: 3.5rem;
            border-radius: 1.75rem;
            box-shadow: 0.3rem 0.5rem 1.4rem rgba($brandColor1, 0.06);
            border: 0.1rem solid $brandColor2;
            background: none;
            color: rgba($brandColor1, 0.4);
            text-transform: uppercase;
            letter-spacing: 0.15rem;
            font-size: 1.2rem;
            font-weight: 600;
            padding: 0 1.8rem 0.2rem;
            box-sizing: border-box;
            transition-property: box-shadow, color, background-color, opacity, visibility;
            transition-duration: $speed;
          
            .ico {
                width: 1.2rem;
                height: 1.2rem;
                margin-right: 0.2rem;
          
                svg {
                    transition: none;
                }
            }

            &-value {
                display: inline-block;
                vertical-align: middle;
            }

            &:hover {
                color: $brandColor1;
  
                &:not(:active) {
                  box-shadow: 0.3rem 0.5rem 2rem rgba($brandColor1, 0.15);
                }
            }

            &.-opened {
                position: absolute;
                z-index: 120;
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                color: $brandColor2;
            
                &:hover {
                    color: $brandColor1;
                    background-color: $brandColor2;
                }
            }
        }


        &-overlay {
            @include fluxBg;
            position: fixed;
            z-index: 110;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            transition-property: opacity, visibility;
            transition-duration: $searchOverlaySpeed;
            padding-top: 28.1rem;
            box-sizing: border-box;

            &-form {
                position: relative;
                opacity: 0;
                transform: translateY(2rem);
                transition-property: transform, opacity;
                transition-duration: $searchOverlaySpeed;
                transition-delay: $searchOverlaySpeed;

                &-placeholder {
                    display: block;
                    transition-property: opacity, visibility;
                    transition-duration: $speed * 1.5;
                    transition-timing-function: linear;
                    height: 4.9rem;

                    @include medium {
                        height: initial;
                    }    
                }

                input {
                    display: block;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    border: none;
                    background: none;
                    color: $brandColor2;
                    font-family: $SharpSans;
                    font-weight: 600;
                    letter-spacing: -0.025rem;
                    height: 8.4rem;
                    font-size: 6.3rem;
                    top: -2.1rem;

                    @include medium {
                        height: 12rem;
                        font-size: 9rem;
                        top: -3rem;
                    }
                }

                &.-filled &-placeholder {
                    visibility: hidden;
                    opacity: 0;
                }

                &.-filled + #{$ns}__search-overlay-tip {
                    opacity: 1;
                    transform: none;
                }
            }

            &-tip {
                opacity: 0;
                transform: translateY(2rem);
                transition-property: transform, opacity;
                transition-duration: $searchOverlaySpeed;
                font-size: 1.5rem;
                line-height: 2.1rem;
                color: rgba($brandColor2, 0.75);
                padding-top: 3.1rem;
            }
        }

        &.-active & {
            &-handler {
                &.-closed {
                    opacity: 0;
                    visibility: hidden;
                }

                &.-opened {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &-overlay {
                opacity: 0.95;
                visibility: visible;

                &-form {
                    opacity: 1;
                    transform: none;
                }
            }
            
        }
    }

    &__list {
        @include large {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-flow: dense;
        }

        &-item {
            width: 100%;
            margin-bottom: 1rem;

            @include large {
                width: auto;
                margin-bottom: 0;
            }
        }
    }

    &__pinned,
    &__announcement {
        background-repeat: no-repeat;

        @include large {
            grid-column-end: span 3; 
        }
    }

    &__normal,
    &__social {
        @include large {
            box-sizing: border-box;
        }  
    }

    &__social {
        span[class] {
            display: block;
        }
    }

    &__pinned {
        color: $brandColor2;
        position: relative;
        background-size: auto 28.8rem;
        background-position: 50% 2rem;

        @include medium {
            background-size: auto 41.4rem;
            background-position: 50% 1.4rem;
        }

        @include large {
            min-height: 60rem;
            background-size: 56.6142% auto;
            background-position: right center;
        }

        @include xlarge {
            min-height: 60rem;
            background-size: 56.6142% auto;
        }

        a {
            color: currentcolor;
        }    
        
        .subhead {
            color: $brandColor2;
            margin-bottom: 0.6rem;
            font-size: 1.3rem;

            @include medium {
                margin-bottom: -0.4rem;
            }

            @include large {
                margin-bottom: 0.7rem;
                font-size: initial;
            }
        }

        .news-info {
            color: $brandColor4;
            font-size: 1.3rem;
            line-height: 2.4rem;
            opacity: 1;

            @include large {
                font-size: 1.7rem;
                line-height: 2.6rem;
                margin-bottom: 5.4rem;
                opacity: 0.5;
            }
        }

        &-body {
            padding: 32.6rem 1.8rem 3.3rem;

            @include medium {
                padding: 42.2rem 2.9rem 4.3rem;
            }

            @include large {
                width: 50%;
                padding: 5.3rem 0 5.3rem 5.8rem;
                box-sizing: border-box;
            }
        }

        &-headline {
            font-family: $Ogg;
            font-size: 2.5rem;
            line-height: 3.6rem;
            margin-bottom: 0.7rem;

            @include medium {
                font-size: 3.2rem;
                line-height: 4.3rem;
                margin-bottom: 0;
            }

            @include large {
                margin-bottom: 0.8rem;
            }

            @include xlarge {
                font-size: 4.6rem;
                line-height: 6rem;
            }   
        }

        &-more {
            display: none;

            @include large {
                display: initial;
            }

            a {
                display: inline-block;
                vertical-align: top;
                width: 27rem;
                height: 4.8rem;
                border: 0.2rem solid $brandColor2;
                text-align: center;
                font-weight: 600;
                font-size: 1.7rem;
                line-height: 4.2rem;
                background-clip: content-box;
                box-sizing: border-box;

                &:hover {
                    @include fluxBg(2s);
                    border-width: 0;
                    line-height: 4.6rem;
                }
            }
        }
    }

    &__announcement {
        background-repeat: no-repeat;
        background-position: 50% 4rem;
        background-size: auto 16rem;
        padding: 22.6rem 3.3rem 3rem;
        box-sizing: border-box;

        @include medium {
            background-position: 50% 5rem;
            background-size: auto 28.9rem;
            padding: 35rem 6.3rem 3.9rem;
        }

        @include large {
            display: flex;
            align-items: center;
            padding: 5rem 0;
            min-height: 50rem;
            background-position: 0% 0%; // set
            background-size: 66.4567% auto; // done
            padding: 0;
            box-sizing: content-box;
        }

        @include xxlarge {
            background-size: 66.4567% auto;
        }
        
        .subhead {
            margin: 0 0 2.1rem;

            @include medium {
                margin: 1.4rem 0 1.1rem;
            }

            &__title {
                font-size: 2.2rem;

                @include medium {
                    font-size: initial;
                }
            }
        }

        &-headline {
            font-family: $Ogg;
            color: $brandColor1;
            font-size: 2.3rem;
            line-height: 3.5rem;

            @include medium {
                font-size: 3rem;
                line-height: 4.1rem;
            }

            @include xlarge {
                font-size: 4rem;
                line-height: 5.2rem;
            }
        }

        &.-text-first {
            @include medium {
                background-position: right center;
            }

            #{$ns} {
                &__announcement {
                    &-body {
                        @include large {
                            width: 50%;
                            box-sizing: border-box;
                            padding-left: 5.3rem;
                        }

                        @include xxlarge {
                            padding-left: 10.6rem;
                        }   
                    }
                }
            }
        }

        &.-image-first {
            @include large {
                background-position: left center;
            }

            #{$ns} {
                &__announcement {
                    &-body {
                        @include large {
                            margin-left: 50%;
                            padding: 0 0 2.6rem 0.5rem;
                        }
                    }
                }
            }
        }
    }

    &__normal {
        .subhead {
            font-size: 1.3rem;
            margin-bottom: 0.8rem;

            @include medium {
                font-size: initial;
                margin-bottom: 0.6rem;
            }
        }

        .news-info {
            font-size: 1.4rem;
            line-height: 2rem;
            color: $brandColor4;
        }

        &-inner {
            display: block;
            box-shadow: 0.3rem 0.5rem 1.4rem rgba($brandColor1, 0.06);
            transition: box-shadow $speed;

            &:hover {
                &:not(:active) {
                    box-shadow: 0.3rem 0.5rem 2rem rgba($brandColor1, 0.15);
                }

                #{$ns}__normal-headline-copy {
                    transform: translateX(-100%);
                }

                #{$ns}__normal-headline-visible,
                #{$ns}__normal-headline,
                .news-info p,
                .news-info p + p:before
                .subhead__title,
                .subhead__title:after,
                .subhead__value {
                    @include fluxText;
                }
            }
        }

        &-preview {
            position: relative;
            overflow: hidden;
          
            &:before {
                content: "";
                display: block;
                padding-top: 50.480769%;
            }
          
            img {
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 100%;
            }
        }

        &-body {
            padding: 2.5rem 2.6rem 2.1rem;

            @include medium {
                padding: 2rem 2.8rem;
            }

            .headline {
                color: $black;
            }
        }

        &-headline {
            font-size: 1.7rem;
            line-height: 2.6rem;
            color: $brandColor1;
            position: relative;
            overflow: hidden;
            height: 7.8rem;
            margin-bottom: 3.8rem;

            @include medium {
                margin-bottom: 3.7rem;
            }

            &-copy {
                position: absolute;
                top: -0.2rem;
                left: 0;
                width: 100%;
                color: transparent;
                transition-property: transform, opacity;
                transition-duration: $speed;
            }

            &-visible {
                display: inline;
            }

        }
    }

    &__social {
        display: none;

        @include large {
            display: initial;
        }

        &-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            margin: 0 3.3rem;
            position: relative;
            #{$ns}__social-media,
            #{$ns}__social-media p,
            #{$ns}__social-link {
                html.no-mobile & {
                    @include fluxText;
                }
            }
        }

        &-media {
            color: $brandColor7;
            position: relative;
            padding-bottom: 3.6rem;

            img,
            p {
                display: block;
            }

            p {
                color: $brandColor7;
                font-size: 1.7rem;
                line-height: 3rem;
                max-height: 27rem;
                overflow: hidden;

                @include xxlarge {
                    font-size: initial;
                    line-height: initial;
                    max-height: none;
                    overflow: visible;
                }              
            }
        }

        &-link {
            font-size: 1.7rem;
            line-height: 1.8;
            position: absolute;
            overflow: hidden;
            bottom: 1.9rem;
            left: 0;
            color: $brandColor1;
        }
    }

    &__preloader {
        text-align: center;
        height: 20rem;
        line-height: 20rem;

        @include medium {
            height: 30rem;
            line-height: 30rem;
        }

        &-pluses {
            i {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                margin: 0 0.3rem;
                width: 1.4rem;
                height: 1.4rem;
                animation: spin linear infinite 1.5s;

                &:before, 
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    background-color: $brandColor1;
                }
                
                &:before {
                    top: 0;
                    left: 0.5rem;
                    width: 0.4rem;
                    height: 100%;
                }
                
                &:after {
                    top: 0.5rem;
                    left: 0;
                    width: 100%;
                    height: 0.4rem;
                }
                
                &:nth-child(1) {
                    opacity: 0.1;
                }
                
                &:nth-child(2) {
                    opacity: 0.2;
                    animation-delay: 0.5s;
                }
                
                &:nth-child(3) {
                    animation-delay: 1s;
                }
            }
        }

        &.-hidden {
            visibility: hidden;
            height: 7.1rem;

            @include medium {
                height: 10.6rem;
            }
        }
    }
}

// isMobile library CSS override 

html.no-mobile #{$ns}__social-inner:not(:hover) #{$ns}__social-link {
    -webkit-text-fill-color: $brandColor1;
}

html.no-mobile #{$ns}__social-inner:not(:hover) #{$ns}__social-media, 
html.no-mobile #{$ns}__social-inner:not(:hover) #{$ns}__social-media p {
    -webkit-text-fill-color: $brandColor7;
}

html.no-mobile #{$ns}__social-inner:not(:hover) #{$ns}__social-media, 
html.no-mobile #{$ns}__social-inner:not(:hover) #{$ns}__social-media p, 
html.no-mobile #{$ns}__social-inner:not(:hover) #{$ns}__social-link {
    background: none;
    -webkit-background-clip: initial;
}

