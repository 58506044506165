$ns: ".video-embed";

#{$ns} {

    &__iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__cover {
        margin-bottom: 2.9rem;

        @include medium {
            margin-bottom: 0;
        }
    }

    &__text {
        padding: 0 0 1rem 0;

        @include medium {
            padding: 0 0 0 17.2%;
        }

        #{$ns}__title {
            font-family: $SharpSans;
            font-weight: 600;
            color: $black;
            padding: 0;
            margin: 0 0 2.3rem 0;
            font-size: 2.7rem;
            line-height: 3.5rem;

            @include medium {
                margin: 0;
                font-size: 3.5rem;
                line-height: 1.3em;
              }

              @include large {
                font-size: 4rem;
                line-height: 1.3em;
            }
        }  
    }
}
  
