$ns: ".approach";

#{$ns} {
    width: 100%;
    margin: -0.8rem 0 0.5rem;

    @include medium {
        margin: -0.8rem 0 9.7rem;
    }

    &__headline {
        padding-bottom: 3.6rem;

        @include medium {
            text-align: center;
            padding-bottom: 10.4rem;
        }
    }

    &__infographic {
        width: 11rem;
        margin: auto;
        position: relative;
        
        @include medium {
            width: auto;
            margin: auto;
            position: static;
        }

        &-stages {
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
            position: absolute;
            top: 0;
            left: 100%;
            font-size: 0.7rem;
            line-height: 0.9rem;
            letter-spacing: 0.1rem;
            width: 6.4rem;

            @include medium {
                position: static;
                top: auto;
                left: auto;
                width: auto;

                display: flex;
                font-size: 1.3rem;
                line-height: 1.7rem;
                margin-bottom: 2.8rem;
                letter-spacing: 0.2rem;
            }

            @include xlarge {
                padding: 0 0.9rem 0 1.1rem;
            }
        }


        &-stage {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            @include medium {
                flex: 0 0 auto;
                height: 5.8rem;
            }

            &:nth-child(1) {
                height: 11.4rem;

                @include medium {
                    height: auto;
                    width: 20%;
                }
            }

            &:nth-child(2) {
                height: 26.7rem;

                @include medium {
                    height: auto;
                    width: 40%;
                }
            }

            &:nth-child(3) {
                height: 13rem;

                @include medium {
                    height: auto;
                    width: 20%;
                }
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 0.2rem;
                height: 5.8rem;
                background-image: url($assetBaseUrl + "/img/mission/approach/dots.svg");
                transform: rotate(90deg) scale(0.6);
                bottom: -3rem;
                left: 3.2rem;

                @include medium {
                    transform: none;
                    bottom: auto;
                    left: auto;

                    top: 0;
                    right: -0.1rem;
                }
            }

            p {
                padding: 0 1rem;
            } 
        }

        &-iterations {
            display: flex;
            align-items: center;
            flex-direction: column;

            @include medium {
                flex-direction: row;
                justify-content: space-between;
            }

            @include xlarge {
                padding: 0 4.2rem;
            }
        }

        &-iteration {
            position: relative;

            @include medium {
                max-width: 16.5%;
            }

            & + & {
                margin-top: 2.9rem;

                @include medium {
                    margin-top: 0;
                }
                
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 0.7rem;
                    height: 1.2rem;
                    background-image: url($assetBaseUrl + "/img/mission/approach/arrow.svg");
                    top: -2rem;
                    left: 50%;
                    transform: rotate(90deg) scale(0.55);
                    margin: 0 0 0 -0.4rem;

                    @include medium {
                        top: 50%;
                        left: -16%;
                        transform: none;
                        margin: 0.2rem 0 0 0;
                    }
                }
            }

            img {
                display: block;
                position: relative;
                max-width: 100%;
            }
            
            p {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                text-align: center;
                transform: translateY(-50%);
                line-height: 2.4rem;
                font-weight: 600;
                color: $brandColor1;
                font-size: 1.265rem;

                @include medium {
                    margin-top: 0.8rem;
                    font-size: 1.6rem;
                }

                @include large {
                    font-size: 2.3rem;
                }
            }

            @include medium {
                &:nth-child(2) img {
                    top: 0.4rem;
                }

                &:nth-child(3) img,
                &:nth-child(4) img {
                    top: 0.7rem;
                }

                &:nth-child(5) img {
                    top: 0.9rem;
                }
            }

            &:nth-child(1) {
                width: 10.3rem;
            }
          
            &:nth-child(2) {
                width: 10.7rem;
            }
          
            &:nth-child(3) {
                width: 10.4rem;
            }
          
            &:nth-child(4) {
                width: 10.6rem;
            }
          
            &:nth-child(5) {
                width: 10.4rem;
            }

            @include medium {
                &:nth-child(1) {
                    width: auto;
                }
              
                &:nth-child(2) {
                    width: auto;
                }
              
                &:nth-child(3) {
                    width: auto;
                }
              
                &:nth-child(4) {
                    width: auto;
                }
              
                &:nth-child(5) {
                    width: auto;
                }
            }
        }

        &-group {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: absolute;
            bottom: 5.3rem;
            right: 100%;
            height: 39.5rem;
            margin: 0 0.3rem 0 0;

            @include medium {
                flex-direction: row;
                position: relative;
                bottom: auto;
                right: auto;
                height: auto;
                margin: 6.4rem 8% 0 28.9%;
            }

            @include xlarge {
                margin: 6.4rem 10.4% 0 30.25%;
            } 

            &-title {
                text-transform: uppercase;
                font-weight: 600;
                white-space: nowrap;
                flex: 0 0 auto;
                text-align: center;
                font-size: 0.7rem;
                line-height: 0.9rem;
                letter-spacing: 0.108rem;
                padding: 0.4rem 0;

                @include medium {
                    text-align: initial;
                    font-size: 1.1rem;
                    line-height: 1.7rem;
                    letter-spacing: 0.2rem;
                    padding: 0 0.9rem;
                }

                @include large {
                    padding: 0 1.2rem;
                    font-size: 1.3rem;
                }
            }

            &-corner {
                flex: 1 1 auto;
                background-color: $brandColor2;
                position: relative;
                font-size: 0;
                line-height: 0;
                width: 0.1rem;

                @include medium {
                    width: auto;
                    height: 0.2rem;
                }

                &.-left {
                    #{$ns}__infographic-group-pointer {
                        &:first-child {
                            top: 0;

                            @include medium {
                                top: auto;
                                left: 0;
                            }
                        }

                        &:last-child {
                            bottom: 5.3rem;

                            @include medium {
                                bottom: 0;
                                right: 6%;
                            }

                            @include large {
                                right: 14%;
                            }
                        }
                    }
                }

                &.-right {
                    #{$ns}__infographic-group-pointer {
                        &:first-child {
                            top: 5.3rem; 

                            @include medium {
                                top: auto;
                                left: 6%;
                            }

                            @include large {
                                left: 14%;
                            }
                        }

                        &:last-child {
                            bottom: 0;

                            @include medium {
                                bottom: 0;
                                right: 0;
                            }
                        }
                    }
                }
            }

            &-pointer {
                position: absolute;
                background-color: $brandColor2;
                height: 0.1rem;
                width: 1.6rem;
                left: 0;

                @include medium {
                    height: 3.7rem;
                    width: 0.2rem;
                    left: auto;
                    bottom: 0;
                }

                &-arrow {
                    display: block;
                    position: absolute;
                    width: 0.7rem;
                    height: 1.2rem;
                    background-image: url($assetBaseUrl + "/img/mission/approach/arrow.svg");
                    transform: scale(0.55);
                    top: -0.6rem;
                    right: -0.2rem;

                    @include medium {
                        transform: rotate(-90deg);
                        top: -0.4rem;
                        left: -0.3rem;
                        right: auto;
                    }
                }
            }
        }       
    }
}