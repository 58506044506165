.author {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $brandColor6;
  
    b {
      font-weight: 600;
    }

    p {
        &,
        + p:before {
            display: inline;
            vertical-align: baseline;
        }

        + p:before {
            content: "/";
            padding: 0 0.55em 0 0.35em;
        }
    }
}