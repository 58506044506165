$ns: ".header";

#{$ns} {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	transition: background-color 0.5s ease;

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 5.7rem;

		@include medium {
			height: 9.8rem;
			transition: height $speed * 2;
			will-change: height;
		}
	}

	&__logo {
		font-size: 0;
		line-height: 0;
		position: relative;
		z-index: 3;

		@include medium {
			position: static;
			z-index: auto;
			flex: 0 0 auto;
		}

		a {
			display: block;
			border: none;
			color: currentColor;
			transition: opacity $speed * 2;

			&:hover {
				opacity: 0.8;
			}
		}

		.ico {
			width: 5rem;
			height: 2.1rem;

			@include medium {
				width: 6rem;
				height: 2.5rem;
			}
		}

		.-white {
			color: $brandColor2;
		}
		
		.-black {
			color: $brandColor1;
		}
	}

	&__fader {
		content: "";
		display: block;
		position: fixed;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 12rem;
		background: linear-gradient(to bottom, rgba($brandColor1, 1) 0%, rgba($brandColor1, 1) 30%, rgba($brandColor1, 0) 100%);
		opacity: 0;
		visibility: hidden;
		transition-property: opacity, visibility;
		transition-duration: $speed * 2;

		@include medium {
			content: "";
			display: block;
			position: static;
			z-index: auto;
			top: auto;
			left: auto;
			width: auto;
			height: auto;
			background: initial;
			opacity: 1;
			visibility: hidden;
			transition-property: all;
			transition-duration: 0s;
		}
	}

	&__overlay {
		position: fixed;
		z-index: 1;
		background-color: $brandColor1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		transition-property: opacity, visibility;
		transition-duration: $speed * 2;
	}

	&__burger {
		display: block;
		position: relative;
		z-index: 3;
		border-style: solid;
		border-width: 0.1rem;
		background: none;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		font-size: 0;
		line-height: 0;
		color: rgba($brandColor2, 0.5);
		transition: all $speed * 2;

		@include medium {
			display: none;
		}

		.ico {
			width: 2.2rem;
			height: 2.2rem;
			position: relative;
			top: -0.2rem;
			left: -0.2rem;
			opacity: 0;
			visibility: hidden;
			transition-property: opacity, visibility;
			transition-duration: $speed * 2;
		}

		&.-white {
			border-color: rgba($brandColor2, 0.5);
		}

		&.-black {
			border-color: rgba($brandColor1, 0.5);
		}
	}

	&__menu {
		font-weight: 600;
		font-size: 1.7rem;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		opacity: 0;
		visibility: hidden;
		transition-property: opacity, visibility, transform;
		transition-duration: $speed * 2;
		background-color: $brandColor1;

		@include medium {
			font-size: 1.6rem;
			position: static;
			z-index: auto;
			top: auto;
			left: auto;
			width: auto;
			height: auto;
			overflow: visible;
			-webkit-overflow-scrolling: auto;
			opacity: 1;
			visibility: visible;
			transition-property: all;
			transition-duration: 0s;
			background-color: initial;
		}

		&-item {
			margin: 0 0 3.4rem 0;

			@include medium {		
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0 2.8rem;
			}

			a {
				color: $brandColor2 !important;
			}

			&.-active {
				opacity: 0.5;
			}
		}

		&-links {
			padding: 10.4rem 2.2rem 5rem;

			@include medium {
				padding: 0;
			}
		}

		&-link {
			background: none;
			-webkit-text-fill-color: currentColor;
			transition-property: background-color;
			transition-duration: $speed * 2;
			border: none;
			background: none;
			color: $brandColor2;
			-webkit-text-fill-color: $brandColor2;

			&.-highlighted {
				padding: 0.5rem 1.4rem;
				background-color: rgba($brandColor2, 0.1);
			}

			@include medium {
				&.-skin-white {
					color: $brandColor2;

					&.-highlighted {
						background-color: rgba($brandColor2, 0.1);

						@include hover {
							background-color: rgba($brandColor2, 0.2);
						}
					}
				}

				&.-skin-black {
					color: $brandColor1;
					-webkit-text-fill-color: $brandColor1;

					&.-highlighted {
						background-color: rgba($brandColor1, 0.03);

						@include hover {
							background-color: rgba($brandColor1, 0.06);
						}
					}
				}
			}		
		}
	}

	&.-active {
		transform: none !important;

		#{$ns} {
			&__logo {
				color: $brandColor2 !important;
			}

			&__burger {
				border-color: transparent;

				.ico {
					opacity: 1;
					visibility: visible;
				}
			}

			&__overlay {
				transform: scale(1);
			}

			&__fader {
				opacity: 1;
				visibility: visible;
			}

			&__menu {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}	
	}

	&.-upscrolled:not(.-active) {
		transition: transform $speed * 2 ease-in-out;
		background-color: $brandColor8;
		border-bottom: 0.1rem solid rgba($black, 0.1);

		#{$ns}__container {
			@include medium {
				height: 6.4rem;
			}
		}

		#{$ns}__logo,
		#{$ns}__menu-link,
		.complex-link {
			color: $brandColor1 !important;
			-webkit-text-fill-color: $brandColor1 !important;
		}

		.complex-link__line {
			background-color: $brandColor1 !important;
		}

		#{$ns}__menu-link.-highlighted {
			background-color: rgba($brandColor1, 0.1);

			&:hover {
				background-color: rgba($brandColor1, 0.2);
			}
		}

		#{$ns}__burger {
			border-color: rgba($brandColor1, 0.5);
		}
	}
}
