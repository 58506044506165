$ns: ".news-category";
#{$ns} {
    .news-list {
        padding-top: 0rem;
        padding-bottom: 9rem;
    }
    @include medium {
        .news-list {
            padding-bottom: 14rem;
        }
    }
}