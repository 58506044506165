.header-landing {
    position: fixed;
    z-index: 100;
    top: 3rem;
    left: 0;
    width: 100%;
    text-align: right;
    font-weight: 600;
    font-size: 1.7rem;

    @include medium {
      font-size: 1.6rem;
    }

    &__container {
        margin: auto;
        width: 88%;
        max-width: 126.8rem;
    }

    &__learn-more {
        display: inline;
      
        a {
          color: $brandColor1;
          padding: 0.5rem 2rem;
        }
    }

    &__contact {
        display: inline;
      
        a {
          display: inline-block;
          color: $brandColor1;
          width: 8.6rem;
          height: 3.1rem;
          transition-property: background-color;
          transition-duration: $speed;
      
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
    }

}