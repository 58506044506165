@use 'sass:math';

$workGcTextTriangle: #ffe4e4;
$workGcLinearGradient: #fafafa;

@keyframes work-gc-pulse {
    0% {
        transform: scale(0.8);
        opacity: 0.8;
    }
  
    80% {
        opacity: 0.5;
    }
  
    100% {
        transform: scale(2.2);
        opacity: 0;
    }
}

.work-gc {
    background-color: $brandColor2;

    &__image {
        img {
            display: block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }

        &.-intro {
            .cover {
                height: 56rem;

                @include medium {
                    height: 50rem;
                }

                @include large {
                    height: 68rem;
                }

                @include xxlarge {
                    height: 80rem;
                }

                @include uber {
                    height: 110rem;
                }
            }
        }

        &.-breakthrough {
            img {
                height: 55rem;

                @include large {
                    height: initial;
                    width: 100%;
                }
            }

            .container {
                overflow: hidden;
                width: 94.5%;

                @include medium {
                    width: initial;
                }
            }
        }

        &.-newsfeed {
            img {
                width: 132%;
                height: auto;

                @include medium {
                    width: initial;
                    height: 57.7rem;
                }
            }
        }

        &.-during {
            img {
                width: 116%;

                @include medium {
                    height: 45rem;
                    width: initial;
                }
            }
        }

        &.-process {
            background-image: linear-gradient(to bottom, $workGcLinearGradient 0%, $brandColor2 86%, $brandColor2 100%);
            padding: 7.1rem 0 5.6rem;

            @include large {
                padding: 22.8rem 0 13.1rem;
            }

            .container {
                @include xlarge {
                    padding-left: 7.35%;
                }
            }

            h2 {
                margin-bottom: 8.1rem;

                @include large {
                    margin-bottom: 10.1rem;
                }
            }

            img {
                height: 34.2rem;

                @include medium {
                    height: 60.3rem;
                    margin-left: -2rem;
                }
            }
        }

        &.-keyboard {
            .container {
                @include large {
                    display: flex;
                }

                @include xlarge {
                    padding: 0 7.35%; 
                }
            }

            figure {
                display: block;

                @include large {
                    width: 50%;
                    box-sizing: border-box;
                    padding-right: 2.5rem;
                }

                &:last-child {
                    display: none;

                    @include large {
                        display: initial;
                    }
                }
            }

            img {
                max-width: 100%;

                @include large {
                    left: auto;
                    transform: none;
                    max-width: 80%;
                }

                @include xlarge {
                    max-width: 100%;
                }
            }
        }

        &.-ibeacon {
            img {
                min-width: 125%;
                min-height: 66.9rem;
                left: -9.4rem;
                transform: none;

                @include medium {
                    min-width: 0;
                    min-height: 0;
                    left: 50%;
                    transform: translateX(-40%);
                    height: 73.3rem;
                    margin-left: 2.1rem;
                }

                @include large {
                    transform: translateX(-50%);
                }
            }
        }
    }

    &__text {
        &.-assignment {
            margin: 10.4rem 0 11.7rem;

            @include medium {
                margin: 10.6rem 0 12.3rem;
            }

            @include large {
                margin: 30.6rem 0 23.6rem;
            }

            @include xlarge {
                margin: 26.8rem 0 27.2rem;
            }
        }

        &.-background {
            margin: 10.7rem 0 11.5rem;

            @include medium {
                margin: 10.6rem 0 12.3rem;
            }

            @include large {
                margin: 26.7rem 0 14.6rem;
            }

            @include xlarge {
                margin: 26.7rem 0 14.1rem;
            }
        }

        &.-challenge {
            margin: 10.3rem 0 9.9rem;

            @include medium {
                margin: 10.6rem 0 10.5rem;
            }

            @include large {
                margin: 26.7rem 0 26.5rem;
            }

            @include xlarge {
                margin: 26.2rem 0 26rem;
            }  
        }

        &.-breakthrough {
            margin: 9.9rem 0 10.5rem;

            @include medium {
                margin: 10.5rem 0 11.8rem;
            }

            @include large {
                margin: 26.5rem 0 25.3rem;
            }

            @include xlarge {
                margin: 26rem 0 12.5rem;
            }  
        }

        &.-analytics {
            padding: 19.3rem 0 22.2rem;
            position: relative;

            @include medium {
                padding: 29.6rem 0 32.9rem;
            }

            @include large {
                padding: 39rem 0 35.5rem;
            }

            @include xlarge {
                padding: 39rem 0 40.1rem;
            }

            @include xxlarge {
                position: initial;
            }

            .container {
                position: static;

                @include xxlarge {
                    position: relative;
                }
            }

            .work-single__text-triangle {
                border-top-color: $workGcTextTriangle;
            }
        }

        &.-keyboard {
            margin: 6.6rem 0 4.4rem;

            @include large {
                margin: 14.1rem 0 12.5rem;
            }
        }

        &.-ibeacon {
            margin: 14rem 0 -0.2rem;

            @include medium {
                margin: 14rem 0 3.2rem;
            }

            @include large {
                margin: 32.8rem 0 3.2rem;
            }

            a {
                display: inline-block;
                font-size: 1.7rem;
                color: $brandColor10;
                margin-top: 1.5rem;

                @include large {
                    margin-top: 1.3rem;
                }     
            }
        }
    }

    &__quote {
        .container {
            padding: 3rem 0;
            box-sizing: border-box;
            color: $brandColor2;
            position: relative;
            display: flex;
            align-items: center;
            min-height: 41rem;
            width: 92.2%;

            @include medium {
                min-height: 56.8rem;
            }

            @include large {
                width: 94.043%;
            }

            @include xlarge {
                width: 92.2%;
            }
        }

        .cover {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        .work-single__quote {
            position: relative;
            z-index: 2;
            margin: auto;

            &-text {
                text-align: center;
            }
        }

        &.-assignment {
            .work-single__quote {
                width: 100%;

                @include medium {
                    width: 75%;
                }

                @include large {
                    width: 67%;
                }

                &-text {
                    padding-bottom: 2.5rem;

                    @include medium {
                        padding-bottom: 5.1rem;
                    }

                    @include large {
                        padding-bottom: 5rem;
                    }
                }

                &-cite {
                    margin: 0 0 1.2rem 25.8%;
                    line-height: 1.3;

                    @include medium {
                        margin: 0 0 1.2rem 50.8%;
                    }
                }
                  
            }
        }

        &.-ibeacon {
            @include medium {
                margin-top: 10rem;
            }

            @include large {
                margin-top: 20.6rem;
            }

            .work-single__quote {
                width: 95%;

                @include large {
                    width: 75%;
                }

                &-text {
                    padding: 1.8rem 0rem 2.1rem 0rem;

                    @include medium {
                        padding: 0 0 7.1rem 0;
                    }

                    @include xlarge {
                        padding: 0 0 4rem 0;
                    }  
                }

                &-cite {
                    margin: 0 0 1.2rem 20.8%;

                    @include medium {
                        margin: 0 0 1.2rem 40.8%;
                    }
                }
            }
        }
    }

    &__background { 
        margin: -0.2rem 0;

        @include medium {
            margin: 0;
        }

        img {
            display: block;
            height: 100%;

            @include medium {
                height: initial;
                width: 100%;
            }
        }

        .container {
            width: 100%;

            @include medium {
                width: initial;
            }

            @include xlarge {
                padding-left: 7%;
            };
        }

        &-image {
            flex: 0 0 auto;
            border: 0.2rem solid $brandColor2;
            overflow: hidden;

            @include medium {
                border: initial;
                overflow: visible;
                padding: 0.5rem 0.6rem;
            }

            &.-top-left {
                height: 25.1rem;

                @include medium {
                    height: initial;
                    width: 35.47%;
                }
            }

            &.-top-right {
                height: 27.3rem;

                @include medium {
                    height: initial;
                    width: 53.805%;
                }
            }

            &.-bottom-left {
                display: none;

                @include medium {
                    display: initial;
                    width: 17.31%;
                }
            }

            &.-bottom-center {
                height: 27.3rem;
                  
                img {
                    position: relative;
                    left: -5rem;
                }

                @include medium {
                    height: initial;
                    width: 53.7%;
                  
                    img {
                        position: static;
                        left: initial;
                    }
                }  
            }

            &.-bottom-right {
                height: 25.1rem;

                @include medium {
                    height: initial;
                    width: 26.4781%;
                }
            }
        }

        &-top,
        &-bottom {
            flex-direction: row-reverse;
            justify-content: center;

            @include medium {
                flex-direction: initial;
                justify-content: initial;
            }
        }

        &-top {
            display: flex;
            align-items: flex-end;
        }

        &-bottom {
            display: flex;
        }
    }

    &__takeaction {  
        margin: 10.1rem 0 13.3rem;
        
        @include large {
            margin: 24.8rem 0 26.7rem;
        }

        .container {
            @include large {
                display: flex;
            }

            @include xlarge {
                padding-left: 7.35%;
            }
        }

        &-text {
            padding-top: 0.9rem;
            margin-bottom: 5.1rem;

            @include medium {
                margin-bottom: 0; 
            }

            @include large {
                margin-right: 9.2%;
                width: 36.5%;
                flex: 0 0 auto;
            }

            h4 {
                img {
                    width: 1.8rem;
                    margin: 0.2rem -0.1rem 0rem 0.2rem;

                    @include medium {
                        width: initial;
                        margin: 0 0.3rem 0 0.4rem;
                    }
                }
            }

            > p {
                margin: 2.9rem 0.2rem 3.6rem;

                @include medium {
                    margin: 3.1rem 0.7rem 5.8rem;
                }
            }

            dl {
                padding: 0;
                margin: 0 0 0 0.2rem;  

                @include medium {
                    display: flex;
                    flex-wrap: wrap;
                }

                @include large {
                    display: initial;
                    flex-wrap: initial;
                }
    
                dt {
                    width: 100%;
                    font-weight: 600;
                    padding: 0;
                    font-size: 1.7rem;
                    line-height: 4rem;
                    margin: 0 0 0.5rem;

                    @include medium {
                        font-size: 2.2rem;
                        line-height: 4.6rem;
                        margin: 0 0 0.3rem;
                    }   
                }

                dd {
                    padding: 0;
                    margin: 0 0 1.1rem;

                    @include smedium {
                        width: 50%;
                    }

                    @include medium {
                        margin: 0 0 0.7rem;
                    }

                    @include large {
                        width: initial;
                    }

                    figure,
                    p {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    figure {
                        width: 2.1rem;
                        font-size: 0;
                        line-height: 0;
                        text-align: center;
                        margin: 0.4rem 1.6rem 0 0;
                
                        img {
                            display: inline-block;
                            transform: scale(0.9);

                            @include medium {
                                transform: none;
                            }
                        }
                    }
                }
            }
        }  

        &-image {
            @include medium {
                text-align: center;
            }

            @include large {
                flex: 1 1 auto;
            }
            
            img {
                width: 120%;
                margin-left: -0.9rem;

                @include medium {
                    display: inline-block;
                    vertical-align: top;
                    max-width: 100%;
                    width: initial;
                    margin-left: 0;
                }
            }
        }
    }

    &__newsfeed {
        margin: 9rem 0 3.5rem;

        @include large {
            margin: 26.7rem 0 -1.1rem;
        }

        .container {
            @include xlarge {
                padding-left: 7.35%;
            }
        }

        &-text {
            position: relative;
            z-index: 2;

            h4 {
                img {
                    margin: 0 0.1rem;

                    @include medium {
                        width: 3.5rem;
                        margin: 0 0.8rem 0 -0.1rem;
                    }
                }
            }

            p {
                max-width: 41.5rem;
                margin-top: 0.3rem;
            }
        }
    }

    &__rewards {
        margin: 12.9rem 0 18.1rem;

        @include large {
            margin: 34.9rem 0 22.3rem;
        }

        .container {
            @include xlarge {
                padding: 0 7.35%;
            }
        }

        &-text {
            margin-bottom: 7.7rem;
            
            @include medium {
                margin-bottom: 3.3rem;
            }

            h4 {
                img {
                    &:first-child {
                        width: 2rem;
                        margin: 0 0 0 0.2rem;

                        @include medium {
                            width: initial;
                            margin: 0 1rem 0.2rem 0.3rem;
                        }
                    }

                    &:last-child {
                        display: none;

                        @include medium {
                            display: inline-block;
                            vertical-align: middle;
                            margin-top: 0.2rem;
                        }
                    }
                }
            }

            p {
                max-width: 50rem;
                margin: 3.1rem 0 0 0.3rem;
            }
        }

        &-covers {
            white-space: nowrap;
            position: relative;
            font-size: 0;
            line-height: 0;
            height: 48.2rem;

            @include medium {
                height: initial;
                margin-left: -0.5rem;
                padding-top: math.percentage((math.div(458, 1061)));
            }

            &-item {
                display: block;
                position: absolute;
                top: 0;
                left: 50%;

                @include medium {
                    top: initial;
                    left: initial;
                }

                img {
                    display: block;
                    position: relative;
                    z-index: 2;
                    width: 100%;
                    border-radius: 50%;
                    box-shadow: 0.3rem 0.6rem 2rem rgba(0, 0, 0, 0.15);
                }

                i {
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    width: 70%;
                    height: 70%;
                    margin: -35%;
                    background: url("/work/global-citizen-app/images/rewards-circle.png") no-repeat 50% 50%;
                    background-size: contain;
                    border-radius: 50%;
                    animation: work-gc-pulse 2s infinite linear;
                
                    &:last-child {
                      animation-delay: 1s;
                    }
                }

                &:nth-child(1) {
                    width: 8.2rem;
                    margin: 38.4rem 0 0 -17.7rem;

                    @include medium {
                        width: initial;
                        margin: initial;
                        top: math.percentage((math.div(206, 458)));
                        left: math.percentage((math.div(32, 1054)));
                        width: math.percentage((math.div(91, 1054)));
                    }
                }

                &:nth-child(2) {
                    width: 14rem;
                    margin: 34.2rem 0 0 -7.5rem;

                    @include medium {
                        width: initial;
                        margin: initial;
                        top: math.percentage((math.div(214, 458)));
                        left: math.percentage((math.div(151, 1054)));
                        width: math.percentage((math.div(156, 1054)));
                    }
                }

                &:nth-child(3) {
                    width: 11.3rem;
                    margin: 7.4rem 0 0 -26.4rem;

                    @include medium {
                        width: initial;
                        margin: initial;
                        top: math.percentage((math.div(112, 458)));
                        left: math.percentage((math.div(283, 1054)));
                        width: math.percentage((math.div(125, 1054)));
                    }
                }

                &:nth-child(4) {
                    width: 16.8rem;
                    margin: 19.4rem 0 0 -22.1rem;

                    @include medium {
                        width: initial;
                        margin: initial;
                        top: math.percentage((math.div(244, 458)));
                        left: math.percentage((math.div(332, 1054)));
                        width: math.percentage((math.div(187, 1054)));
                    }
                }

                &:nth-child(5) {
                    width: 20rem;
                    margin-left: -12.9rem;

                    @include medium {
                        width: initial;
                        margin-left: initial;
                        top: math.percentage((math.div(28, 458)));
                        left: math.percentage((math.div(436, 1054)));
                        width: math.percentage((math.div(221, 1054)));
                    }
                }

                &:nth-child(6) {
                    width: 10.5rem;
                    margin: 21.2rem 0 0 -2.6rem;

                    @include medium {
                        width: initial;
                        margin: initial;
                        top: math.percentage((math.div(265, 458)));
                        left: math.percentage((math.div(550, 1054)));
                        width: math.percentage((math.div(116, 1054)));

                    }
                }

                &:nth-child(7) {
                    width: 18.2rem;
                    margin: 8.8rem 0 0 8.1rem;

                    @include medium {
                        width: initial;
                        margin: initial;
                        top: math.percentage((math.div(126, 458)));
                        left: math.percentage((math.div(669, 1054)));
                        width: math.percentage((math.div(202, 1054)));
                    }
                }

                &:nth-child(8) {
                    width: 10.5rem;
                    margin: 29.6rem 0 0 7.8rem;

                    @include medium {
                        width: initial;
                        margin: initial;
                        top: math.percentage((math.div(206, 458)));
                        left: math.percentage((math.div(901, 1054)));
                        width: math.percentage((math.div(116, 1054)));
                    }

                    i {
                        width: 100%;
                        height: 100%;
                        margin: -50%;
                    }
                }
            }
        }
    }

    &__pre {
        @include medium {
            background-image: linear-gradient(to bottom, $workGcLinearGradient 0%, $brandColor2 86%, $brandColor2 100%);
            padding-top: 23.8rem;
        }

        .container {
            @include large {
                display: flex;
            }

            @include xlarge {
                padding-left: 7.35%;
            }
        }

        &-text {
            margin-bottom: 3.2rem;

            @include large {
                margin-bottom: 0;
                width: 45.2%;
                flex: 0 0 auto;
            }

            p {
                margin: 3.1rem 0 3.3rem 0;

                @include medium {
                    margin: 3.1rem 0 3.8rem 0;
                }
            }

            img {
                display: block;
                margin: auto;
                width: 84.5%;
                max-width: 40.2rem;

                @include large {
                    display: initial;
                    margin: initial;
                    width: initial;
                    max-width: 100%;
                }  
            }
        }

        &-image {
            text-align: center;

            @include large {
                text-align: right;
                flex: 1 1 auto;
                margin-top: 1.1rem;
            }

            @include xlarge {
                text-align: center;
            }

            img {
                display: inline-block;
                vertical-align: top;
                max-width: 95.5%;
                margin-left: -0.8rem;

                @include large {
                    margin-left: 0;
                    max-width: 80%;
                }

                @include xlarge {
                    max-width: 100%;
                }
            }
        }
    }

    &__during {
        margin: 8.5rem 0 6.7rem;

        @include large {
            margin: 21rem 0 7.3rem;
        }

        .container {
            @include xlarge {
                padding-left: 7.35%;
            }
        }

        p {
            max-width: 52rem;
            margin-top: 3.6rem;

            @include medium {
                margin-top: initial;
            }

            @include large {
                margin-top: 3.1rem;
            }
        } 
    }

    &__festivals {
        margin: 17.5rem 0 7.3rem;

        @include large {
            margin: 35.8rem 0 25.7rem;
        }

        .container {
            @include large {
                display: flex;
            }
        }

        &-block {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;

            @include large {
                width: 50%;
            }

            &:first-child {
                @include large {
                    padding-right: 0.5rem;
                }
            }

            &:last-child {
                padding: 1.6rem 0 0 0 ;

                @include large {
                    padding: 15.9% 0 0 0.5rem;
                }
            }

            &-item {
                width: 32%;

                img {
                    display: block;
                    width: 100%;
                }
                  
                figcaption {
                    display: block;
                    text-align: center;
                    font-weight: 500;
                    color: $brandColor4;
                    padding-top: 0.33em;
                    white-space: nowrap;
                    font-size: 1.1rem;
                    line-height: 2rem;

                    @include smedium {
                        font-size: 1.3rem;
                        line-height: 2.4em;
                    }

                    @include medium {
                        font-size: 1.6rem;
                    }

                    @include large {
                        font-size: 1.4rem;
                    }

                    @include xxlarge {
                        font-size: 1.5rem;
                    }   
                }
            }
        }
    }

    &__moments {
        margin-bottom: 9.8rem;

        @include large {
            margin-bottom: 26rem;
        }

        .container {
            @include large {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 0 7.35%;
            }
        }

        figure {
            display: block;
            font-size: 0;
            line-height: 0;
            margin-bottom: 2rem;

            @include large {
                margin-bottom: 0;
            }

            &:nth-child(1) {
                @include large {
                    width: math.percentage((math.div(523, 1056)));
                }

                img {
                    width: 74%;

                    @include large {
                        width: initial;
                    }
                }
            }

            &:nth-child(2) {
                text-align: right;

                @include large {
                    text-align: initial;
                    margin-top: 23rem;
                    width: math.percentage((math.div(307, 1056)));
                }

                img {
                    width: 50.2%;

                    @include large {
                        width: initial;
                    }
                }
            }

            &:nth-child(3) {
                @include large {
                    width: 100%;
                    text-align: center;
                    margin-top: 15rem;
                }
                
                img {
                    width: 100%;

                    @include large {
                        width: initial;
                    }
                }
            }

            &:nth-child(5) {
                text-align: right;

                @include large {
                    text-align: initial;
                    margin-top: 38.7rem;
                    width: math.percentage((math.div(308, 1056)));
                }

                img {
                    width: 58.2%;

                    @include large {
                        width: initial;
                    }
                }
            }

            &:nth-child(6) {
                @include large {
                    margin-top: -15.4rem;
                    width: 69.8%;
                    text-align: center;
                }

                img {
                    width: 57%;

                    @include large {
                        width: math.percentage((math.div(308, 737)));
                    }
                }  
            }

            &:nth-child(7) {
                @include large {
                    width: 100%;
                    text-align: center;
                    margin-top: 22.6rem;
                }

                img {
                    width: 100%;

                    @include large {
                        width: initial;
                    }
                }
            }
        }

        img {
            max-width: 100%;
        }
        
        p {
            max-width: 41rem;
            margin: 7rem 0 7.6rem;

            @include large {
                margin: 23.6rem 0 0;
            }
        }
    }

    &__max-width-img {
        max-width: 124rem;
    }
}