.contact-buttons {
    &__button {
        @include fluxBg;
        display: block;
        max-width: 18.2rem;
        height: 4.3rem;
        color: $brandColor1 !important;
        box-sizing: border-box;
        padding: 0 2.4rem;
        border-radius: 0.1rem;
        text-transform: uppercase;
        letter-spacing: 0.25rem;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 3.6rem;
        margin-top: 1rem;
        position: relative;
        text-align: center;
        &:first-of-type {
            letter-spacing: 0.05rem;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $brandColor2;
            transition: opacity $speed;
        }

        &-icon {
            font-size: 0;
            line-height: 0;
            margin-right: 0.2rem;

            .ico {
                width: 1.4rem;

                &.-discuss-phone {
                    height: 1.4rem;
                }

                &.-discuss-email {
                    height: 1.1rem;
                }

                &.-discuss-text {
                    height: 1.2rem;
                }
            }
        }

        &-icon,
        &-text {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            z-index: 2;
        }


        &:hover {
            @media (hover: hover) {
                color: $brandColor2 !important;
            }

            &:before {
                @media (hover: hover) {
                    opacity: 0;
                }
            }
        }
    }
}