.work-slider {
    padding-top: 0.4rem;
    
    & + & {
        margin-top: 3.5rem;

        @include medium {
            margin-top: 6.4rem;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1.9rem;

        @include medium {
            padding-bottom: 2.3rem;
        }

        &-title {
            font-weight: 600;
            line-height: 3.2rem;
             
            a {
                padding-bottom: 0.5rem;
            }

            font-size: 2rem;

            @include medium {
                font-size: 2.2rem;
            }
        }


        &-nav {
            font-size: 0;
            line-height: 0;
            display: none;

            @include medium {
                display: initial;
            }
    
            button {
                display: inline-block;
                vertical-align: top;
                border: none;
                background: none;
                font-size: 0;
                line-height: 0;
        
                + button {
                    margin-left: 4rem;
                }
            
                .ico {
                    width: 3.6rem;
                    height: 2.3rem;
                }
            
                &[disabled] {
                    opacity: 0.1;
                }
            }
        } 
    }

    &__scrollable {
        position: relative;

        html.mobile & {
            -ms-overflow-style: none;
            -webkit-overflow-scrolling: touch;
            overflow: auto;
            overflow: -moz-scrollbars-none;
        
            &::-webkit-scrollbar {
                width: 0 !important;
                height: 0 !important;
                opacity: 0 !important;
                visibility: hidden !important;
            }
        }

        html.no-mobile & {
            overflow: hidden;
        }

        .ps__rail-x {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &-slides {
            display: flex;
        }
    }

    &__slide {
        flex: 0 0 auto;
        min-width: 28rem;

        width: calc(30vw - 0.65rem);

        @include xxlarge {
            width: 41.6rem;
        }

        .work-tile-big {
            margin-bottom: 0;
        }

        & + & {
            margin-left: 1rem;
        }

        &:first-child {
            padding-left: 2.2rem;

            @include medium {
                padding-left: 5vw;
            }

            @include xxlarge {
                padding-left: calc((100vw - 126.8rem) / 2);
            }
        }

        &:last-child {
            padding-right: 2.2rem;

            @include medium {
                padding-right: 5vw;
            }

            @include xxlarge {
                padding-right: calc((100vw - 126.8rem) / 2);
            }
        }
    }
}

