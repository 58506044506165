.subhead {
    display: block;
    color: $brandColor1;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;

    @include medium {
        margin-bottom: 1.5rem;
        font-size: 1.7rem;
        line-height: 2.8rem;
    }

    @include xxlarge {
        margin-bottom: 0;
    }

    &__title,
    &__value {
        display: inline-block;
        vertical-align: baseline;
    }

    &__title {
        font-weight: 500;
  
        &:after {
            content: "____";
            white-space: nowrap;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            letter-spacing: -0.15em;
            position: relative;
            top: -0.058824em;
            -webkit-font-smoothing: none;
            font-smooth: never;
            width: 2.6rem;
            margin: 0 0.5rem;

            @include medium {
                width: 3.1rem;
                margin: 0 0.8rem;
            }
        }
    }

    &__value {
        font-family: $Ogg;
    }
}