/* Reset */
/* Grid */
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 62.5%;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  text-size-adjust: 100%;
}

/* HTML5 */
article,
aside,
figure,
footer,
header,
nav,
main,
section {
  display: block;
  padding: 0;
  margin: 0;
  border: none;
}

/* Text */
table,
tr,
th,
td {
  padding: 0px;
  border-collapse: collapse;
}

p {
  margin: 0;
  padding: 0;
}

a img {
  border: none;
}

a[href*="tel:"] {
  white-space: nowrap;
}

/* Forms */
input[type=search],
input[type=number],
input[type=tel] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  border-radius: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  cursor: pointer;
  font-size: 1em;
  font-family: inherit;
  color: currentcolor;
  border-radius: 0;
}

button::-moz-focus-inner {
  border: none;
}

input::-ms-clear,
textarea::-ms-clear {
  display: none;
}

input,
textarea,
button {
  margin: 0px;
  padding: 0px;
  outline: none;
}

form,
fieldset,
label {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  font-style: normal;
  border: none;
}

/* Mixins */
@font-face {
  font-family: "Sharp Sans No1";
  src: url("../fonts/SharpSansNo1-Light.woff2") format("woff2"), url("../fonts/SharpSansNo1-Light.woff") format("woff");
  font-weight: 300;
  font-style: "normal";
}
@font-face {
  font-family: "Sharp Sans No1";
  src: url("../fonts/SharpSansNo1-Book.woff2") format("woff2"), url("../fonts/SharpSansNo1-Book.woff") format("woff");
  font-weight: "normal";
  font-style: "normal";
}
@font-face {
  font-family: "Sharp Sans No1";
  src: url("../fonts/SharpSansNo1-Medium.woff2") format("woff2"), url("../fonts/SharpSansNo1-Medium.woff") format("woff");
  font-weight: 500;
  font-style: "normal";
}
@font-face {
  font-family: "Sharp Sans No1";
  src: url("../fonts/SharpSansNo1-Semibold.woff2") format("woff2"), url("../fonts/SharpSansNo1-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: "normal";
}
@font-face {
  font-family: "Sharp Sans No1";
  src: url("../fonts/SharpSansNo1-Bold.woff2") format("woff2"), url("../fonts/SharpSansNo1-Bold.woff") format("woff");
  font-weight: 700;
  font-style: "normal";
}
@font-face {
  font-family: "Sharp Sans No1";
  src: url("../fonts/SharpSansNo1-Extrabold.woff2") format("woff2"), url("../fonts/SharpSansNo1-Extrabold.woff") format("woff");
  font-weight: 800;
  font-style: "normal";
}
@font-face {
  font-family: "Druk Wide";
  src: url("../fonts/DrukWide-Bold.woff2") format("woff2"), url("../fonts/DrukWide-Bold.woff") format("woff");
  font-weight: 700;
  font-style: "normal";
}
@font-face {
  font-family: "GT America Mono";
  src: url("../fonts/gt-america-mono-light.woff2") format("woff2"), url("../fonts/gt-america-mono-light.woff") format("woff");
  font-weight: 300;
  font-style: "normal";
}
@font-face {
  font-family: "GT America Mono";
  src: url("../fonts/gt-america-mono-regular-italic.woff2") format("woff2"), url("../fonts/gt-america-mono-regular-italic.woff") format("woff");
  font-weight: 400;
  font-style: "italic";
}
@font-face {
  font-family: "Ogg";
  src: url("../fonts/Ogg-Roman.woff2") format("woff2"), url("../fonts/Ogg-Roman.woff") format("woff");
  font-weight: "normal";
  font-style: "normal";
}
@keyframes gradient {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -5% 0;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html,
html body {
  background-color: #fdfdfd;
}
html.page-landing,
html.page-landing body {
  background-color: #ffffff;
}

html.is-scrolling,
html.is-scrolling * {
  pointer-events: none !important;
}

body {
  font-weight: 500;
  font-family: "Sharp Sans No1", "Arial", sans-serif;
  color: #000000;
  font-size: 1.7rem;
  line-height: 2.7rem;
}
@media (min-width: 768px) {
  body {
    font-size: 2rem;
    line-height: 1.8em;
  }
}

a {
  text-decoration: none;
}

.layout {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: #fdfdfd;
}

html.is-combined-homepage .layout {
  transition: transform 750ms;
}
html.is-combined-homepage.is-combined-homepage-wave {
  will-change: transform;
  position: fixed;
}
html.is-combined-homepage.is-combined-homepage-wave .layout {
  transform: translateY(100vh);
}
html.is-combined-homepage.is-combined-homepage-altering {
  position: fixed;
}
html.is-combined-homepage.is-combined-homepage-altering,
html.is-combined-homepage.is-combined-homepage-altering * {
  pointer-events: none;
}
html.is-combined-homepage.is-combined-homepage-altering-down .layout {
  transform: none;
}
html.is-combined-homepage.is-combined-homepage-altering-up {
  position: fixed;
}
html.is-combined-homepage.is-combined-homepage-altering-up .layout {
  transform: translateY(100vh);
}
html.is-combined-homepage.is-combined-homepage-content .landing {
  visibility: hidden;
  z-index: -1;
}
html.is-combined-homepage.is-combined-homepage-prerender .layout {
  transition-duration: 100ms !important;
  z-index: -1 !important;
}
html.is-combined-homepage.is-combined-homepage-prerender .layout,
html.is-combined-homepage.is-combined-homepage-prerender .layout * {
  pointer-events: none;
}

.container {
  position: relative;
  margin: auto;
  max-width: 126.8rem;
  box-sizing: border-box;
  width: 100%;
  padding: 0 2.2rem;
}
@media (min-width: 768px) {
  .container {
    width: 90%;
    padding: 0;
  }
}

.hidden {
  display: none !important;
}
.hidden-b-sm {
  display: none !important;
}
.hidden-b-m {
  display: none !important;
}
.hidden-b-t {
  display: none !important;
}
.hidden-b-d {
  display: none !important;
}
@media (min-width: 480px) {
  .hidden-a-sm {
    display: none !important;
  }
  .hidden-b-sm {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .hidden-a-m {
    display: none !important;
  }
  .hidden-b-m {
    display: block !important;
  }
}
@media (min-width: 1024px) {
  .hidden-a-t {
    display: none !important;
  }
  .hidden-b-t {
    display: block !important;
  }
}
@media (min-width: 1440px) {
  .hidden-a-d {
    display: none !important;
  }
  .hidden-b-d {
    display: block !important;
  }
}

.hr {
  margin: 8.2rem 0 9.7rem;
}
@media (min-width: 768px) {
  .hr {
    margin: 19rem 0 18.8rem;
  }
}
.hr__container {
  height: 0.1rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.ico {
  font: 0/0 "a";
  display: inline-block;
  vertical-align: middle;
  flex: 0 0 auto;
}
.ico svg {
  width: 100%;
  height: 100%;
  transition-duration: 150ms;
  transition-property: fill, stroke, color;
  fill: currentcolor;
}
.ico svg [fill] {
  fill: currentcolor;
}
.ico svg [stroke] {
  stroke: currentcolor;
}
.ico svg:not(:root) {
  overflow: hidden;
}

.js-hover * {
  pointer-events: none;
}
.js-hover.-animate .js-hover__line {
  transition: transform 450ms;
}
.js-hover.-animate-in .js-hover__line {
  transform: translateX(0);
}
.js-hover.-animate-out .js-hover__line {
  transform: translateX(105%);
}
.js-hover.-preactivated .js-hover__line {
  transition: opacity 150ms;
  transform: translateX(0) !important;
}
.js-hover.-preactivated html.no-mobile a.js-hover.-preactivated:hover .js-hover__line {
  opacity: 0;
}
.js-hover html.no-mobile .js-hover:active .js-hover__line {
  transition-duration: 150ms;
  transform: translateX(1rem);
}
.js-hover__container {
  position: relative;
  overflow: hidden;
}
.js-hover__line {
  transform: translateX(-105%);
}
.js-hover__line.-multi {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.js-hover__line.-multi i {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
  display: inline-block;
  vertical-align: bottom;
  width: 0;
  height: 0.1rem;
}
.js-hover__rect-arrow:after {
  content: ">";
  display: block;
  font-size: 2.5rem;
}

.spacer {
  display: block;
  border: none;
  background: none;
  width: 100%;
  height: 0.1rem;
  font-size: 0;
  line-height: 0;
  padding: 0;
  margin: 0;
}
.spacer.-black {
  background-color: #161616;
}

@keyframes rotateColor {
  0% {
    filter: hue-rotate(360deg);
  }
  100% {
    filter: hue-rotate(0deg);
  }
}
.page-landing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .page-landing .wrapper {
    margin-bottom: 0;
  }
}
.page-landing .main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.landing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100%;
  background-color: #ffffff;
}
.landing__canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  animation: 10s rotateColor linear infinite;
}
.landing__canvas canvas {
  display: block;
  width: 100%;
  height: 100%;
}
.landing__rasters {
  display: none;
}
.landing__link {
  display: block;
  position: absolute;
  z-index: 100010;
  width: 12.5rem;
  height: 5rem;
  bottom: 3.3rem;
  cursor: pointer;
  border: none;
  background: none;
  right: 5.5%;
}
@media (min-width: 1440px) {
  .landing__link {
    right: initial;
    left: 50%;
    margin-left: 63.9rem;
    transform: translateX(-100%);
  }
}

#strategyAestheticsBlk {
  animation: 5s rotateColor linear infinite;
}

.anniversary-post .container {
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  min-height: calc(100vh - 15.1rem);
}
.anniversary-post .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  flex-direction: row;
}
.anniversary-post .row.text__only {
  display: none;
}
.anniversary-post .row.reverse {
  flex-direction: row-reverse;
}
.anniversary-post .row.reverse button.contact-buttons__button.button__timeline {
  margin-right: 3rem;
  transform: translateX(0);
}
.anniversary-post .main__timeline .row {
  position: relative;
  border-top: 1px solid #000000;
  margin-top: 15.1rem;
}
.anniversary-post .half-page {
  transition: all 500ms linear;
  position: relative;
  width: 100%;
  min-height: 64.9rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
}
.anniversary-post .half-page.text-container {
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
  padding-left: 0;
  max-width: 847px;
  height: calc(100vh - 15.1rem);
}
.anniversary-post .half-page.text-container.included-in-timeline {
  border-left: 0.1rem solid #000000;
  padding-left: 2.4rem;
}
.anniversary-post .half-page.text-container.included-in-timeline button.btn-animated {
  padding-bottom: 5rem;
}
.anniversary-post .half-page.text-container.included-in-timeline button.contact-buttons__button.button__timeline.included-in-timeline {
  opacity: 0;
}
.anniversary-post .half-page.text-container.included-in-timeline.slick-active {
  padding-left: 0;
  border-left: none;
}
.anniversary-post .half-page.text-container.included-in-timeline.slick-active button.contact-buttons__button.button__timeline {
  display: inline-block;
  opacity: 1;
}
.anniversary-post .half-page.timeline__section {
  padding-left: 0;
  left: 9rem;
}
.anniversary-post .half-page.timeline__section .slick-list {
  transform: translateX(0);
  transition: transform 0.3s linear;
}
.anniversary-post .half-page.timeline__section .first-slide .slick-list {
  transform: translateX(-4.9rem);
  transition: transform 0.3s linear;
  overflow: hidden;
}
.anniversary-post .half-page.timeline__section .first-slide button.contact-buttons__button.button__timeline.included-in-timeline {
  opacity: 1;
  z-index: 20;
}
.anniversary-post .half-page.timeline__section .first-slide .first-timeline-item .timeline__snippet h4,
.anniversary-post .half-page.timeline__section .first-slide .first-timeline-item .timeline__snippet p {
  opacity: 1;
}
.anniversary-post .half-page.timeline__section .first-slide .first-timeline-item .timeline-info button.scroll__bar {
  background: #000000;
}
.anniversary-post .half-page.timeline__section .first-slide.timeline-slider .timeline-icon.first-timeline-item .year-button__container .year__button {
  background: #000000;
  color: #ffffff;
}
.anniversary-post .small-text {
  padding-top: 2.4rem;
  width: 96%;
}
.anniversary-post .small-text p {
  font-size: 1.6rem;
  line-height: 140%;
  letter-spacing: 0.01em;
}
.anniversary-post .sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0.1rem;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 0.1rem;
}
.anniversary-post .prev__button div,
.anniversary-post .next__button div {
  width: 74px;
  height: 74px;
  background-color: #ffffff;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.anniversary-post .slick-arrow {
  height: 118px;
  background: transparent;
  border: none;
  position: absolute;
  bottom: 0;
  top: 92%;
  z-index: 18;
  display: none !important;
}
.anniversary-post .slick-arrow.prev__button {
  left: -6rem;
}
.anniversary-post .slick-arrow.next__button {
  right: calc(100vw - 6rem);
}
.anniversary-post .section-gradient.hero-gradient {
  background: #ffffff;
  color: #000000;
  animation: none;
}
.anniversary-post .section-gradient.hero-gradient:first-child .section-gradient__container {
  padding-top: 2.4rem;
  padding-bottom: 3rem;
  height: 100%;
  position: relative;
}
.anniversary-post .section-gradient.hero-gradient:first-child .section-gradient__container h1 {
  font-weight: 500;
}
.anniversary-post button.btn-animated {
  position: relative;
  background: transparent;
  top: -0.6rem;
  border: none;
  transition: transform 0.8s linear;
}
.anniversary-post button.btn-animated .drop__shadow {
  width: 7.2rem;
  height: 7.2rem;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 4.9rem;
  top: 0.7rem;
  border-radius: 50%;
}
.anniversary-post button.btn-animated svg {
  position: absolute;
  left: 7.3rem;
  top: 2.7rem;
  right: 0;
  transition: all 0.8s;
}
.anniversary-post .button__container {
  padding-top: 4.5%;
  display: flex;
  position: absolute;
  bottom: 12.5rem;
  width: 100%;
  z-index: 20;
}
.anniversary-post button.contact-buttons__button.button__timeline {
  margin-right: -3rem;
  max-width: none;
  margin-left: auto;
  display: inline-block;
  background: none;
  border: none;
  text-transform: none;
  height: 7.5rem;
  padding-left: 3.5rem;
  padding-right: 0;
  transition: all 1s;
  z-index: 20;
  margin-top: 0;
}
.anniversary-post button.contact-buttons__button.button__timeline::before {
  transition: all 1s;
  background: transparent;
}
.anniversary-post button.contact-buttons__button.button__timeline:hover {
  color: inherit !important;
}
.anniversary-post button.contact-buttons__button.button__timeline .contact-buttons__button-text {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.4rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  transition: all 0.8s;
  margin-bottom: 0.6rem;
  font-size: 1.4rem;
  margin-right: 0;
  margin-left: 0.6rem;
  font-weight: 600;
}
.anniversary-post button.contact-buttons__button.button__timeline .contact-buttons__button-icon {
  margin-left: 1rem;
  width: 7.4rem;
  height: 7.4rem;
  transition: all 0.8s;
  margin-bottom: 0.6rem;
  background: #ffffff;
  box-shadow: 0rem 0.4rem 2.3rem rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
.anniversary-post button.contact-buttons__button.button__timeline .contact-buttons__button-icon svg {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.anniversary-post .circle__anim {
  position: absolute;
  left: 3.5rem;
  right: 0;
  top: -1.2rem;
  margin: 0 auto;
  width: 10rem;
  height: 11rem;
  border-radius: 50%;
  font-weight: 600;
  color: #000000;
  text-align: center;
  transform: rotateZ(-0.25turn);
  animation: resetZ 2s linear;
}
.anniversary-post .circle__anim span {
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-transform: uppercase;
  font-size: 1.1rem;
  transition: all 0.5s cubic-bezier(0, 0, 0, 1);
}
@keyframes spinZ {
  0% {
    transform: rotateZ(-0.25turn);
  }
  100% {
    transform: rotateZ(-0.5turn);
  }
}
@keyframes resetZ {
  0% {
    transform: rotateZ(-0.5turn);
  }
  100% {
    transform: rotateZ(-0.25turn);
  }
}
.anniversary-post .btn-animated:hover .circle__anim,
.anniversary-post .btn-animated.animate .circle__anim {
  animation: spinZ 2s linear;
}
@media (prefers-reduced-motion: reduce) {
  .anniversary-post .btn-animated:hover .circle__anim,
.anniversary-post .btn-animated.animate .circle__anim {
    animation: none;
  }
}
.anniversary-post .btn-animated.resetting .circle__anim {
  animation: resetZ 2s linear;
}
@media (prefers-reduced-motion: reduce) {
  .anniversary-post .btn-animated.resetting .circle__anim {
    animation: none;
  }
}
.anniversary-post .timeline-slider.slick-slider {
  height: calc(100vh - 27.4rem);
}
.anniversary-post .timeline-slider .slick-track {
  display: flex;
}
.anniversary-post .timeline-slider .slick-slide {
  height: inherit;
}
.anniversary-post .timeline-slider .timeline-icon {
  overflow: visible;
  padding-top: 0;
  border-left: none;
  border-top: none;
  transition: all 0.8s;
  height: calc(100vh - 17.5rem);
  width: 66vh;
  background-color: #ffffff;
}
.anniversary-post .timeline-slider .timeline-icon .year-button__container {
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000;
}
.anniversary-post .timeline-slider .timeline-icon .year-button__container .year__button {
  transition: 500ms linear 0.5s;
  width: fit-content;
  width: max-content;
  padding: 0rem 2.4rem;
  border-right: 0.1rem solid #000000;
  font-size: 1.6rem;
  line-height: 2.4rem;
  background: transparent;
}
.anniversary-post .timeline-slider .timeline-icon button.image__container {
  background: transparent;
  border: none;
  width: 100%;
  height: 74%;
  padding: 0 2.4rem;
  display: block;
}
.anniversary-post .timeline-slider .timeline-icon button.image__container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.anniversary-post .timeline-slider .timeline-icon .content__container {
  border-left: 0.1rem solid #000000;
  height: calc(100% - 2.7rem);
  border-bottom: 0.1rem solid #000000;
  overflow: visible;
}
.anniversary-post .timeline-slider .timeline-icon.slick-current .year__button {
  background: #000000;
  color: #ffffff;
}
.anniversary-post .timeline-slider div.timeline-icon:last-of-type {
  border-right: 0.1rem solid #000000;
}
.anniversary-post .timeline__nav {
  list-style: none;
  display: flex;
  padding-left: 0;
  margin: 0;
  margin-top: -27px;
  z-index: 5;
  bottom: 0;
  position: absolute;
  left: 0;
}
.anniversary-post .timeline__nav li {
  width: 44.5rem;
}
.anniversary-post .timeline__nav li button.timeline__scroll {
  transition: 500ms linear 0s;
  width: 100%;
  background: #ffffff;
  height: 0.9rem;
  border: none;
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.anniversary-post .timeline__nav li:not(.slick-active) button.timeline__scroll {
  height: 0.9rem;
  background: #ffffff;
}
.anniversary-post .timeline__section hr {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  border: none;
  height: 0.1rem;
  background-color: #000000;
}
.anniversary-post .timeline__section .timeline__snippet {
  position: relative;
}
.anniversary-post .timeline__section .timeline__snippet h4,
.anniversary-post .timeline__section .timeline__snippet p {
  transition: 0.5s linear 0s;
  opacity: 0;
}
.anniversary-post .timeline__section .timeline__snippet.timeline__active {
  display: block;
}
.anniversary-post .timeline__section .timeline__snippet.timeline__active::after {
  position: absolute;
  width: 100%;
  height: 0.8rem;
  background: #000000;
  left: 0;
  bottom: 0;
  content: "";
}
.anniversary-post .timeline__section .slick-active .timeline__snippet h4,
.anniversary-post .timeline__section .slick-active .timeline__snippet p {
  opacity: 1;
  transition: 0.5s linear 0.5s;
}
.anniversary-post .timeline__section .slick-active .timeline-info button.scroll__bar {
  background: #000000;
  transition: 0.5s linear 0.5s;
}
.anniversary-post .timeline__section .timeline-info {
  padding-top: 1.6rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  border-top: 0.1rem solid #000000;
  background: #fff;
  z-index: 10;
  height: calc(26% - 1.8rem);
}
.anniversary-post .timeline__section .timeline-info button.scroll__bar {
  background: #ffffff;
  border: none;
  border-top: 0.1rem solid #000000;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -0.1rem;
  height: 0.8rem;
  transition: 0.5s linear 0s;
}
.anniversary-post .timeline__section .timeline-info .headline {
  margin-bottom: 0.8rem;
}
.anniversary-post .timeline__section .timeline-info p {
  font-size: 1.6rem;
  line-height: 140%;
  letter-spacing: 0.01em;
}
.anniversary-post .timeline__section .timeline-info .buttons {
  margin-bottom: 3.8rem;
  margin-top: 1.4rem;
  display: flex;
}
.anniversary-post .timeline__section .timeline-info .buttons button {
  border: 0.1rem solid #000000;
  transition: all 0.15s;
}
.anniversary-post .timeline__section .timeline-info .buttons .icon__button {
  margin-left: 1.2rem;
  margin-top: 1rem;
  background: transparent;
  width: 4.4rem;
  height: 4.4rem;
}
.anniversary-post .timeline__section .timeline-info .buttons .icon__button svg {
  width: 100%;
}
.anniversary-post .timeline__section .timeline-info .buttons .icon__button svg rect {
  transition: all 0.15s;
}
.anniversary-post .timeline__section .timeline-info .buttons .icon__button:hover {
  background: #000000;
}
.anniversary-post .timeline__section .timeline-info .buttons .icon__button:hover svg rect {
  stroke: #ffffff;
  fill: #ffffff;
}
.anniversary-post .timeline__section .timeline-info .buttons .contact-buttons__button {
  background: transparent;
  animation: none;
  transition: all 0.15s;
  max-width: 22.2rem;
}
.anniversary-post .timeline__section .timeline-info .buttons .contact-buttons__button:hover, .anniversary-post .timeline__section .timeline-info .buttons .contact-buttons__button:focus {
  background: #000000;
  color: #ffffff;
}
.anniversary-post .timeline__section .timeline-info .buttons .contact-buttons__button:hover .contact-buttons__button-icon svg path, .anniversary-post .timeline__section .timeline-info .buttons .contact-buttons__button:focus .contact-buttons__button-icon svg path {
  fill: #ffffff;
}
.anniversary-post .timeline__section .timeline-info .buttons .contact-buttons__button .contact-buttons__button-icon {
  margin-left: 0.6rem;
  margin-right: 0;
  margin-bottom: 0.6rem;
}
.anniversary-post .timeline__section .timeline-info .buttons .contact-buttons__button .contact-buttons__button-text {
  vertical-align: unset;
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
}
.anniversary-post .marquee__and__snippet {
  position: relative;
  top: 1px;
  height: 16vh;
}
.anniversary-post .marquee__and__snippet .image__marquee {
  height: 143px;
  position: absolute;
  top: 0;
  left: 0;
  width: max-content;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}
.anniversary-post .marquee__and__snippet .image__marquee .scroll {
  width: 130px;
  animation: scroll-left 10s linear infinite;
}
.anniversary-post .marquee__and__snippet .image__marquee img {
  max-height: 100%;
}
@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-135px);
  }
}
.anniversary-post .show-full-timeline .half-page.text-container.included-in-timeline .button__container {
  display: flex;
}
.anniversary-post .show-full-timeline .slick-list {
  overflow: visible;
}
.anniversary-post .show-full-timeline button.contact-buttons__button.button__timeline:not(.included-in-timeline) {
  transform: translateX(-100vw);
}
.anniversary-post .show-full-timeline .half-page.timeline__section {
  max-width: 100%;
  flex: 0 0 100%;
}
.anniversary-post .show-full-timeline .half-page.text-container:not(.included-in-timeline) {
  overflow: hidden;
  flex: 0 0 0%;
  max-width: 0%;
  padding: 0;
}
.anniversary-post .show-full-timeline .half-page.text-container:not(.included-in-timeline) .section-gradient__headlines {
  display: none;
}
.anniversary-post .show-full-timeline .reverse .half-page.timeline__section {
  flex: 0 0 40%;
  max-width: 40%;
  left: 0;
}
.anniversary-post .show-full-timeline .reverse .half-page.text-container {
  flex: 0 0 60%;
  max-width: 60%;
}
.anniversary-post .show-full-timeline .reverse .slick-arrow.next__button {
  transform: translateX(100vw);
}
.anniversary-post .show-full-timeline.main__timeline .row {
  border-top: none;
}
.anniversary-post .show-full-timeline.main__timeline .timeline-slider {
  border-left: none;
}
.anniversary-post .show-full-timeline.main__timeline .timeline-slider .slick-list .slick-track {
  border-top: 1px solid #000000;
}
.anniversary-post .show-full-timeline.main__timeline .timeline__section .timeline__snippet.timeline__active {
  margin-left: -0.1rem;
}
.anniversary-post .show-full-timeline .slick-arrow {
  display: inline-block !important;
}
.anniversary-post .show-full-timeline .first-slide .slick-arrow {
  display: none !important;
}
@media (max-height: 794px) and (min-width: 1399px) {
  .anniversary-post .slick-arrow {
    top: 48rem;
  }
  .anniversary-post .timeline-slider .timeline-icon {
    min-width: 49.4rem;
    min-height: 62.5rem;
  }
}
.anniversary-post .mobile,
.anniversary-post .mobile__button {
  display: none;
}
.anniversary-post .timeline-slider.mobile__only__timeline {
  display: none;
}
@media (max-width: 1400px) {
  .anniversary-post .included-in-timeline {
    display: none !important;
  }
  .anniversary-post .timeline-slider:not(.mobile__only__timeline) {
    display: none;
  }
  .anniversary-post .timeline-slider.mobile__only__timeline {
    display: block;
  }
  .anniversary-post .half-page.timeline__section {
    left: 0;
  }
  .anniversary-post .show-full-timeline .mobile__button {
    transform: translateX(-100%);
  }
  .anniversary-post .show-full-timeline .half-page.timeline__section {
    transform: translateX(-19.8rem);
    border-top: none;
  }
  .anniversary-post .show-full-timeline .half-page.timeline__section .first-slide .slick-list {
    transform: translateX(0);
  }
  .anniversary-post .show-full-timeline .half-page.text-container:not(.included-in-timeline) {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
    overflow: visible;
  }
  .anniversary-post .show-full-timeline .half-page.text-container:not(.included-in-timeline) .section-gradient__headlines {
    display: block;
  }
  .anniversary-post .slick-arrow {
    top: 45.5%;
  }
  .anniversary-post .slick-arrow.next__button {
    left: 83vw;
  }
  .anniversary-post .slick-arrow.prev__button {
    left: -1.4rem;
  }
  .anniversary-post .small-text.mobile {
    padding-top: 0;
    width: 100%;
    padding-bottom: 2.4rem;
  }
  .anniversary-post .small-text.mobile p {
    line-height: 2.2rem;
  }
  .anniversary-post button.contact-buttons__button.button__timeline {
    margin-right: 0;
    padding-left: 0;
  }
  .anniversary-post button.contact-buttons__button.button__timeline .contact-buttons__button-text {
    text-align: left;
    margin-left: 0;
  }
  .anniversary-post button.contact-buttons__button.button__timeline .contact-buttons__button-icon {
    margin-left: 0;
    margin-right: auto;
    display: block;
    width: 5rem;
    height: 5rem;
  }
  .anniversary-post button.contact-buttons__button.button__timeline .contact-buttons__button-icon svg {
    width: 1.9rem;
    height: 2.2rem;
  }
  .anniversary-post .mobile,
.anniversary-post .mobile__button {
    display: block;
  }
  .anniversary-post .mobile__button {
    -ms-flex: 0 0 19.4rem;
    flex: 0 0 19.4rem;
    max-width: 19.4rem;
    height: fit-content;
    height: max-content;
    margin-top: auto;
    margin-bottom: 5rem;
  }
  .anniversary-post .container {
    min-height: unset;
    padding: 0;
  }
  .anniversary-post .button__container {
    bottom: unset;
    padding-top: 2.8rem;
  }
  .anniversary-post button.btn-animated .drop__shadow {
    left: 1.4rem;
    width: 5rem;
    height: 5rem;
  }
  .anniversary-post button.btn-animated svg {
    left: 3.2rem;
    top: 2.2rem;
    width: 1.5rem;
    height: 1.9rem;
  }
  .anniversary-post button.btn-animated .circle__anim {
    left: -1.2rem;
    top: -1.05rem;
    height: 8.6rem;
  }
  .anniversary-post .main__timeline .row {
    margin-left: 2.4rem;
    flex-wrap: wrap;
    border-top: none;
    margin-top: 6.9rem;
  }
  .anniversary-post .half-page {
    min-height: unset;
  }
  .anniversary-post .half-page.mobile {
    margin-right: 2.4rem;
  }
  .anniversary-post .half-page.text-container, .anniversary-post .half-page.timeline__section {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .anniversary-post .half-page.text-container {
    height: unset;
  }
  .anniversary-post .half-page.text-container .small-text:not(.mobile) {
    display: none;
  }
  .anniversary-post .half-page.timeline__section {
    border-top: 0.1rem solid #000000;
    padding-bottom: 2.4rem;
    display: flex;
  }
  .anniversary-post .half-page.timeline__section .timeline-info {
    height: calc(40% - 1.8rem);
  }
  .anniversary-post button.contact-buttons__button.button__timeline:not(.mobile) {
    display: none;
  }
  .anniversary-post .section-gradient__headlines {
    padding-bottom: 9.5rem;
  }
  .anniversary-post .timeline-slider.slick-slider {
    height: unset;
  }
  .anniversary-post .timeline-slider .timeline-icon {
    height: 50.1rem;
    width: 33.4rem;
  }
  .anniversary-post .timeline-slider .timeline-icon button.image__container {
    height: 60%;
  }
  .anniversary-post .row.text__only {
    display: block;
    margin-top: 0;
    margin-right: 2.4rem;
  }
}
@media (max-width: 500px) and (max-height: 800px) {
  .anniversary-post .timeline-slider .timeline-icon {
    height: 34.1rem;
    width: 23.6rem;
  }
  .anniversary-post .timeline-slider .timeline-icon button.image__container {
    height: 49%;
  }
  .anniversary-post .half-page.timeline__section .timeline-info {
    height: calc(51% - 1.4rem) !important;
  }
}
@media (max-width: 500px) {
  .anniversary-post .show-full-timeline .slick-arrow {
    display: none !important;
  }
  .anniversary-post .half-page.timeline__section .timeline-info {
    height: calc(40% - 1.4rem);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.2rem;
  }
  .anniversary-post .half-page.timeline__section .timeline-info h4 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .anniversary-post .half-page.timeline__section .timeline-info p {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
  .anniversary-post .half-page.timeline__section .timeline-info .headline {
    margin-bottom: 0.5rem;
  }
}

.news-category .news-list {
  padding-top: 0rem;
  padding-bottom: 9rem;
}
@media (min-width: 768px) {
  .news-category .news-list {
    padding-bottom: 14rem;
  }
}

.featured-tap {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
@media (min-width: 1024px) {
  .featured-tap {
    display: none;
  }
}

.news-list {
  padding-top: 9rem;
}
@media (min-width: 768px) {
  .news-list {
    padding-top: 14rem;
  }
}
.news-list-without-headline {
  padding-top: 0rem;
}
.news-list__search {
  margin-bottom: 2.4rem;
}
.news-list__search-toggler {
  position: relative;
}
.news-list__search-handler {
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 3.5rem;
  border-radius: 1.75rem;
  box-shadow: 0.3rem 0.5rem 1.4rem rgba(0, 0, 0, 0.06);
  border: 0.1rem solid #ffffff;
  background: none;
  color: rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 1.8rem 0.2rem;
  box-sizing: border-box;
  transition-property: box-shadow, color, background-color, opacity, visibility;
  transition-duration: 150ms;
}
.news-list__search-handler .ico {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}
.news-list__search-handler .ico svg {
  transition: none;
}
.news-list__search-handler-value {
  display: inline-block;
  vertical-align: middle;
}
.news-list__search-handler:hover {
  color: #000000;
}
.news-list__search-handler:hover:not(:active) {
  box-shadow: 0.3rem 0.5rem 2rem rgba(0, 0, 0, 0.15);
}
.news-list__search-handler.-opened {
  position: absolute;
  z-index: 120;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  color: #ffffff;
}
.news-list__search-handler.-opened:hover {
  color: #000000;
  background-color: #ffffff;
}
.news-list__search-overlay {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 450ms;
  padding-top: 28.1rem;
  box-sizing: border-box;
}
.news-list__search-overlay-form {
  position: relative;
  opacity: 0;
  transform: translateY(2rem);
  transition-property: transform, opacity;
  transition-duration: 450ms;
  transition-delay: 450ms;
}
.news-list__search-overlay-form-placeholder {
  display: block;
  transition-property: opacity, visibility;
  transition-duration: 225ms;
  transition-timing-function: linear;
  height: 4.9rem;
}
@media (min-width: 768px) {
  .news-list__search-overlay-form-placeholder {
    height: initial;
  }
}
.news-list__search-overlay-form input {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  border: none;
  background: none;
  color: #ffffff;
  font-family: "Sharp Sans No1", "Arial", sans-serif;
  font-weight: 600;
  letter-spacing: -0.025rem;
  height: 8.4rem;
  font-size: 6.3rem;
  top: -2.1rem;
}
@media (min-width: 768px) {
  .news-list__search-overlay-form input {
    height: 12rem;
    font-size: 9rem;
    top: -3rem;
  }
}
.news-list__search-overlay-form.-filled .news-list__search-overlay-form-placeholder {
  visibility: hidden;
  opacity: 0;
}
.news-list__search-overlay-form.-filled + .news-list__search-overlay-tip {
  opacity: 1;
  transform: none;
}
.news-list__search-overlay-tip {
  opacity: 0;
  transform: translateY(2rem);
  transition-property: transform, opacity;
  transition-duration: 450ms;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: rgba(255, 255, 255, 0.75);
  padding-top: 3.1rem;
}
.news-list__search.-active .news-list__search-handler.-closed {
  opacity: 0;
  visibility: hidden;
}
.news-list__search.-active .news-list__search-handler.-opened {
  opacity: 1;
  visibility: visible;
}
.news-list__search.-active .news-list__search-overlay {
  opacity: 0.95;
  visibility: visible;
}
.news-list__search.-active .news-list__search-overlay-form {
  opacity: 1;
  transform: none;
}
@media (min-width: 1024px) {
  .news-list__list {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;
  }
}
.news-list__list-item {
  width: 100%;
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .news-list__list-item {
    width: auto;
    margin-bottom: 0;
  }
}
.news-list__pinned, .news-list__announcement {
  background-repeat: no-repeat;
}
@media (min-width: 1024px) {
  .news-list__pinned, .news-list__announcement {
    grid-column-end: span 3;
  }
}
@media (min-width: 1024px) {
  .news-list__normal, .news-list__social {
    box-sizing: border-box;
  }
}
.news-list__social span[class] {
  display: block;
}
.news-list__pinned {
  color: #ffffff;
  position: relative;
  background-size: auto 28.8rem;
  background-position: 50% 2rem;
}
@media (min-width: 768px) {
  .news-list__pinned {
    background-size: auto 41.4rem;
    background-position: 50% 1.4rem;
  }
}
@media (min-width: 1024px) {
  .news-list__pinned {
    min-height: 60rem;
    background-size: 56.6142% auto;
    background-position: right center;
  }
}
@media (min-width: 1280px) {
  .news-list__pinned {
    min-height: 60rem;
    background-size: 56.6142% auto;
  }
}
.news-list__pinned a {
  color: currentcolor;
}
.news-list__pinned .subhead {
  color: #ffffff;
  margin-bottom: 0.6rem;
  font-size: 1.3rem;
}
@media (min-width: 768px) {
  .news-list__pinned .subhead {
    margin-bottom: -0.4rem;
  }
}
@media (min-width: 1024px) {
  .news-list__pinned .subhead {
    margin-bottom: 0.7rem;
    font-size: initial;
  }
}
.news-list__pinned .news-info {
  color: #a4a4a4;
  font-size: 1.3rem;
  line-height: 2.4rem;
  opacity: 1;
}
@media (min-width: 1024px) {
  .news-list__pinned .news-info {
    font-size: 1.7rem;
    line-height: 2.6rem;
    margin-bottom: 5.4rem;
    opacity: 0.5;
  }
}
.news-list__pinned-body {
  padding: 32.6rem 1.8rem 3.3rem;
}
@media (min-width: 768px) {
  .news-list__pinned-body {
    padding: 42.2rem 2.9rem 4.3rem;
  }
}
@media (min-width: 1024px) {
  .news-list__pinned-body {
    width: 50%;
    padding: 5.3rem 0 5.3rem 5.8rem;
    box-sizing: border-box;
  }
}
.news-list__pinned-headline {
  font-family: "Ogg", "Times New Roman", serif;
  font-size: 2.5rem;
  line-height: 3.6rem;
  margin-bottom: 0.7rem;
}
@media (min-width: 768px) {
  .news-list__pinned-headline {
    font-size: 3.2rem;
    line-height: 4.3rem;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .news-list__pinned-headline {
    margin-bottom: 0.8rem;
  }
}
@media (min-width: 1280px) {
  .news-list__pinned-headline {
    font-size: 4.6rem;
    line-height: 6rem;
  }
}
.news-list__pinned-more {
  display: none;
}
@media (min-width: 1024px) {
  .news-list__pinned-more {
    display: initial;
  }
}
.news-list__pinned-more a {
  display: inline-block;
  vertical-align: top;
  width: 27rem;
  height: 4.8rem;
  border: 0.2rem solid #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 4.2rem;
  background-clip: content-box;
  box-sizing: border-box;
}
.news-list__pinned-more a:hover {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 2s linear infinite;
  background-color: #0369FB;
  border-width: 0;
  line-height: 4.6rem;
}
.news-list__announcement {
  background-repeat: no-repeat;
  background-position: 50% 4rem;
  background-size: auto 16rem;
  padding: 22.6rem 3.3rem 3rem;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .news-list__announcement {
    background-position: 50% 5rem;
    background-size: auto 28.9rem;
    padding: 35rem 6.3rem 3.9rem;
  }
}
@media (min-width: 1024px) {
  .news-list__announcement {
    display: flex;
    align-items: center;
    padding: 5rem 0;
    min-height: 50rem;
    background-position: 0% 0%;
    background-size: 66.4567% auto;
    padding: 0;
    box-sizing: content-box;
  }
}
@media (min-width: 1440px) {
  .news-list__announcement {
    background-size: 66.4567% auto;
  }
}
.news-list__announcement .subhead {
  margin: 0 0 2.1rem;
}
@media (min-width: 768px) {
  .news-list__announcement .subhead {
    margin: 1.4rem 0 1.1rem;
  }
}
.news-list__announcement .subhead__title {
  font-size: 2.2rem;
}
@media (min-width: 768px) {
  .news-list__announcement .subhead__title {
    font-size: initial;
  }
}
.news-list__announcement-headline {
  font-family: "Ogg", "Times New Roman", serif;
  color: #000000;
  font-size: 2.3rem;
  line-height: 3.5rem;
}
@media (min-width: 768px) {
  .news-list__announcement-headline {
    font-size: 3rem;
    line-height: 4.1rem;
  }
}
@media (min-width: 1280px) {
  .news-list__announcement-headline {
    font-size: 4rem;
    line-height: 5.2rem;
  }
}
@media (min-width: 768px) {
  .news-list__announcement.-text-first {
    background-position: right center;
  }
}
@media (min-width: 1024px) {
  .news-list__announcement.-text-first .news-list__announcement-body {
    width: 50%;
    box-sizing: border-box;
    padding-left: 5.3rem;
  }
}
@media (min-width: 1440px) {
  .news-list__announcement.-text-first .news-list__announcement-body {
    padding-left: 10.6rem;
  }
}
@media (min-width: 1024px) {
  .news-list__announcement.-image-first {
    background-position: left center;
  }
}
@media (min-width: 1024px) {
  .news-list__announcement.-image-first .news-list__announcement-body {
    margin-left: 50%;
    padding: 0 0 2.6rem 0.5rem;
  }
}
.news-list__normal .subhead {
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}
@media (min-width: 768px) {
  .news-list__normal .subhead {
    font-size: initial;
    margin-bottom: 0.6rem;
  }
}
.news-list__normal .news-info {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #a4a4a4;
}
.news-list__normal-inner {
  display: block;
  box-shadow: 0.3rem 0.5rem 1.4rem rgba(0, 0, 0, 0.06);
  transition: box-shadow 150ms;
}
.news-list__normal-inner:hover:not(:active) {
  box-shadow: 0.3rem 0.5rem 2rem rgba(0, 0, 0, 0.15);
}
.news-list__normal-inner:hover .news-list__normal-headline-copy {
  transform: translateX(-100%);
}
.news-list__normal-inner:hover .news-list__normal-headline-visible,
.news-list__normal-inner:hover .news-list__normal-headline,
.news-list__normal-inner:hover .news-info p,
.news-list__normal-inner:hover .news-info p + p:before .subhead__title,
.news-list__normal-inner:hover .subhead__title:after,
.news-list__normal-inner:hover .subhead__value {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.news-list__normal-preview {
  position: relative;
  overflow: hidden;
}
.news-list__normal-preview:before {
  content: "";
  display: block;
  padding-top: 50.480769%;
}
.news-list__normal-preview img {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
}
.news-list__normal-body {
  padding: 2.5rem 2.6rem 2.1rem;
}
@media (min-width: 768px) {
  .news-list__normal-body {
    padding: 2rem 2.8rem;
  }
}
.news-list__normal-body .headline {
  color: #000000;
}
.news-list__normal-headline {
  font-size: 1.7rem;
  line-height: 2.6rem;
  color: #000000;
  position: relative;
  overflow: hidden;
  height: 7.8rem;
  margin-bottom: 3.8rem;
}
@media (min-width: 768px) {
  .news-list__normal-headline {
    margin-bottom: 3.7rem;
  }
}
.news-list__normal-headline-copy {
  position: absolute;
  top: -0.2rem;
  left: 0;
  width: 100%;
  color: transparent;
  transition-property: transform, opacity;
  transition-duration: 150ms;
}
.news-list__normal-headline-visible {
  display: inline;
}
.news-list__social {
  display: none;
}
@media (min-width: 1024px) {
  .news-list__social {
    display: initial;
  }
}
.news-list__social-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 3.3rem;
  position: relative;
}
html.no-mobile .news-list__social-inner .news-list__social-media,
html.no-mobile .news-list__social-inner .news-list__social-media p,
html.no-mobile .news-list__social-inner .news-list__social-link {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.news-list__social-media {
  color: #3c3c3c;
  position: relative;
  padding-bottom: 3.6rem;
}
.news-list__social-media img,
.news-list__social-media p {
  display: block;
}
.news-list__social-media p {
  color: #3c3c3c;
  font-size: 1.7rem;
  line-height: 3rem;
  max-height: 27rem;
  overflow: hidden;
}
@media (min-width: 1440px) {
  .news-list__social-media p {
    font-size: initial;
    line-height: initial;
    max-height: none;
    overflow: visible;
  }
}
.news-list__social-link {
  font-size: 1.7rem;
  line-height: 1.8;
  position: absolute;
  overflow: hidden;
  bottom: 1.9rem;
  left: 0;
  color: #000000;
}
.news-list__preloader {
  text-align: center;
  height: 20rem;
  line-height: 20rem;
}
@media (min-width: 768px) {
  .news-list__preloader {
    height: 30rem;
    line-height: 30rem;
  }
}
.news-list__preloader-pluses i {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0 0.3rem;
  width: 1.4rem;
  height: 1.4rem;
  animation: spin linear infinite 1.5s;
}
.news-list__preloader-pluses i:before, .news-list__preloader-pluses i:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #000000;
}
.news-list__preloader-pluses i:before {
  top: 0;
  left: 0.5rem;
  width: 0.4rem;
  height: 100%;
}
.news-list__preloader-pluses i:after {
  top: 0.5rem;
  left: 0;
  width: 100%;
  height: 0.4rem;
}
.news-list__preloader-pluses i:nth-child(1) {
  opacity: 0.1;
}
.news-list__preloader-pluses i:nth-child(2) {
  opacity: 0.2;
  animation-delay: 0.5s;
}
.news-list__preloader-pluses i:nth-child(3) {
  animation-delay: 1s;
}
.news-list__preloader.-hidden {
  visibility: hidden;
  height: 7.1rem;
}
@media (min-width: 768px) {
  .news-list__preloader.-hidden {
    height: 10.6rem;
  }
}

html.no-mobile .news-list__social-inner:not(:hover) .news-list__social-link {
  -webkit-text-fill-color: #000000;
}

html.no-mobile .news-list__social-inner:not(:hover) .news-list__social-media,
html.no-mobile .news-list__social-inner:not(:hover) .news-list__social-media p {
  -webkit-text-fill-color: #3c3c3c;
}

html.no-mobile .news-list__social-inner:not(:hover) .news-list__social-media,
html.no-mobile .news-list__social-inner:not(:hover) .news-list__social-media p,
html.no-mobile .news-list__social-inner:not(:hover) .news-list__social-link {
  background: none;
  -webkit-background-clip: initial;
}

@font-face {
  font-family: "ss-social-regular";
  src: url("../fonts/ss-social-regular.woff") format("woff");
}
.page-news-single {
  overflow-x: hidden;
}
.page-news-single .layout,
.page-news-single .footer {
  overflow-x: hidden;
}
.page-news-single .header:not(.-upscrolled) .header__menu-link.-highlighted.-highlighted:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.page-news-single .header:not(.-upscrolled) .complex-link.-skin-black .news-single__line {
  background-color: #000000;
}
@media (min-width: 1024px) {
  .page-news-single .header:not(.-upscrolled) .complex-link.-skin-black .news-single__line {
    background-color: initial;
  }
}
.page-news-single .header.-upscrolled .header__logo .ico {
  color: #000000;
}

#twitter-widget-0,
#twitter-widget-1,
#twitter-widget-2 {
  width: 100% !important;
}

.news-single__main {
  position: relative;
}
@media (min-width: 1024px) {
  .news-single__main {
    padding-left: 50%;
  }
}
.news-single__main-cover {
  position: relative;
}
.news-single__main-cover:before {
  content: "";
  display: block;
  padding-top: 100%;
}
@media (min-width: 1024px) {
  .news-single__main-cover:before {
    content: initial;
    display: block;
    padding-top: auto;
  }
}
@media (min-width: 1024px) {
  .news-single__main-cover {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100vh;
  }
  .news-single__main-cover.-fixed {
    position: fixed;
  }
}
.news-single__main-cover .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.news-single__main-article {
  border-bottom: 0.1rem solid rgba(191, 191, 191, 0.25);
  padding-top: 5rem;
}
@media (min-width: 768px) {
  .news-single__main-article {
    padding: 10.9rem 0 0 0;
  }
}
@media (min-width: 1024px) {
  .news-single__main-article {
    padding: 20.5rem 17.95% 0 13.4%;
  }
}
@media (min-width: 1440px) {
  .news-single__main-article {
    padding: 20.5rem 12% 0 13.4%;
  }
}
@media (min-width: 1024px) {
  .news-single__main-article .container {
    width: 100%;
  }
}
.news-single__main-article-heading {
  padding-bottom: 5rem;
}
@media (min-width: 768px) {
  .news-single__main-article-heading {
    padding-bottom: 2.3rem;
  }
}
.news-single__main-article-heading .subhead {
  margin-bottom: 0.8rem;
  margin-top: 9rem;
}
.news-single__main-article-heading h1 {
  font-weight: normal;
  font-family: "Ogg", "Times New Roman", serif;
  color: #000000;
  padding: 0;
  margin: 0 0 1rem;
  font-size: 2.8rem;
  line-height: 4rem;
}
@media (min-width: 768px) {
  .news-single__main-article-heading h1 {
    font-size: 4.4rem;
    line-height: 6rem;
  }
}
.news-single__main-article-heading .news-info {
  color: #a4a4a4;
  font-size: 1.4rem;
  line-height: 2rem;
}
@media (min-width: 768px) {
  .news-single__main-article-heading .news-info {
    font-size: 1.7rem;
    line-height: initial;
  }
}
.news-single__main-article-body {
  font-size: 1.6rem;
  line-height: 2.9rem;
  letter-spacing: -0.01rem;
  padding-bottom: 8rem;
}
.news-single__main-article-body ul {
  padding-left: 2rem;
}
.news-single__main-article-body h2 {
  padding: 0;
  margin: 5rem 0;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.9rem;
}
.news-single__main-article-body p,
.news-single__main-article-body > div {
  margin: 0rem 0;
}
.news-single__main-article-body > p + p {
  margin-top: 4.5rem;
}
.news-single__main-article-body img {
  display: block;
  margin: 6rem 0 6rem 0rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}
@media (min-width: 1024px) {
  .news-single__main-article-body img {
    max-width: initial;
    width: 100%;
  }
}
.news-single__main-article-body hr {
  display: block;
  padding: 0;
  margin: 5.2rem 0;
  border: none;
  width: 100%;
  height: 0.1rem;
  background-color: rgba(191, 191, 191, 0.25);
}
.news-single__main-article-body blockquote {
  overflow: hidden;
  margin: 8rem 0 8rem;
  padding: 5.2rem !important;
  background-color: #f7f7f7;
  color: #000000;
  letter-spacing: 0;
  padding: 3.2rem;
  margin-left: -2rem;
  margin-right: -2rem;
}
@media (min-width: 768px) {
  .news-single__main-article-body blockquote {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
.news-single__main-article-body blockquote + blockquote {
  margin-top: 0;
  padding-top: 0;
  border-top-width: 0;
}
.news-single__main-article-body blockquote:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom-width: 0;
}
.news-single__main-article-body blockquote p + p {
  margin-top: 3.9rem;
}
.news-single__main-article-body blockquote p:not(:last-child),
.news-single__main-article-body blockquote p:only-child {
  font-family: "GT America Mono", "Arial", sans-serif;
  font-style: italic;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.89;
  line-height: 3.4rem;
  opacity: 0.6;
}
.news-single__main-article-body blockquote p:not(:last-child) a,
.news-single__main-article-body blockquote p:only-child a {
  padding: 0 0.2rem 0 0;
}
.news-single__main-article-body blockquote p:last-child:not(:only-child) {
  font-family: "Sharp Sans No1", "Arial", sans-serif;
  float: right;
  right: 0;
  position: relative;
  white-space: pre;
  font-size: 1.7rem;
  line-height: 1.53;
  min-width: 21.9rem;
  opacity: 0.6;
}
.news-single__main-article-body blockquote p:last-child:not(:only-child) b,
.news-single__main-article-body blockquote p:last-child:not(:only-child) strong {
  font-weight: 600;
}
.news-single__main-article-body blockquote p:last-child:not(:only-child):before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  margin: 1.8rem 1.3rem 0 0;
  width: 3.1rem;
  height: 0.1rem;
  background-color: #000000;
}
.news-single__main-article-body > :first-child {
  margin-top: 0 !important;
}
.news-single__main-article-body > :last-child {
  margin-bottom: 0 !important;
}
.news-single__main-article-body .tagline {
  opacity: 0.35;
  font-family: "GT America Mono", "Arial", sans-serif;
  font-size: 1.4rem;
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #3c3c3c;
  margin: -4rem 0rem 7rem 0rem;
}
.news-single__main-article-body h5 {
  font-family: "Sharp Sans No1", "Arial", sans-serif;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.71;
  color: #3c3c3c;
  margin: 5rem 0rem;
}
.news-single__main-article-body .features {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .news-single__main-article-body .features {
    flex-wrap: nowrap;
  }
}
.news-single__main-article-body .features .features__list-a,
.news-single__main-article-body .features .features__list-b {
  flex: 1;
}
.news-single__main-article-body .features p {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.81;
  color: #3c3c3c;
  padding-right: 17.5rem;
  letter-spacing: normal;
  text-decoration: underline;
}
.news-single__main-article .afterword {
  border-top: 0.1rem solid rgba(191, 191, 191, 0.25);
  padding: 7rem 0;
}
@media (min-width: 768px) {
  .news-single__main-article .afterword {
    padding: 14.5rem 0;
  }
}
.news-single__main-article .afterword__heading {
  color: #a4a4a4;
  font-weight: 300;
}
.news-single__main-article .afterword_subheading {
  font-weight: 500;
}
.news-single__main-article .afterword__links {
  display: flex;
  align-items: center;
}
.news-single__main-article .afterword__links .complex-link__blank img {
  width: calc(100% + 0.2rem);
  margin-left: -0.1rem;
  margin-top: -0.1rem;
  margin-right: -0.1rem;
}
.news-single__main-article .afterword__links a {
  margin-right: 2.5rem;
  font-size: 1.7rem;
  color: #ff00d5;
}
.news-single__main-article .afterword__heading, .news-single__main-article .afterword__subheading {
  font-size: 3rem;
  line-height: 4rem;
}
@media (min-width: 768px) {
  .news-single__main-article .afterword__heading, .news-single__main-article .afterword__subheading {
    font-size: 3.4rem;
    line-height: 5.2rem;
  }
}
.news-single__alt .container {
  width: 100%;
  margin-top: 4.8rem;
}
.news-single__alt .news-single__main-article {
  border-bottom: 0.1rem solid rgba(191, 191, 191, 0.25);
}
@media (min-width: 1024px) {
  .news-single__alt .news-single__main-article {
    padding: 2.4rem;
  }
}
.news-single__alt .news-single__main-article-body {
  font-size: 1.6rem;
  line-height: 2.9rem;
  letter-spacing: -0.01rem;
  padding-bottom: 0rem;
}
.news-single__alt .news-single__main-article-heading {
  padding-left: 2.4rem;
}
.news-single__alt .news-single__main-article-heading .subhead {
  margin-bottom: 0.8rem;
  margin-top: 9rem;
}
.news-single__alt .news-single__main-article-heading h1 {
  font-weight: normal;
  font-family: "Ogg", "Times New Roman", serif;
  color: #000000;
  padding: 0;
  margin: 0 0 1rem;
  font-size: 2.8rem;
  line-height: 4rem;
}
@media (min-width: 768px) {
  .news-single__alt .news-single__main-article-heading h1 {
    font-size: 4.4rem;
    line-height: 6rem;
  }
}
.news-single__alt .news-list__announcement-headline {
  font-family: "Ogg", "Times New Roman", serif;
  color: #000000;
  font-size: 2.3rem;
  line-height: 3.5rem;
}
@media (min-width: 768px) {
  .news-single__alt .news-list__announcement-headline {
    font-size: 3rem;
    line-height: 4.1rem;
  }
}
@media (min-width: 1280px) {
  .news-single__alt .news-list__announcement-headline {
    font-size: 3.2rem;
    line-height: 5.2rem;
  }
}
.news-single__alt .news-list__social {
  display: block;
}
@media (min-width: 1024px) {
  .news-single__alt .news-list__social {
    display: initial;
  }
}
.news-single__alt .news-list__social-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 2.4rem;
  position: relative;
}
.news-single__related {
  padding: 11.4rem 0 14rem;
}
.news-single__related .news-list {
  padding-top: 2.3rem;
}
.news-single__related-without-headline {
  padding: 0rem;
}
.news-single__related-without-headline .news-list {
  padding-top: 0rem;
}
.news-single__nav {
  border-top: 0.1rem solid rgba(191, 191, 191, 0.25);
  text-align: center;
}
@media (min-width: 1024px) {
  .news-single__nav {
    display: flex;
  }
}
.news-single__nav-block {
  box-sizing: border-box;
  padding: 2rem;
}
@media (min-width: 1024px) {
  .news-single__nav-block {
    width: 50%;
  }
}
.news-single__nav-block + .news-single__nav-block {
  border-top: 0.1rem solid rgba(191, 191, 191, 0.25);
}
@media (min-width: 1024px) {
  .news-single__nav-block + .news-single__nav-block {
    border-left: 0.1rem solid rgba(191, 191, 191, 0.25);
    border-top: initial;
  }
}
.news-single__nav-block-link {
  display: block;
  position: relative;
  height: 23rem;
}
@media (min-width: 1024px) {
  .news-single__nav-block-link {
    height: 46rem;
  }
}
.news-single__nav-block-link-arrow {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-property: opacity, visibility;
  transition-duration: 150ms;
  line-height: 4rem;
  color: #000000;
  padding-bottom: 1.8rem;
  white-space: nowrap;
  font-size: 3rem;
}
@media (min-width: 768px) {
  .news-single__nav-block-link-arrow {
    font-size: 3.4rem;
  }
}
.news-single__nav-block-link-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 150ms;
  position: relative;
}
.news-single__nav-block-link-preview:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}
.news-single__nav-block-link-preview .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.news-single__nav-block-link-preview-title {
  display: block;
  position: relative;
  z-index: 3;
  color: #ffffff;
  font-size: 2.55rem;
  line-height: 3.9rem;
  text-decoration: underline;
  margin: 2rem;
  font-size: 2rem;
  padding: 1.5rem;
}
@media (min-width: 768px) {
  .news-single__nav-block-link-preview-title {
    font-size: initial;
    padding: 0;
  }
}
.news-single__nav-block-link:hover .news-single__nav-block-link-arrow {
  opacity: 0;
  visibility: hidden;
}
.news-single__nav-block-link:hover .news-single__nav-block-link-preview {
  opacity: 1;
  visibility: visible;
}
.news-single__share {
  height: 6rem;
  width: 30rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fdfdfd;
  border: 0.02rem solid rgba(164, 164, 164, 0.3);
  text-align: center;
  font-size: 0;
  line-height: 0;
  margin-bottom: 8rem;
}
@media (min-width: 768px) {
  .news-single__share {
    margin-bottom: 14.4rem;
  }
}
.news-single__share-title {
  display: flex;
  border-right: 0.1rem solid rgba(191, 191, 191, 0.25);
  padding-right: 2.8rem;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.news-single__share-title p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 0.91;
  letter-spacing: 0.12rem;
  color: #a4a4a4;
}
.news-single__share a {
  display: flex;
  text-align: center;
  font-size: 0;
  line-height: 0;
  font-size: 1.6rem;
  font-family: "ss-social-regular", sans-serif;
  color: #3c3c3c;
  height: 2.2rem;
  width: 2.2rem;
  position: relative;
}
.news-single__share a img {
  display: block;
  width: 100%;
  height: auto;
}
html.mobile .news-single__share a {
  background-color: #000000;
}
html.no-mobile .news-single__share a {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
}

@-moz-document url-prefix() {
  .news-single__share a img {
    width: calc(100% + 0.2rem);
    margin-left: -0.1rem;
    margin-right: -0.1rem;
  }
}
@supports (-ms-ime-align: auto) {
  .news-single__share {
    justify-content: space-around;
  }
}
html.no-mobile .news-single__share a:not(:hover) {
  background: #000000;
}

.search__page .justify-center {
  justify-content: center;
}
.search__page .search__form {
  width: 100%;
  position: relative;
  margin: auto;
  transition: opacity 0.6s 0.75s;
  padding-bottom: 22px;
}
.search__page .search__form-input {
  height: 50px;
  position: relative;
}
.search__page .search__form-input input {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  font: 500 17px/24px Sharp Sans No1, Arial, sans-serif;
  color: #fff;
  box-sizing: border-box;
  padding-right: 100px;
}
.search__page .search__form-input-border {
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 1px;
  background-color: #fff;
  transition: height 0.45s;
}
.search__page .search__form-input-border-gradient {
  background: linear-gradient(270deg, #0369fb 0, #72da0e 16.67%, #f7c233 33.34%, #ee7e33 50.01%, #e93f33 66.68%, #a552f2 83.35%, #0369fb);
  background-size: 2000% 400%;
  animation: gradient 5s linear infinite;
  background-color: #0369fb;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.45s;
}
.search__page .search__form-control {
  position: relative;
  display: block;
  position: absolute;
  z-index: 3;
  transition-property: visibility, opacity;
  transition-duration: 0.3s;
  top: 18px;
  right: 0;
}
.search__page .search__form-button {
  border: none;
  background: none;
  font: 500 17px/24px Sharp Sans No1, Arial, sans-serif;
  display: block;
  height: 24px;
  transition: color 0.45s;
  color: #fff;
}
.search__page .search__form-error {
  color: #ff001f;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  visibility: hidden;
  box-sizing: border-box;
  height: 99px;
}
.search__page .search__form-error img {
  position: relative;
  top: 1px;
  margin-right: 6px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.search__page .search-results ul {
  list-style: none;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.search__page .search-results ul li.h3 {
  font-size: 24px;
  line-height: 34px;
  border-bottom: 1px solid #a4a4a4;
  padding-bottom: 10px;
}
.search__page .search-results ul li.h3 a {
  color: #fff;
  padding-top: 30px;
  padding-bottom: 20px;
}
.search__page .search-results ul li.h3 .complex-link.-skin-white .complex-link__line {
  background: #fff;
}
.search__page a.return-to-search {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .search__page .search-results ul li.h3 {
    font-size: 30px;
    line-height: 45px;
  }
}

@keyframes work-gc-pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
.work-gc {
  background-color: #ffffff;
}
.work-gc__image img {
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.work-gc__image.-intro .cover {
  height: 56rem;
}
@media (min-width: 768px) {
  .work-gc__image.-intro .cover {
    height: 50rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__image.-intro .cover {
    height: 68rem;
  }
}
@media (min-width: 1440px) {
  .work-gc__image.-intro .cover {
    height: 80rem;
  }
}
@media (min-width: 2000px) {
  .work-gc__image.-intro .cover {
    height: 110rem;
  }
}
.work-gc__image.-breakthrough img {
  height: 55rem;
}
@media (min-width: 1024px) {
  .work-gc__image.-breakthrough img {
    height: initial;
    width: 100%;
  }
}
.work-gc__image.-breakthrough .container {
  overflow: hidden;
  width: 94.5%;
}
@media (min-width: 768px) {
  .work-gc__image.-breakthrough .container {
    width: initial;
  }
}
.work-gc__image.-newsfeed img {
  width: 132%;
  height: auto;
}
@media (min-width: 768px) {
  .work-gc__image.-newsfeed img {
    width: initial;
    height: 57.7rem;
  }
}
.work-gc__image.-during img {
  width: 116%;
}
@media (min-width: 768px) {
  .work-gc__image.-during img {
    height: 45rem;
    width: initial;
  }
}
.work-gc__image.-process {
  background-image: linear-gradient(to bottom, #fafafa 0%, #ffffff 86%, #ffffff 100%);
  padding: 7.1rem 0 5.6rem;
}
@media (min-width: 1024px) {
  .work-gc__image.-process {
    padding: 22.8rem 0 13.1rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__image.-process .container {
    padding-left: 7.35%;
  }
}
.work-gc__image.-process h2 {
  margin-bottom: 8.1rem;
}
@media (min-width: 1024px) {
  .work-gc__image.-process h2 {
    margin-bottom: 10.1rem;
  }
}
.work-gc__image.-process img {
  height: 34.2rem;
}
@media (min-width: 768px) {
  .work-gc__image.-process img {
    height: 60.3rem;
    margin-left: -2rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__image.-keyboard .container {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .work-gc__image.-keyboard .container {
    padding: 0 7.35%;
  }
}
.work-gc__image.-keyboard figure {
  display: block;
}
@media (min-width: 1024px) {
  .work-gc__image.-keyboard figure {
    width: 50%;
    box-sizing: border-box;
    padding-right: 2.5rem;
  }
}
.work-gc__image.-keyboard figure:last-child {
  display: none;
}
@media (min-width: 1024px) {
  .work-gc__image.-keyboard figure:last-child {
    display: initial;
  }
}
.work-gc__image.-keyboard img {
  max-width: 100%;
}
@media (min-width: 1024px) {
  .work-gc__image.-keyboard img {
    left: auto;
    transform: none;
    max-width: 80%;
  }
}
@media (min-width: 1280px) {
  .work-gc__image.-keyboard img {
    max-width: 100%;
  }
}
.work-gc__image.-ibeacon img {
  min-width: 125%;
  min-height: 66.9rem;
  left: -9.4rem;
  transform: none;
}
@media (min-width: 768px) {
  .work-gc__image.-ibeacon img {
    min-width: 0;
    min-height: 0;
    left: 50%;
    transform: translateX(-40%);
    height: 73.3rem;
    margin-left: 2.1rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__image.-ibeacon img {
    transform: translateX(-50%);
  }
}
.work-gc__text.-assignment {
  margin: 10.4rem 0 11.7rem;
}
@media (min-width: 768px) {
  .work-gc__text.-assignment {
    margin: 10.6rem 0 12.3rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__text.-assignment {
    margin: 30.6rem 0 23.6rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__text.-assignment {
    margin: 26.8rem 0 27.2rem;
  }
}
.work-gc__text.-background {
  margin: 10.7rem 0 11.5rem;
}
@media (min-width: 768px) {
  .work-gc__text.-background {
    margin: 10.6rem 0 12.3rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__text.-background {
    margin: 26.7rem 0 14.6rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__text.-background {
    margin: 26.7rem 0 14.1rem;
  }
}
.work-gc__text.-challenge {
  margin: 10.3rem 0 9.9rem;
}
@media (min-width: 768px) {
  .work-gc__text.-challenge {
    margin: 10.6rem 0 10.5rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__text.-challenge {
    margin: 26.7rem 0 26.5rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__text.-challenge {
    margin: 26.2rem 0 26rem;
  }
}
.work-gc__text.-breakthrough {
  margin: 9.9rem 0 10.5rem;
}
@media (min-width: 768px) {
  .work-gc__text.-breakthrough {
    margin: 10.5rem 0 11.8rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__text.-breakthrough {
    margin: 26.5rem 0 25.3rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__text.-breakthrough {
    margin: 26rem 0 12.5rem;
  }
}
.work-gc__text.-analytics {
  padding: 19.3rem 0 22.2rem;
  position: relative;
}
@media (min-width: 768px) {
  .work-gc__text.-analytics {
    padding: 29.6rem 0 32.9rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__text.-analytics {
    padding: 39rem 0 35.5rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__text.-analytics {
    padding: 39rem 0 40.1rem;
  }
}
@media (min-width: 1440px) {
  .work-gc__text.-analytics {
    position: initial;
  }
}
.work-gc__text.-analytics .container {
  position: static;
}
@media (min-width: 1440px) {
  .work-gc__text.-analytics .container {
    position: relative;
  }
}
.work-gc__text.-analytics .work-single__text-triangle {
  border-top-color: #ffe4e4;
}
.work-gc__text.-keyboard {
  margin: 6.6rem 0 4.4rem;
}
@media (min-width: 1024px) {
  .work-gc__text.-keyboard {
    margin: 14.1rem 0 12.5rem;
  }
}
.work-gc__text.-ibeacon {
  margin: 14rem 0 -0.2rem;
}
@media (min-width: 768px) {
  .work-gc__text.-ibeacon {
    margin: 14rem 0 3.2rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__text.-ibeacon {
    margin: 32.8rem 0 3.2rem;
  }
}
.work-gc__text.-ibeacon a {
  display: inline-block;
  font-size: 1.7rem;
  color: #666666;
  margin-top: 1.5rem;
}
@media (min-width: 1024px) {
  .work-gc__text.-ibeacon a {
    margin-top: 1.3rem;
  }
}
.work-gc__quote .container {
  padding: 3rem 0;
  box-sizing: border-box;
  color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 41rem;
  width: 92.2%;
}
@media (min-width: 768px) {
  .work-gc__quote .container {
    min-height: 56.8rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__quote .container {
    width: 94.043%;
  }
}
@media (min-width: 1280px) {
  .work-gc__quote .container {
    width: 92.2%;
  }
}
.work-gc__quote .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.work-gc__quote .work-single__quote {
  position: relative;
  z-index: 2;
  margin: auto;
}
.work-gc__quote .work-single__quote-text {
  text-align: center;
}
.work-gc__quote.-assignment .work-single__quote {
  width: 100%;
}
@media (min-width: 768px) {
  .work-gc__quote.-assignment .work-single__quote {
    width: 75%;
  }
}
@media (min-width: 1024px) {
  .work-gc__quote.-assignment .work-single__quote {
    width: 67%;
  }
}
.work-gc__quote.-assignment .work-single__quote-text {
  padding-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .work-gc__quote.-assignment .work-single__quote-text {
    padding-bottom: 5.1rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__quote.-assignment .work-single__quote-text {
    padding-bottom: 5rem;
  }
}
.work-gc__quote.-assignment .work-single__quote-cite {
  margin: 0 0 1.2rem 25.8%;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .work-gc__quote.-assignment .work-single__quote-cite {
    margin: 0 0 1.2rem 50.8%;
  }
}
@media (min-width: 768px) {
  .work-gc__quote.-ibeacon {
    margin-top: 10rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__quote.-ibeacon {
    margin-top: 20.6rem;
  }
}
.work-gc__quote.-ibeacon .work-single__quote {
  width: 95%;
}
@media (min-width: 1024px) {
  .work-gc__quote.-ibeacon .work-single__quote {
    width: 75%;
  }
}
.work-gc__quote.-ibeacon .work-single__quote-text {
  padding: 1.8rem 0rem 2.1rem 0rem;
}
@media (min-width: 768px) {
  .work-gc__quote.-ibeacon .work-single__quote-text {
    padding: 0 0 7.1rem 0;
  }
}
@media (min-width: 1280px) {
  .work-gc__quote.-ibeacon .work-single__quote-text {
    padding: 0 0 4rem 0;
  }
}
.work-gc__quote.-ibeacon .work-single__quote-cite {
  margin: 0 0 1.2rem 20.8%;
}
@media (min-width: 768px) {
  .work-gc__quote.-ibeacon .work-single__quote-cite {
    margin: 0 0 1.2rem 40.8%;
  }
}
.work-gc__background {
  margin: -0.2rem 0;
}
@media (min-width: 768px) {
  .work-gc__background {
    margin: 0;
  }
}
.work-gc__background img {
  display: block;
  height: 100%;
}
@media (min-width: 768px) {
  .work-gc__background img {
    height: initial;
    width: 100%;
  }
}
.work-gc__background .container {
  width: 100%;
}
@media (min-width: 768px) {
  .work-gc__background .container {
    width: initial;
  }
}
@media (min-width: 1280px) {
  .work-gc__background .container {
    padding-left: 7%;
  }
}
.work-gc__background-image {
  flex: 0 0 auto;
  border: 0.2rem solid #ffffff;
  overflow: hidden;
}
@media (min-width: 768px) {
  .work-gc__background-image {
    border: initial;
    overflow: visible;
    padding: 0.5rem 0.6rem;
  }
}
.work-gc__background-image.-top-left {
  height: 25.1rem;
}
@media (min-width: 768px) {
  .work-gc__background-image.-top-left {
    height: initial;
    width: 35.47%;
  }
}
.work-gc__background-image.-top-right {
  height: 27.3rem;
}
@media (min-width: 768px) {
  .work-gc__background-image.-top-right {
    height: initial;
    width: 53.805%;
  }
}
.work-gc__background-image.-bottom-left {
  display: none;
}
@media (min-width: 768px) {
  .work-gc__background-image.-bottom-left {
    display: initial;
    width: 17.31%;
  }
}
.work-gc__background-image.-bottom-center {
  height: 27.3rem;
}
.work-gc__background-image.-bottom-center img {
  position: relative;
  left: -5rem;
}
@media (min-width: 768px) {
  .work-gc__background-image.-bottom-center {
    height: initial;
    width: 53.7%;
  }
  .work-gc__background-image.-bottom-center img {
    position: static;
    left: initial;
  }
}
.work-gc__background-image.-bottom-right {
  height: 25.1rem;
}
@media (min-width: 768px) {
  .work-gc__background-image.-bottom-right {
    height: initial;
    width: 26.4781%;
  }
}
.work-gc__background-top, .work-gc__background-bottom {
  flex-direction: row-reverse;
  justify-content: center;
}
@media (min-width: 768px) {
  .work-gc__background-top, .work-gc__background-bottom {
    flex-direction: initial;
    justify-content: initial;
  }
}
.work-gc__background-top {
  display: flex;
  align-items: flex-end;
}
.work-gc__background-bottom {
  display: flex;
}
.work-gc__takeaction {
  margin: 10.1rem 0 13.3rem;
}
@media (min-width: 1024px) {
  .work-gc__takeaction {
    margin: 24.8rem 0 26.7rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__takeaction .container {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .work-gc__takeaction .container {
    padding-left: 7.35%;
  }
}
.work-gc__takeaction-text {
  padding-top: 0.9rem;
  margin-bottom: 5.1rem;
}
@media (min-width: 768px) {
  .work-gc__takeaction-text {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .work-gc__takeaction-text {
    margin-right: 9.2%;
    width: 36.5%;
    flex: 0 0 auto;
  }
}
.work-gc__takeaction-text h4 img {
  width: 1.8rem;
  margin: 0.2rem -0.1rem 0rem 0.2rem;
}
@media (min-width: 768px) {
  .work-gc__takeaction-text h4 img {
    width: initial;
    margin: 0 0.3rem 0 0.4rem;
  }
}
.work-gc__takeaction-text > p {
  margin: 2.9rem 0.2rem 3.6rem;
}
@media (min-width: 768px) {
  .work-gc__takeaction-text > p {
    margin: 3.1rem 0.7rem 5.8rem;
  }
}
.work-gc__takeaction-text dl {
  padding: 0;
  margin: 0 0 0 0.2rem;
}
@media (min-width: 768px) {
  .work-gc__takeaction-text dl {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 1024px) {
  .work-gc__takeaction-text dl {
    display: initial;
    flex-wrap: initial;
  }
}
.work-gc__takeaction-text dl dt {
  width: 100%;
  font-weight: 600;
  padding: 0;
  font-size: 1.7rem;
  line-height: 4rem;
  margin: 0 0 0.5rem;
}
@media (min-width: 768px) {
  .work-gc__takeaction-text dl dt {
    font-size: 2.2rem;
    line-height: 4.6rem;
    margin: 0 0 0.3rem;
  }
}
.work-gc__takeaction-text dl dd {
  padding: 0;
  margin: 0 0 1.1rem;
}
@media (min-width: 480px) {
  .work-gc__takeaction-text dl dd {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .work-gc__takeaction-text dl dd {
    margin: 0 0 0.7rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__takeaction-text dl dd {
    width: initial;
  }
}
.work-gc__takeaction-text dl dd figure,
.work-gc__takeaction-text dl dd p {
  display: inline-block;
  vertical-align: middle;
}
.work-gc__takeaction-text dl dd figure {
  width: 2.1rem;
  font-size: 0;
  line-height: 0;
  text-align: center;
  margin: 0.4rem 1.6rem 0 0;
}
.work-gc__takeaction-text dl dd figure img {
  display: inline-block;
  transform: scale(0.9);
}
@media (min-width: 768px) {
  .work-gc__takeaction-text dl dd figure img {
    transform: none;
  }
}
@media (min-width: 768px) {
  .work-gc__takeaction-image {
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .work-gc__takeaction-image {
    flex: 1 1 auto;
  }
}
.work-gc__takeaction-image img {
  width: 120%;
  margin-left: -0.9rem;
}
@media (min-width: 768px) {
  .work-gc__takeaction-image img {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    width: initial;
    margin-left: 0;
  }
}
.work-gc__newsfeed {
  margin: 9rem 0 3.5rem;
}
@media (min-width: 1024px) {
  .work-gc__newsfeed {
    margin: 26.7rem 0 -1.1rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__newsfeed .container {
    padding-left: 7.35%;
  }
}
.work-gc__newsfeed-text {
  position: relative;
  z-index: 2;
}
.work-gc__newsfeed-text h4 img {
  margin: 0 0.1rem;
}
@media (min-width: 768px) {
  .work-gc__newsfeed-text h4 img {
    width: 3.5rem;
    margin: 0 0.8rem 0 -0.1rem;
  }
}
.work-gc__newsfeed-text p {
  max-width: 41.5rem;
  margin-top: 0.3rem;
}
.work-gc__rewards {
  margin: 12.9rem 0 18.1rem;
}
@media (min-width: 1024px) {
  .work-gc__rewards {
    margin: 34.9rem 0 22.3rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__rewards .container {
    padding: 0 7.35%;
  }
}
.work-gc__rewards-text {
  margin-bottom: 7.7rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-text {
    margin-bottom: 3.3rem;
  }
}
.work-gc__rewards-text h4 img:first-child {
  width: 2rem;
  margin: 0 0 0 0.2rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-text h4 img:first-child {
    width: initial;
    margin: 0 1rem 0.2rem 0.3rem;
  }
}
.work-gc__rewards-text h4 img:last-child {
  display: none;
}
@media (min-width: 768px) {
  .work-gc__rewards-text h4 img:last-child {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.2rem;
  }
}
.work-gc__rewards-text p {
  max-width: 50rem;
  margin: 3.1rem 0 0 0.3rem;
}
.work-gc__rewards-covers {
  white-space: nowrap;
  position: relative;
  font-size: 0;
  line-height: 0;
  height: 48.2rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers {
    height: initial;
    margin-left: -0.5rem;
    padding-top: 43.1668237512%;
  }
}
.work-gc__rewards-covers-item {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers-item {
    top: initial;
    left: initial;
  }
}
.work-gc__rewards-covers-item img {
  display: block;
  position: relative;
  z-index: 2;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0.3rem 0.6rem 2rem rgba(0, 0, 0, 0.15);
}
.work-gc__rewards-covers-item i {
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 70%;
  margin: -35%;
  background: url("/work/global-citizen-app/images/rewards-circle.png") no-repeat 50% 50%;
  background-size: contain;
  border-radius: 50%;
  animation: work-gc-pulse 2s infinite linear;
}
.work-gc__rewards-covers-item i:last-child {
  animation-delay: 1s;
}
.work-gc__rewards-covers-item:nth-child(1) {
  width: 8.2rem;
  margin: 38.4rem 0 0 -17.7rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers-item:nth-child(1) {
    width: initial;
    margin: initial;
    top: 44.9781659389%;
    left: 3.0360531309%;
    width: 8.6337760911%;
  }
}
.work-gc__rewards-covers-item:nth-child(2) {
  width: 14rem;
  margin: 34.2rem 0 0 -7.5rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers-item:nth-child(2) {
    width: initial;
    margin: initial;
    top: 46.7248908297%;
    left: 14.3263757116%;
    width: 14.8007590133%;
  }
}
.work-gc__rewards-covers-item:nth-child(3) {
  width: 11.3rem;
  margin: 7.4rem 0 0 -26.4rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers-item:nth-child(3) {
    width: initial;
    margin: initial;
    top: 24.4541484716%;
    left: 26.8500948767%;
    width: 11.8595825427%;
  }
}
.work-gc__rewards-covers-item:nth-child(4) {
  width: 16.8rem;
  margin: 19.4rem 0 0 -22.1rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers-item:nth-child(4) {
    width: initial;
    margin: initial;
    top: 53.2751091703%;
    left: 31.4990512334%;
    width: 17.7419354839%;
  }
}
.work-gc__rewards-covers-item:nth-child(5) {
  width: 20rem;
  margin-left: -12.9rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers-item:nth-child(5) {
    width: initial;
    margin-left: initial;
    top: 6.1135371179%;
    left: 41.3662239089%;
    width: 20.9677419355%;
  }
}
.work-gc__rewards-covers-item:nth-child(6) {
  width: 10.5rem;
  margin: 21.2rem 0 0 -2.6rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers-item:nth-child(6) {
    width: initial;
    margin: initial;
    top: 57.8602620087%;
    left: 52.1821631879%;
    width: 11.0056925996%;
  }
}
.work-gc__rewards-covers-item:nth-child(7) {
  width: 18.2rem;
  margin: 8.8rem 0 0 8.1rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers-item:nth-child(7) {
    width: initial;
    margin: initial;
    top: 27.5109170306%;
    left: 63.4724857685%;
    width: 19.165085389%;
  }
}
.work-gc__rewards-covers-item:nth-child(8) {
  width: 10.5rem;
  margin: 29.6rem 0 0 7.8rem;
}
@media (min-width: 768px) {
  .work-gc__rewards-covers-item:nth-child(8) {
    width: initial;
    margin: initial;
    top: 44.9781659389%;
    left: 85.4838709677%;
    width: 11.0056925996%;
  }
}
.work-gc__rewards-covers-item:nth-child(8) i {
  width: 100%;
  height: 100%;
  margin: -50%;
}
@media (min-width: 768px) {
  .work-gc__pre {
    background-image: linear-gradient(to bottom, #fafafa 0%, #ffffff 86%, #ffffff 100%);
    padding-top: 23.8rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__pre .container {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .work-gc__pre .container {
    padding-left: 7.35%;
  }
}
.work-gc__pre-text {
  margin-bottom: 3.2rem;
}
@media (min-width: 1024px) {
  .work-gc__pre-text {
    margin-bottom: 0;
    width: 45.2%;
    flex: 0 0 auto;
  }
}
.work-gc__pre-text p {
  margin: 3.1rem 0 3.3rem 0;
}
@media (min-width: 768px) {
  .work-gc__pre-text p {
    margin: 3.1rem 0 3.8rem 0;
  }
}
.work-gc__pre-text img {
  display: block;
  margin: auto;
  width: 84.5%;
  max-width: 40.2rem;
}
@media (min-width: 1024px) {
  .work-gc__pre-text img {
    display: initial;
    margin: initial;
    width: initial;
    max-width: 100%;
  }
}
.work-gc__pre-image {
  text-align: center;
}
@media (min-width: 1024px) {
  .work-gc__pre-image {
    text-align: right;
    flex: 1 1 auto;
    margin-top: 1.1rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__pre-image {
    text-align: center;
  }
}
.work-gc__pre-image img {
  display: inline-block;
  vertical-align: top;
  max-width: 95.5%;
  margin-left: -0.8rem;
}
@media (min-width: 1024px) {
  .work-gc__pre-image img {
    margin-left: 0;
    max-width: 80%;
  }
}
@media (min-width: 1280px) {
  .work-gc__pre-image img {
    max-width: 100%;
  }
}
.work-gc__during {
  margin: 8.5rem 0 6.7rem;
}
@media (min-width: 1024px) {
  .work-gc__during {
    margin: 21rem 0 7.3rem;
  }
}
@media (min-width: 1280px) {
  .work-gc__during .container {
    padding-left: 7.35%;
  }
}
.work-gc__during p {
  max-width: 52rem;
  margin-top: 3.6rem;
}
@media (min-width: 768px) {
  .work-gc__during p {
    margin-top: initial;
  }
}
@media (min-width: 1024px) {
  .work-gc__during p {
    margin-top: 3.1rem;
  }
}
.work-gc__festivals {
  margin: 17.5rem 0 7.3rem;
}
@media (min-width: 1024px) {
  .work-gc__festivals {
    margin: 35.8rem 0 25.7rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__festivals .container {
    display: flex;
  }
}
.work-gc__festivals-block {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
@media (min-width: 1024px) {
  .work-gc__festivals-block {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .work-gc__festivals-block:first-child {
    padding-right: 0.5rem;
  }
}
.work-gc__festivals-block:last-child {
  padding: 1.6rem 0 0 0;
}
@media (min-width: 1024px) {
  .work-gc__festivals-block:last-child {
    padding: 15.9% 0 0 0.5rem;
  }
}
.work-gc__festivals-block-item {
  width: 32%;
}
.work-gc__festivals-block-item img {
  display: block;
  width: 100%;
}
.work-gc__festivals-block-item figcaption {
  display: block;
  text-align: center;
  font-weight: 500;
  color: #a4a4a4;
  padding-top: 0.33em;
  white-space: nowrap;
  font-size: 1.1rem;
  line-height: 2rem;
}
@media (min-width: 480px) {
  .work-gc__festivals-block-item figcaption {
    font-size: 1.3rem;
    line-height: 2.4em;
  }
}
@media (min-width: 768px) {
  .work-gc__festivals-block-item figcaption {
    font-size: 1.6rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__festivals-block-item figcaption {
    font-size: 1.4rem;
  }
}
@media (min-width: 1440px) {
  .work-gc__festivals-block-item figcaption {
    font-size: 1.5rem;
  }
}
.work-gc__moments {
  margin-bottom: 9.8rem;
}
@media (min-width: 1024px) {
  .work-gc__moments {
    margin-bottom: 26rem;
  }
}
@media (min-width: 1024px) {
  .work-gc__moments .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 7.35%;
  }
}
.work-gc__moments figure {
  display: block;
  font-size: 0;
  line-height: 0;
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .work-gc__moments figure {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(1) {
    width: 49.5265151515%;
  }
}
.work-gc__moments figure:nth-child(1) img {
  width: 74%;
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(1) img {
    width: initial;
  }
}
.work-gc__moments figure:nth-child(2) {
  text-align: right;
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(2) {
    text-align: initial;
    margin-top: 23rem;
    width: 29.071969697%;
  }
}
.work-gc__moments figure:nth-child(2) img {
  width: 50.2%;
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(2) img {
    width: initial;
  }
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(3) {
    width: 100%;
    text-align: center;
    margin-top: 15rem;
  }
}
.work-gc__moments figure:nth-child(3) img {
  width: 100%;
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(3) img {
    width: initial;
  }
}
.work-gc__moments figure:nth-child(5) {
  text-align: right;
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(5) {
    text-align: initial;
    margin-top: 38.7rem;
    width: 29.1666666667%;
  }
}
.work-gc__moments figure:nth-child(5) img {
  width: 58.2%;
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(5) img {
    width: initial;
  }
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(6) {
    margin-top: -15.4rem;
    width: 69.8%;
    text-align: center;
  }
}
.work-gc__moments figure:nth-child(6) img {
  width: 57%;
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(6) img {
    width: 41.7910447761%;
  }
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(7) {
    width: 100%;
    text-align: center;
    margin-top: 22.6rem;
  }
}
.work-gc__moments figure:nth-child(7) img {
  width: 100%;
}
@media (min-width: 1024px) {
  .work-gc__moments figure:nth-child(7) img {
    width: initial;
  }
}
.work-gc__moments img {
  max-width: 100%;
}
.work-gc__moments p {
  max-width: 41rem;
  margin: 7rem 0 7.6rem;
}
@media (min-width: 1024px) {
  .work-gc__moments p {
    margin: 23.6rem 0 0;
  }
}
.work-gc__max-width-img {
  max-width: 124rem;
}

.work-schumacher {
  background-color: #ffffff;
}
.work-schumacher__image img {
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.work-schumacher__image.-intro .cover {
  height: 56rem;
}
@media (min-width: 768px) {
  .work-schumacher__image.-intro .cover {
    height: 50rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__image.-intro .cover {
    height: 68rem;
  }
}
@media (min-width: 1440px) {
  .work-schumacher__image.-intro .cover {
    height: 80rem;
  }
}
@media (min-width: 2000px) {
  .work-schumacher__image.-intro .cover {
    height: 110rem;
  }
}
.work-schumacher__image.-background .cover {
  height: 40.4rem;
}
@media (min-width: 480px) {
  .work-schumacher__image.-background .cover {
    height: 55.3rem;
  }
}
@media (min-width: 768px) {
  .work-schumacher__image.-background .cover {
    height: 81.4rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__image.-background .cover {
    height: 101.7rem;
  }
}
.work-schumacher__image.-solution .cover {
  height: 55rem;
  background-position: 64.5% 0;
}
@media (min-width: 768px) {
  .work-schumacher__image.-solution .cover {
    height: 67rem;
    background-position: top center;
  }
}
@media (min-width: 1440px) {
  .work-schumacher__image.-solution .cover {
    height: 94.2rem;
  }
}
@media (min-width: 2000px) {
  .work-schumacher__image.-solution .cover {
    height: 150rem;
  }
}
.work-schumacher__image.-experience img {
  height: 67rem;
  margin-left: 2.5rem;
}
@media (min-width: 768px) {
  .work-schumacher__image.-experience img {
    width: 97.1%;
    height: initial;
    margin-left: -0.4rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__image.-experience img {
    width: 91.7%;
    margin-left: -0.5rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__image.-experience img {
    width: 92.5%;
  }
}
@media (min-width: 1440px) {
  .work-schumacher__image.-experience img {
    width: 82%;
  }
}
.work-schumacher__image.-filters img {
  height: 54rem;
}
@media (min-width: 768px) {
  .work-schumacher__image.-filters img {
    height: initial;
    width: 97.8%;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__image.-filters img {
    width: 100.3%;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__image.-filters img {
    width: 92.5%;
  }
}
@media (min-width: 1440px) {
  .work-schumacher__image.-filters img {
    width: 88%;
  }
}
.work-schumacher__image.-search img {
  height: 71.4rem;
  margin-left: 3.8rem;
}
@media (min-width: 768px) {
  .work-schumacher__image.-search img {
    height: initial;
    width: 117.8%;
    margin-left: 2rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__image.-search img {
    width: 108.4%;
    margin-left: 2.3rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__image.-search img {
    width: 134%;
    margin-left: 2.4rem;
  }
}
@media (min-width: 1440px) {
  .work-schumacher__image.-search img {
    width: 81%;
    margin-left: 2.6rem;
  }
}
.work-schumacher__image.-showcase img {
  width: 111.1%;
}
@media (min-width: 768px) {
  .work-schumacher__image.-showcase img {
    width: 81.3%;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__image.-showcase img {
    width: initial;
    height: 63.9rem;
  }
}
@media (min-width: 1440px) {
  .work-schumacher__image.-showcase img {
    height: 80rem;
  }
}
.work-schumacher__text.-assignment {
  margin: 10.4rem 0 11.7rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-assignment {
    margin: 10.6rem 0 12.3rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-assignment {
    margin: 30.6rem 0 23.6rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-assignment {
    margin: 26.8rem 0 27.2rem;
  }
}
.work-schumacher__text.-background {
  margin: 10.7rem 0 11.5rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-background {
    margin: 10.6rem 0 12.3rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-background {
    margin: 26.7rem 0 14.6rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-background {
    margin: 26.7rem 0 14.3rem;
  }
}
.work-schumacher__text.-challenge {
  margin: 10.3rem 0 9.9rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-challenge {
    margin: 10.6rem 0 10.5rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-challenge {
    margin: 26.7rem 0 26.5rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-challenge {
    margin: 26.7rem 0 25.9rem;
  }
}
.work-schumacher__text.-breakthrough {
  margin: 9.9rem 0 10.5rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-breakthrough {
    margin: 10.5rem 0 11.8rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-breakthrough {
    margin: 26.5rem 0 25.3rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-breakthrough {
    margin: 25.9rem 0 24.2rem;
  }
}
.work-schumacher__text.-solution {
  margin: 10.5rem 0 7.4rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-solution {
    margin: 11.8rem 0 12.7rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-solution {
    margin: 24.2rem 0 14.8rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-solution {
    margin: 24.2rem 0 14.5rem;
  }
}
.work-schumacher__text.-experience {
  margin: 11rem 0 5.4rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-experience {
    margin: 10.6rem 0 21.8rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-experience {
    margin: 26.7rem 0 21.6rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-experience {
    margin: 26.6rem 0 26.1rem;
  }
}
.work-schumacher__text.-filters {
  margin: -1.8rem 0 10.2rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-filters {
    margin: 17.4rem 0 19.5rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-filters {
    margin: 21.1rem 0 23.9rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-filters {
    margin: 24.8rem 0 25.3rem;
  }
}
.work-schumacher__text.-search {
  margin: 10.1rem 0 3.3rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-search {
    margin: 25.7rem 0 14.6rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-search {
    margin: 43rem 0 15.8rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-search {
    margin: 37.6rem 0 15.9rem;
  }
}
.work-schumacher__text.-showcase {
  margin: 6.1rem 0 11.5rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-showcase {
    margin: 12.9rem 0 10.1rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-showcase {
    margin: 28.2rem 0 14.3rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-showcase {
    margin: 28rem 0 14.2rem;
  }
}
.work-schumacher__text.-strategy {
  position: relative;
  padding: 19.3rem 0 22.2rem;
}
@media (min-width: 768px) {
  .work-schumacher__text.-strategy {
    padding: 29.6rem 0 32.9rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__text.-strategy {
    padding: 39rem 0 35.5rem;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__text.-strategy {
    padding: 39rem 0 40.1rem;
  }
}
@media (min-width: 1440px) {
  .work-schumacher__text.-strategy {
    position: static;
  }
}
.work-schumacher__text.-strategy .container {
  position: static;
}
@media (min-width: 1440px) {
  .work-schumacher__text.-strategy .container {
    position: relative;
  }
}
.work-schumacher__text.-strategy .work-single__text-triangle {
  border-top-color: #fff7b1;
}
.work-schumacher__quote .container {
  padding: 3rem 0;
  box-sizing: border-box;
  background-color: #fff7b1;
  color: #000000;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 41rem;
  width: 92.2%;
}
@media (min-width: 768px) {
  .work-schumacher__quote .container {
    min-height: 56.8rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__quote .container {
    width: 94.043%;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__quote .container {
    width: 92.2%;
  }
}
.work-schumacher__quote .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.work-schumacher__quote .work-single__quote {
  position: relative;
  z-index: 2;
}
.work-schumacher__quote.-assignment .cover {
  opacity: 0.13;
  background-position: 111% 50%;
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-assignment .cover {
    opacity: 1;
    background-position: 75.5% 50%;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__quote.-assignment .cover {
    background-position: 111% 50%;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-assignment .work-single__quote {
    padding: 0 0 2.6rem 6.5%;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__quote.-assignment .work-single__quote {
    padding: 0 0 2.6rem 8.3%;
    width: 48%;
  }
}
.work-schumacher__quote.-assignment .work-single__quote-text {
  width: 78%;
  padding: 0.6rem 0 1.4rem;
  margin: auto;
  text-align: center;
}
@media (min-width: 768px) {
  .work-schumacher__quote.-assignment .work-single__quote-text {
    width: 74%;
    padding: 0.2rem 0 7.1rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-assignment .work-single__quote-text {
    width: initial;
    padding: 0 0 7.1rem;
    margin: initial;
    text-align: initial;
  }
}
@media (min-width: 1280px) {
  .work-schumacher__quote.-assignment .work-single__quote-text {
    padding: 0 0 7.4rem;
  }
}
.work-schumacher__quote.-assignment .work-single__quote-cite {
  margin: 0 0 0 25%;
  width: 55%;
}
@media (min-width: 768px) {
  .work-schumacher__quote.-assignment .work-single__quote-cite {
    margin: 0 0 0 57.6%;
    width: initial;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-assignment .work-single__quote-cite {
    margin: 0 0 0.6rem 50%;
  }
}
.work-schumacher__quote.-strategy .container {
  overflow: hidden;
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-strategy .container {
    overflow: visible;
  }
}
.work-schumacher__quote.-strategy img {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 90%;
  opacity: 0.95;
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-strategy img {
    left: 50%;
    margin-left: -24.5%;
    max-width: 46%;
    transform: translate(-50%, -50%);
    height: initial;
    opacity: initial;
  }
}
@media (min-width: 1440px) {
  .work-schumacher__quote.-strategy img {
    left: 4.7%;
    max-width: 39.9842%;
    transform: none;
    margin-left: 0;
  }
}
.work-schumacher__quote.-strategy img:nth-child(1) {
  left: 0;
  transform: translate(-69%, -50%);
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-strategy img:nth-child(1) {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 1440px) {
  .work-schumacher__quote.-strategy img:nth-child(1) {
    left: 25%;
  }
}
.work-schumacher__quote.-strategy img:nth-child(2) {
  right: 0;
  transform: translate(69%, -50%);
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-strategy .work-single__quote {
    padding-left: 50.5%;
    width: 43%;
  }
}
.work-schumacher__quote.-strategy .work-single__quote-text {
  width: 78%;
  padding: 0.4rem 0 3.6rem;
  margin: auto;
  text-align: center;
}
@media (min-width: 768px) {
  .work-schumacher__quote.-strategy .work-single__quote-text {
    width: 68%;
    padding: 0.2rem 0 7.1rem;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-strategy .work-single__quote-text {
    width: initial;
    padding: initial;
    margin: initial;
    text-align: initial;
    padding: 0 0 7.4rem;
  }
}
.work-schumacher__quote.-strategy .work-single__quote-cite {
  margin: 0 0 0 25%;
  width: 55%;
}
@media (min-width: 768px) {
  .work-schumacher__quote.-strategy .work-single__quote-cite {
    margin: 0 0 0 57.9%;
    width: initial;
  }
}
@media (min-width: 1024px) {
  .work-schumacher__quote.-strategy .work-single__quote-cite {
    margin: 0 0 2.2rem 50%;
  }
}

.work-short__image img {
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
}
.work-short__image .cover {
  height: 56rem;
}
@media (min-width: 768px) {
  .work-short__image .cover {
    height: 50rem;
  }
}
@media (min-width: 1024px) {
  .work-short__image .cover {
    height: 68rem;
  }
}
@media (min-width: 1440px) {
  .work-short__image .cover {
    height: 80rem;
  }
}
@media (min-width: 2000px) {
  .work-short__image .cover {
    height: 110rem;
  }
}
.work-short__image .image {
  height: 25rem;
}
@media (min-width: 768px) {
  .work-short__image .image {
    height: 40rem;
  }
}
@media (min-width: 1024px) {
  .work-short__image .image {
    height: initial;
  }
}
.work-short__text {
  margin: 10.4rem 0 11.7rem;
}
@media (min-width: 768px) {
  .work-short__text {
    margin: 10.6rem 0 12.3rem;
  }
}
@media (min-width: 1024px) {
  .work-short__text {
    margin: 30.6rem 0 23.6rem;
  }
}
@media (min-width: 1280px) {
  .work-short__text {
    margin: 26.8rem 0 27.2rem;
  }
}
.work-short__quote .container {
  padding: 3rem 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 41rem;
  width: 92.2%;
}
@media (min-width: 768px) {
  .work-short__quote .container {
    min-height: 56.8rem;
  }
}
@media (min-width: 1024px) {
  .work-short__quote .container {
    width: 94.043%;
  }
}
@media (min-width: 1280px) {
  .work-short__quote .container {
    width: initial;
  }
}
.work-short__quote .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 1;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .work-short__quote .cover {
    opacity: initial;
    margin: initial;
    background-position: 75.5% 50%;
  }
}
@media (min-width: 1280px) {
  .work-short__quote .cover {
    background-position: initial;
  }
}
.work-short__quote .cover + .work-single__quote {
  color: #ffffff;
}
.work-short__quote .work-single__quote {
  position: relative;
  z-index: 2;
  margin: auto;
}
@media (min-width: 1024px) {
  .work-short__quote .work-single__quote {
    padding: 0 0 2.6rem 6.5%;
    width: 90%;
  }
}
@media (min-width: 1280px) {
  .work-short__quote .work-single__quote {
    padding: 0 7% 2.6rem 7%;
  }
}
.work-short__quote .work-single__quote:only-child {
  transform: translateY(-25%);
}
@media (min-width: 768px) {
  .work-short__quote .work-single__quote:only-child {
    transform: translateY(-50%);
  }
}
.work-short__quote .work-single__quote-text {
  text-align: center;
  width: 80%;
  padding: 0.6rem 0 2.5rem;
  margin: auto;
  text-align: center;
}
@media (min-width: 768px) {
  .work-short__quote .work-single__quote-text {
    width: initial;
    padding: 0 0 2.5rem 0;
  }
}
@media (min-width: 1024px) {
  .work-short__quote .work-single__quote-text {
    padding: 0 0 5rem 0;
    margin: initial;
    text-align: initial;
  }
}
.work-short__quote .work-single__quote-cite {
  margin: 0 0 1.2rem 50.8%;
  line-height: 1.3;
}
.work-short__video {
  width: 100%;
  height: 100%;
  margin: 10.4rem 0 11.7rem;
}
@media (min-width: 768px) {
  .work-short__video {
    margin: 10.6rem 0 12.3rem;
  }
}
@media (min-width: 1024px) {
  .work-short__video {
    margin: 30.6rem 0 23.6rem;
  }
}
@media (min-width: 1280px) {
  .work-short__video {
    margin: 26.8rem 0 27.2rem;
  }
}
.work-short__video video {
  /* Make video at least 100% wide and tall */
  width: 100%;
}

.work-single {
  padding-top: 14rem;
}
@media (min-width: 768px) {
  .work-single {
    border-top: 0.1rem solid rgba(191, 191, 191, 0.25);
  }
}
.work-single h1 {
  font-weight: 600;
  color: #000000;
  padding: 0;
  margin: 0;
  font-size: 3rem;
  line-height: 4rem;
  letter-spacing: 0.027rem;
}
@media (min-width: 768px) {
  .work-single h1 {
    font-size: 3.8rem;
    line-height: 5.1rem;
    letter-spacing: 0.03rem;
  }
}
@media (min-width: 1024px) {
  .work-single h1 {
    font-size: 4.5rem;
    line-height: 5.1rem;
    letter-spacing: 0.035rem;
  }
}
@media (min-width: 1440px) {
  .work-single h1 {
    font-size: 6rem;
    line-height: 1.09em;
    letter-spacing: 0.047rem;
  }
}
.work-single h2 {
  font-family: "Ogg", "Times New Roman", serif;
  font-weight: normal;
  color: #000000;
  padding: 0;
  margin: 0;
  font-size: 2.8rem;
  line-height: 3.1rem;
}
@media (min-width: 768px) {
  .work-single h2 {
    font-size: 3.4rem;
    line-height: 5.2rem;
  }
}
@media (min-width: 1024px) {
  .work-single h2 {
    font-size: 4rem;
    line-height: 1.3em;
  }
}
.work-single h2 + h4 {
  padding-top: 0.2rem;
}
@media (min-width: 768px) {
  .work-single h2 + h4 {
    padding-top: 0.6rem;
  }
}
.work-single h3 {
  font-weight: 500;
  color: #000000;
  padding: 0;
  margin: 0;
  font-size: 2.4rem;
  line-height: 3.1rem;
}
@media (min-width: 768px) {
  .work-single h3 {
    font-size: 2.8rem;
    line-height: 3.8rem;
  }
}
@media (min-width: 1024px) {
  .work-single h3 {
    font-size: 3.2rem;
    line-height: 4.2rem;
  }
}
@media (min-width: 1440px) {
  .work-single h3 {
    font-size: 4rem;
    line-height: 5rem;
  }
}
.work-single h4 {
  font-weight: 300;
  color: #a4a4a4;
  padding: 0;
  margin: 0;
  font-size: 2.8rem;
  line-height: 3.2rem;
}
@media (min-width: 768px) {
  .work-single h4 {
    font-size: 3.4rem;
    line-height: 4.1rem;
  }
}
@media (min-width: 1024px) {
  .work-single h4 {
    font-size: 4rem;
    line-height: 1.3em;
  }
}
.work-single h4 img {
  display: inline-block;
  vertical-align: middle;
}
.work-single h5 {
  font-weight: 600;
  color: #000000;
  padding: 0;
  font-size: 1.8rem;
  line-height: 2.7rem;
  margin: 2.7rem 0;
}
@media (min-width: 768px) {
  .work-single h5 {
    font-size: 2.2rem;
    line-height: 1.636364em;
    margin: 1.7em 0;
  }
}
.work-single__header {
  margin-bottom: 8rem;
}
@media (min-width: 1024px) {
  .work-single__header {
    margin-bottom: 12.2rem;
  }
}
@media (min-width: 1024px) {
  .work-single__header .container {
    display: flex;
  }
}
@media (min-width: 1024px) {
  .work-single__header-main {
    flex: 1 1 auto;
    padding-right: 5rem;
  }
}
@media (min-width: 1280px) {
  .work-single__header-main {
    padding-right: 2.5rem;
  }
}
.work-single__header-main .headline {
  margin-bottom: 1.5rem;
}
.work-single__header-aside {
  flex: 0 0 auto;
  padding-left: 1.1rem;
  display: none;
  width: 15.8rem;
}
@media (min-width: 1024px) {
  .work-single__header-aside {
    display: initial;
  }
}
@media (min-width: 1280px) {
  .work-single__header-aside {
    width: 20.2rem;
  }
}
.work-single__header-aside:last-child {
  display: none;
}
@media (min-width: 1280px) {
  .work-single__header-aside:last-child {
    display: initial;
  }
}
.work-single__header-aside-block + .work-single__header-aside-block {
  margin-top: 4.7rem;
}
.work-single__header-aside-block .headline {
  margin-bottom: 1.3em;
}
.work-single__header-aside-block img {
  display: block;
  margin-top: 1.9rem;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.work-single__header-aside-block p {
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: #717171;
  margin-bottom: 2.8rem;
}
.work-single__header-aside-block p a {
  color: currentColor;
}
.work-single__header-aside-block p a:not(:last-child):after {
  display: none;
}
.work-single__header-aside-block p a:not(:last-child):hover ~ a:last-child:after {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translateX(0.2rem);
}
.work-single__header-aside-block p a:after {
  color: #666666;
}
@media (min-width: 1024px) {
  .work-single__text {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .work-single__text {
    padding: 0 7.8%;
  }
}
.work-single__text-column {
  position: relative;
}
@media (min-width: 1024px) {
  .work-single__text-column {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .work-single__text-column {
    box-sizing: border-box;
    padding: 0 0.7rem;
  }
}
.work-single__text-column ul {
  padding-left: 2rem;
}
.work-single__text-column:nth-child(1) {
  margin-bottom: 4.5rem;
}
@media (min-width: 768px) {
  .work-single__text-column:nth-child(1) {
    margin-bottom: 3.7rem;
  }
}
@media (min-width: 1024px) {
  .work-single__text-column:nth-child(1) {
    padding-right: 0.5rem;
    margin-bottom: 0;
  }
}
@media (min-width: 1280px) {
  .work-single__text-column:nth-child(1) {
    padding-right: 0;
  }
}
.work-single__text-column:nth-child(1) .work-single__text-triangle {
  right: 6rem;
  bottom: -0.2rem;
}
@media (min-width: 768px) {
  .work-single__text-column:nth-child(1) .work-single__text-triangle {
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    margin: 0 0 0.8rem 0.7rem;
  }
}
@media (min-width: 1024px) {
  .work-single__text-column:nth-child(1) .work-single__text-triangle {
    bottom: initial;
    margin: 7.9rem 0 0 -1.5rem;
    top: 100%;
  }
}
@media (min-width: 1280px) {
  .work-single__text-column:nth-child(1) .work-single__text-triangle {
    margin: 11.4rem 0 0 0;
  }
}
@media (min-width: 768px) {
  .work-single__text-column:nth-child(2) {
    padding: 1.3rem 0 0 0;
  }
}
@media (min-width: 1024px) {
  .work-single__text-column:nth-child(2) {
    padding: 1.3rem 0 0 0.5rem;
  }
}
@media (min-width: 1280px) {
  .work-single__text-column:nth-child(2) {
    padding: 1.3rem 0 0 0;
  }
}
.work-single__text-column:nth-child(2) .work-single__text-triangle {
  left: 0;
  display: none;
  top: 100%;
  margin: 9.6rem 0 0 0.1rem;
}
@media (min-width: 768px) {
  .work-single__text-column:nth-child(2) .work-single__text-triangle {
    display: initial;
  }
}
@media (min-width: 1024px) {
  .work-single__text-column:nth-child(2) .work-single__text-triangle {
    top: 0;
    margin: 0 0 1.8rem -0.3rem;
    bottom: 100%;
  }
}
@media (min-width: 1280px) {
  .work-single__text-column:nth-child(2) .work-single__text-triangle {
    margin: 0 0 1.8rem 0.6rem;
  }
}
@media (min-width: 1024px) {
  .work-single__text-column h2 {
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
}
.work-single__text-column h4:last-child {
  margin-bottom: -1.5rem;
}
@media (min-width: 768px) {
  .work-single__text-column h4:last-child {
    margin-bottom: 0;
  }
}
.work-single__text-column h5:first-child {
  margin-top: 0 !important;
}
.work-single__text-column p {
  letter-spacing: 0.01rem;
  margin: 2.7rem 0;
}
@media (min-width: 768px) {
  .work-single__text-column p {
    margin: 1.8em 0;
  }
}
.work-single__text-column p b {
  color: #000000;
  font-weight: 600;
}
.work-single__text-column p:first-of-type {
  margin-top: 0 !important;
}
.work-single__text-column p:last-of-type {
  margin-bottom: 0 !important;
}
.work-single__text-decorator {
  position: absolute;
}
.work-single__text-decorator img {
  display: block;
}
.work-single__text-decorator.-top-left {
  bottom: 100%;
  left: 0;
  display: none;
  margin: 0 0 7.8rem 0.5rem;
}
@media (min-width: 768px) {
  .work-single__text-decorator.-top-left {
    display: initial;
  }
}
@media (min-width: 1024px) {
  .work-single__text-decorator.-top-left {
    margin: 0 0 11.2rem 0.2rem;
  }
}
@media (min-width: 1280px) {
  .work-single__text-decorator.-top-left {
    margin: 0 0 11.2rem 1.6rem;
  }
}
.work-single__text-decorator.-top-left + h2 {
  height: 100%;
}
.work-single__text-decorator.-top-left img {
  width: 24.4rem;
}
@media (min-width: 1024px) {
  .work-single__text-decorator.-top-left img {
    width: initial;
  }
}
.work-single__text-decorator.-top-right {
  bottom: 100%;
  right: 60%;
  margin-bottom: 12.1rem;
}
@media (min-width: 768px) {
  .work-single__text-decorator.-top-right {
    right: -0.3rem;
    margin: 0 0 11.1rem 0;
  }
}
@media (min-width: 1024px) {
  .work-single__text-decorator.-top-right {
    right: -0.4rem;
    margin: 0 0 3.7rem 0;
  }
}
@media (min-width: 1280px) {
  .work-single__text-decorator.-top-right {
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    margin: 0 0 3.7rem 1.4rem;
  }
}
.work-single__text-decorator.-top-right img {
  width: 19rem;
}
@media (min-width: 768px) {
  .work-single__text-decorator.-top-right img {
    width: 20.6rem;
  }
}
@media (min-width: 1024px) {
  .work-single__text-decorator.-top-right img {
    width: initial;
  }
}
.work-single__text-decorator.-bottom {
  left: 2.4rem;
  bottom: 4.8rem;
}
@media (min-width: 768px) {
  .work-single__text-decorator.-bottom {
    left: 36.3%;
    bottom: 12.5rem;
  }
}
@media (min-width: 1024px) {
  .work-single__text-decorator.-bottom {
    left: 42.3%;
    bottom: 15rem;
  }
}
@media (min-width: 1440px) {
  .work-single__text-decorator.-bottom {
    left: initial;
    bottom: initial;
    right: 7.8%;
    top: 100%;
    margin: 9.2rem 1.8rem 0 0;
  }
}
.work-single__text-decorator.-bottom img {
  width: 54rem;
}
@media (min-width: 768px) {
  .work-single__text-decorator.-bottom img {
    width: 65.4rem;
  }
}
@media (min-width: 1024px) {
  .work-single__text-decorator.-bottom img {
    width: initial;
  }
}
.work-single__text-triangle {
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-width: 0.7rem 0.7rem 0 0.7rem;
}
@media (min-width: 1024px) {
  .work-single__text-triangle {
    border-width: 0.9rem 0.9rem 0 0.9rem;
  }
}
.work-single__quote-text {
  font-family: "Ogg", "Times New Roman", serif;
  margin: 0 auto;
  width: 97%;
  font-size: 2.8rem;
  line-height: 3.8rem;
}
@media (min-width: 768px) {
  .work-single__quote-text {
    font-size: 4.4rem;
    line-height: 6rem;
  }
}
@media (min-width: 1440px) {
  .work-single__quote-text {
    font-size: 4.9rem;
    line-height: 6.7rem;
  }
}
.work-single__quote-cite {
  font-size: 1.4rem;
  line-height: 1.5rem;
}
@media (min-width: 768px) {
  .work-single__quote-cite {
    font-size: 1.7rem;
    line-height: 2.6rem;
  }
}
.work-single__quote-cite b {
  font-weight: 600;
  position: relative;
  top: 1.6rem;
}
.work-single__quote-cite b:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0.3rem;
  right: 100%;
  height: 0.1rem;
  background-color: currentColor;
  width: 1.8rem;
  margin: 0 0.8rem 0.1rem 0;
}
@media (min-width: 768px) {
  .work-single__quote-cite b:before {
    width: 3.1rem;
    margin: 0 2rem 0.2rem 0;
  }
}
.work-single__outcomes {
  margin: 10.1rem 0 8.3rem;
}
@media (min-width: 768px) {
  .work-single__outcomes {
    margin: 10.4rem 0 9.7rem;
  }
}
@media (min-width: 1024px) {
  .work-single__outcomes {
    margin: 26.6rem 0 22.8rem;
  }
}
@media (min-width: 1280px) {
  .work-single__outcomes {
    margin: 26.6rem 0 22.5rem;
  }
}
@media (min-width: 1024px) {
  .work-single__outcomes .container {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .work-single__outcomes .container {
    padding-left: 7.3%;
  }
}
@media (min-width: 1440px) {
  .work-single__outcomes .container {
    padding-left: 10.5rem;
  }
}
.work-single__outcomes-content {
  padding-top: 4.9rem;
}
@media (min-width: 768px) {
  .work-single__outcomes-content {
    padding-top: 5.6rem;
  }
}
@media (min-width: 1024px) {
  .work-single__outcomes-content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2.2rem;
    width: 49.4%;
  }
}
@media (min-width: 1280px) {
  .work-single__outcomes-content {
    width: 72.4%;
  }
}
.work-single__outcomes-block {
  font-size: 1.7rem;
  line-height: 2.1rem;
  box-sizing: border-box;
  padding: 0 0 3.3rem 0;
}
@media (min-width: 1024px) {
  .work-single__outcomes-block {
    padding: 0 2.5rem 5.2rem 0;
  }
  .work-single__outcomes-block:nth-child(2n-1) {
    width: 61.8%;
  }
  .work-single__outcomes-block:nth-child(2n) {
    width: 38.2%;
    padding-right: 0;
  }
}
@media (min-width: 1280px) {
  .work-single__outcomes-block {
    width: 38%;
  }
  .work-single__outcomes-block:nth-child(3n) {
    width: 24%;
    padding-right: 0;
  }
  .work-single__outcomes-block:nth-child(2n-1) {
    width: 33.3%;
  }
  .work-single__outcomes-block:nth-child(2n) {
    width: 33.3%;
    padding-right: 0;
  }
}
.work-single__outcomes-block-title {
  color: #000000;
  padding-bottom: 0.3rem;
}
.work-single__outcomes-block-title .ico {
  display: inline-block;
  vertical-align: baseline;
}
.work-single__outcomes-block-title .ico.-star {
  width: 1.1rem;
  height: 1.1rem;
  margin-right: -0.1rem;
}
.work-single__outcomes-block-title .ico.-star:first-child {
  margin-left: 0.2rem;
}
.work-single__outcomes-block-value {
  color: #717171;
}
.work-single__outcomes-block-value a {
  color: currentColor;
}
.work-single__outcomes-block-value a:not(:last-child):after {
  display: none;
}
.work-single__outcomes-block-value a:not(:last-child):hover ~ a:last-child:after {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translateX(0.2rem);
}
.work-single__outcomes-block-value a[data-with-arrow]:after {
  color: #666666;
}
.work-single__afterwords {
  border-top: 0.1rem solid rgba(191, 191, 191, 0.25);
}
.work-single__afterwords .container {
  width: 100%;
}
@media (min-width: 1024px) {
  .work-single__afterwords .container {
    width: initial;
    display: flex;
  }
}
.work-single__afterwords-block {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 28rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .work-single__afterwords-block {
    height: 34.9rem;
    padding-bottom: 3.4rem;
  }
}
@media (min-width: 1024px) {
  .work-single__afterwords-block {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .work-single__afterwords-block {
    height: 50rem;
  }
}
@media (min-width: 1280px) {
  .work-single__afterwords-block:first-child .afterword {
    padding-left: 17.6%;
  }
}
@media (min-width: 1440px) {
  .work-single__afterwords-block:first-child .afterword {
    padding-left: 0;
  }
}
.work-single__afterwords-block + .work-single__afterwords-block {
  border-style: solid;
  border-color: rgba(191, 191, 191, 0.25);
  border-width: 0.1rem 0 0;
}
@media (min-width: 1024px) {
  .work-single__afterwords-block + .work-single__afterwords-block {
    border-width: 0 0 0 0.1rem;
  }
}
@media (min-width: 1024px) {
  .work-single__afterwords-block + .work-single__afterwords-block .afterword {
    padding-left: 23.4%;
  }
}
@media (min-width: 1440px) {
  .work-single__afterwords-block + .work-single__afterwords-block .afterword {
    padding-left: 26%;
  }
}
.work-single__afterwords .afterword {
  padding: 0 5.6%;
}
@media (min-width: 768px) {
  .work-single__afterwords .afterword {
    padding: 0 30.2%;
  }
}
@media (min-width: 1024px) {
  .work-single__afterwords .afterword {
    padding: 0 0 0 17.6%;
  }
}
@media (min-width: 1440px) {
  .work-single__afterwords .afterword {
    padding: 0 0 0 26%;
  }
}
.work-single__afterwords .afterword__heading, .work-single__afterwords .afterword__subheading {
  font-size: 3rem;
  line-height: 4rem;
}
@media (min-width: 768px) {
  .work-single__afterwords .afterword__heading, .work-single__afterwords .afterword__subheading {
    font-size: 4rem;
    line-height: 5.2rem;
  }
}
.work-single__afterwords .afterword__heading .complex-link__line, .work-single__afterwords .afterword__subheading .complex-link__line {
  height: 0.2rem;
  top: 95%;
}
.work-single__afterwords .afterword__links a {
  margin-right: 2rem;
}
.work-single__header-byline {
  font-weight: 400 !important;
}
.work-single-contact {
  padding: 5.5rem 0rem;
  width: 100% !important;
  text-align: center;
  padding-bottom: 6.8rem;
  text-align: left;
}
@media (min-width: 768px) {
  .work-single-contact {
    padding-bottom: 0;
    text-align: initial;
  }
}
@media (min-width: 1440px) {
  .work-single-contact {
    margin: 10rem 0rem 0rem 0rem;
  }
}
.work-single-contact__grid {
  padding: 5.5rem 0rem;
  width: 100% !important;
  text-align: center;
}
.work-single-contact__grid-bond {
  padding: 1rem 0rem;
}
@media (min-width: 768px) {
  .work-single-contact__grid-bond {
    padding: 0;
  }
}
.work-single-contact__grid__byline {
  width: 30rem;
  margin: 0 2.1rem;
}
@media (min-width: 768px) {
  .work-single-contact__grid__byline {
    margin: 0 auto;
  }
}
.work-single-contact__block {
  height: 35rem !important;
}
.work-single-contact__heading {
  width: 30rem;
  margin: 0 auto;
}
.work-single-contact__heading__app {
  padding-left: 0 !important;
}
@media (min-width: 1440px) {
  .work-single__apps {
    border-top: 0.1rem solid rgba(191, 191, 191, 0.25);
  }
}
.work-single__dynamic {
  display: block;
  width: 100%;
  height: auto;
}
@media (min-width: 1440px) {
  .work-single__dynamic {
    display: initial;
    width: initial;
    height: initial;
  }
}
.work-single .section-gradient h3 {
  color: #ffffff;
}

.approach {
  width: 100%;
  margin: -0.8rem 0 0.5rem;
}
@media (min-width: 768px) {
  .approach {
    margin: -0.8rem 0 9.7rem;
  }
}
.approach__headline {
  padding-bottom: 3.6rem;
}
@media (min-width: 768px) {
  .approach__headline {
    text-align: center;
    padding-bottom: 10.4rem;
  }
}
.approach__infographic {
  width: 11rem;
  margin: auto;
  position: relative;
}
@media (min-width: 768px) {
  .approach__infographic {
    width: auto;
    margin: auto;
    position: static;
  }
}
.approach__infographic-stages {
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 0;
  left: 100%;
  font-size: 0.7rem;
  line-height: 0.9rem;
  letter-spacing: 0.1rem;
  width: 6.4rem;
}
@media (min-width: 768px) {
  .approach__infographic-stages {
    position: static;
    top: auto;
    left: auto;
    width: auto;
    display: flex;
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-bottom: 2.8rem;
    letter-spacing: 0.2rem;
  }
}
@media (min-width: 1280px) {
  .approach__infographic-stages {
    padding: 0 0.9rem 0 1.1rem;
  }
}
.approach__infographic-stage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (min-width: 768px) {
  .approach__infographic-stage {
    flex: 0 0 auto;
    height: 5.8rem;
  }
}
.approach__infographic-stage:nth-child(1) {
  height: 11.4rem;
}
@media (min-width: 768px) {
  .approach__infographic-stage:nth-child(1) {
    height: auto;
    width: 20%;
  }
}
.approach__infographic-stage:nth-child(2) {
  height: 26.7rem;
}
@media (min-width: 768px) {
  .approach__infographic-stage:nth-child(2) {
    height: auto;
    width: 40%;
  }
}
.approach__infographic-stage:nth-child(3) {
  height: 13rem;
}
@media (min-width: 768px) {
  .approach__infographic-stage:nth-child(3) {
    height: auto;
    width: 20%;
  }
}
.approach__infographic-stage:after {
  content: "";
  display: block;
  position: absolute;
  width: 0.2rem;
  height: 5.8rem;
  background-image: url("https://web-cdn-prod.levinriegner.com/img/mission/approach/dots.svg");
  transform: rotate(90deg) scale(0.6);
  bottom: -3rem;
  left: 3.2rem;
}
@media (min-width: 768px) {
  .approach__infographic-stage:after {
    transform: none;
    bottom: auto;
    left: auto;
    top: 0;
    right: -0.1rem;
  }
}
.approach__infographic-stage p {
  padding: 0 1rem;
}
.approach__infographic-iterations {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .approach__infographic-iterations {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .approach__infographic-iterations {
    padding: 0 4.2rem;
  }
}
.approach__infographic-iteration {
  position: relative;
}
@media (min-width: 768px) {
  .approach__infographic-iteration {
    max-width: 16.5%;
  }
}
.approach__infographic-iteration + .approach__infographic-iteration {
  margin-top: 2.9rem;
}
@media (min-width: 768px) {
  .approach__infographic-iteration + .approach__infographic-iteration {
    margin-top: 0;
  }
}
.approach__infographic-iteration + .approach__infographic-iteration:before {
  content: "";
  display: block;
  position: absolute;
  width: 0.7rem;
  height: 1.2rem;
  background-image: url("https://web-cdn-prod.levinriegner.com/img/mission/approach/arrow.svg");
  top: -2rem;
  left: 50%;
  transform: rotate(90deg) scale(0.55);
  margin: 0 0 0 -0.4rem;
}
@media (min-width: 768px) {
  .approach__infographic-iteration + .approach__infographic-iteration:before {
    top: 50%;
    left: -16%;
    transform: none;
    margin: 0.2rem 0 0 0;
  }
}
.approach__infographic-iteration img {
  display: block;
  position: relative;
  max-width: 100%;
}
.approach__infographic-iteration p {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  line-height: 2.4rem;
  font-weight: 600;
  color: #000000;
  font-size: 1.265rem;
}
@media (min-width: 768px) {
  .approach__infographic-iteration p {
    margin-top: 0.8rem;
    font-size: 1.6rem;
  }
}
@media (min-width: 1024px) {
  .approach__infographic-iteration p {
    font-size: 2.3rem;
  }
}
@media (min-width: 768px) {
  .approach__infographic-iteration:nth-child(2) img {
    top: 0.4rem;
  }
  .approach__infographic-iteration:nth-child(3) img, .approach__infographic-iteration:nth-child(4) img {
    top: 0.7rem;
  }
  .approach__infographic-iteration:nth-child(5) img {
    top: 0.9rem;
  }
}
.approach__infographic-iteration:nth-child(1) {
  width: 10.3rem;
}
.approach__infographic-iteration:nth-child(2) {
  width: 10.7rem;
}
.approach__infographic-iteration:nth-child(3) {
  width: 10.4rem;
}
.approach__infographic-iteration:nth-child(4) {
  width: 10.6rem;
}
.approach__infographic-iteration:nth-child(5) {
  width: 10.4rem;
}
@media (min-width: 768px) {
  .approach__infographic-iteration:nth-child(1) {
    width: auto;
  }
  .approach__infographic-iteration:nth-child(2) {
    width: auto;
  }
  .approach__infographic-iteration:nth-child(3) {
    width: auto;
  }
  .approach__infographic-iteration:nth-child(4) {
    width: auto;
  }
  .approach__infographic-iteration:nth-child(5) {
    width: auto;
  }
}
.approach__infographic-group {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 5.3rem;
  right: 100%;
  height: 39.5rem;
  margin: 0 0.3rem 0 0;
}
@media (min-width: 768px) {
  .approach__infographic-group {
    flex-direction: row;
    position: relative;
    bottom: auto;
    right: auto;
    height: auto;
    margin: 6.4rem 8% 0 28.9%;
  }
}
@media (min-width: 1280px) {
  .approach__infographic-group {
    margin: 6.4rem 10.4% 0 30.25%;
  }
}
.approach__infographic-group-title {
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
  flex: 0 0 auto;
  text-align: center;
  font-size: 0.7rem;
  line-height: 0.9rem;
  letter-spacing: 0.108rem;
  padding: 0.4rem 0;
}
@media (min-width: 768px) {
  .approach__infographic-group-title {
    text-align: initial;
    font-size: 1.1rem;
    line-height: 1.7rem;
    letter-spacing: 0.2rem;
    padding: 0 0.9rem;
  }
}
@media (min-width: 1024px) {
  .approach__infographic-group-title {
    padding: 0 1.2rem;
    font-size: 1.3rem;
  }
}
.approach__infographic-group-corner {
  flex: 1 1 auto;
  background-color: #ffffff;
  position: relative;
  font-size: 0;
  line-height: 0;
  width: 0.1rem;
}
@media (min-width: 768px) {
  .approach__infographic-group-corner {
    width: auto;
    height: 0.2rem;
  }
}
.approach__infographic-group-corner.-left .approach__infographic-group-pointer:first-child {
  top: 0;
}
@media (min-width: 768px) {
  .approach__infographic-group-corner.-left .approach__infographic-group-pointer:first-child {
    top: auto;
    left: 0;
  }
}
.approach__infographic-group-corner.-left .approach__infographic-group-pointer:last-child {
  bottom: 5.3rem;
}
@media (min-width: 768px) {
  .approach__infographic-group-corner.-left .approach__infographic-group-pointer:last-child {
    bottom: 0;
    right: 6%;
  }
}
@media (min-width: 1024px) {
  .approach__infographic-group-corner.-left .approach__infographic-group-pointer:last-child {
    right: 14%;
  }
}
.approach__infographic-group-corner.-right .approach__infographic-group-pointer:first-child {
  top: 5.3rem;
}
@media (min-width: 768px) {
  .approach__infographic-group-corner.-right .approach__infographic-group-pointer:first-child {
    top: auto;
    left: 6%;
  }
}
@media (min-width: 1024px) {
  .approach__infographic-group-corner.-right .approach__infographic-group-pointer:first-child {
    left: 14%;
  }
}
.approach__infographic-group-corner.-right .approach__infographic-group-pointer:last-child {
  bottom: 0;
}
@media (min-width: 768px) {
  .approach__infographic-group-corner.-right .approach__infographic-group-pointer:last-child {
    bottom: 0;
    right: 0;
  }
}
.approach__infographic-group-pointer {
  position: absolute;
  background-color: #ffffff;
  height: 0.1rem;
  width: 1.6rem;
  left: 0;
}
@media (min-width: 768px) {
  .approach__infographic-group-pointer {
    height: 3.7rem;
    width: 0.2rem;
    left: auto;
    bottom: 0;
  }
}
.approach__infographic-group-pointer-arrow {
  display: block;
  position: absolute;
  width: 0.7rem;
  height: 1.2rem;
  background-image: url("https://web-cdn-prod.levinriegner.com/img/mission/approach/arrow.svg");
  transform: scale(0.55);
  top: -0.6rem;
  right: -0.2rem;
}
@media (min-width: 768px) {
  .approach__infographic-group-pointer-arrow {
    transform: rotate(-90deg);
    top: -0.4rem;
    left: -0.3rem;
    right: auto;
  }
}

.author {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #8a8a8a;
}
.author b {
  font-weight: 600;
}
.author p,
.author p + p:before {
  display: inline;
  vertical-align: baseline;
}
.author p + p:before {
  content: "/";
  padding: 0 0.55em 0 0.35em;
}

.brief-section {
  margin: 9.7rem 0 8.1rem;
}
@media (min-width: 768px) {
  .brief-section {
    margin: 15rem 0 19rem;
  }
}
.brief-section__container {
  background-color: #0f1010;
  color: #ffffff;
  border-radius: 0.3rem;
  position: relative;
}
@media (min-width: 768px) {
  .brief-section__container {
    padding: 7.5rem 5rem 0;
  }
}
@media (min-width: 1024px) {
  .brief-section__container {
    display: flex;
    padding: 10.7rem 10.4rem 0 0;
  }
}
.brief-section__container-spine {
  position: absolute;
  bottom: 100%;
  background-color: #0f1010;
  border-radius: 0.3rem 0.3rem 0 0;
  width: 16.9rem;
  height: 0.9rem;
  left: 2.7rem;
}
@media (min-width: 768px) {
  .brief-section__container-spine {
    width: 31.4rem;
    height: 1.3rem;
    left: 9.4rem;
  }
}
.brief-section__container-spine:before, .brief-section__container-spine:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  margin: 0.1rem -0.1rem;
}
.brief-section__container-spine:before {
  border-width: 0 0 1.3rem 1.5rem;
  border-color: transparent transparent #000000 transparent;
  right: 100%;
}
.brief-section__container-spine:after {
  border-width: 1.3rem 0 0 1.5rem;
  border-color: transparent transparent transparent #0f1010;
  left: 100%;
}
.brief-section__title {
  flex: 0 0 auto;
  line-height: 1;
  font-family: "Sharp Sans No1", "Arial", sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 4rem;
  padding: 5rem 2.6rem 0;
}
@media (min-width: 768px) {
  .brief-section__title {
    font-size: 6rem;
    padding: 5rem 2.6rem 5rem 0;
  }
}
@media (min-width: 1024px) {
  .brief-section__title {
    padding: 0.2rem 10.4rem 0;
  }
}
.brief-section__title span {
  font-family: "Ogg", "Times New Roman", serif;
}
@media (min-width: 1024px) {
  .brief-section__title span {
    display: block;
  }
}
@media (min-width: 1280px) {
  .brief-section__title span {
    margin: 0 0.105em 0 -0.105em;
    display: initial;
  }
}
.brief-section__info {
  padding: 3.4rem 2.8rem 0;
}
@media (min-width: 768px) {
  .brief-section__info {
    padding: 0;
  }
}
.brief-section__about {
  padding-bottom: 5rem;
}
@media (min-width: 768px) {
  .brief-section__about {
    padding-bottom: 5.6rem;
  }
}
.brief-section__form {
  width: 100%;
  position: relative;
  margin: auto;
  transition: opacity 600ms 750ms;
}
@media (min-width: 768px) {
  .brief-section__form {
    padding-bottom: 2.2rem;
  }
}
.brief-section__form-control {
  position: relative;
  display: block;
  position: absolute;
  z-index: 3;
  transition-property: visibility, opacity;
  transition-duration: 300ms;
  top: 1.8rem;
  right: 0;
}
.brief-section__form-button {
  border: none;
  background: none;
  font: 500 1.7rem/2.4rem "Sharp Sans No1", "Arial", sans-serif;
  display: block;
  height: 2.4rem;
  transition: color 450ms;
  color: rgba(255, 255, 255, 0.29);
}
.brief-section__form-button[disabled] {
  pointer-events: none;
}
.brief-section__form-error {
  color: #ff001f;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: left;
  visibility: hidden;
  box-sizing: border-box;
  height: 8rem;
}
@media (min-width: 768px) {
  .brief-section__form-error {
    height: 9.9rem;
  }
}
.brief-section__form-error img {
  position: relative;
  top: 0.1rem;
  margin-right: 0.6rem;
  flex: 0 0 auto;
}
.brief-section__form-error.-active {
  display: flex;
  visibility: visible;
  padding-top: 0.9rem;
}
.brief-section__form-input {
  height: 5rem;
  position: relative;
}
.brief-section__form-input input {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  font: 500 1.7rem/2.4rem "Sharp Sans No1", "Arial", sans-serif;
  color: #ffffff;
  box-sizing: border-box;
  padding-right: 3rem;
}
@media (min-width: 768px) {
  .brief-section__form-input input .brief-section__form-input input {
    padding-right: 10rem;
  }
}
@media (min-width: 768px) {
  .brief-section__form-input input {
    padding-right: 0;
  }
}
.brief-section__form-input input::-webkit-input-placeholder {
  color: #ffffff;
}
.brief-section__form-input input::-moz-placeholder {
  color: #ffffff;
}
.brief-section__form-input input:-ms-input-placeholder {
  color: #ffffff;
}
.brief-section__form-input input:-moz-placeholder {
  color: #ffffff;
}
.brief-section__form-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem #0e0f0f inset;
}
.brief-section__form-input input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff !important;
}
.brief-section__form-input-border {
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.15);
  transition: height 450ms;
}
.brief-section__form-input-border .subhead {
  color: #ffffff;
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(100%);
  transition: transform 300ms 300ms;
  font-size: 2rem;
  margin-top: -0.2rem;
}
.brief-section__form-input-border-gradient {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 450ms;
}
.brief-section__form.-active .brief-section__form-input:before,
.brief-section__form.-active .brief-section__form-input:after {
  opacity: 0.5;
}
.brief-section__form.-active .brief-section__form-input:before {
  transform: translateX(-100%);
}
.brief-section__form.-active .brief-section__form-input:after {
  transform: translateX(100%);
}
.brief-section__form.-active .brief-section__form-control {
  transform: none;
}
.brief-section__form.-active .brief-section__form-button {
  color: #717171;
}
.brief-section__form.-focused-input-border, .brief-section__form.-filled .brief-section__form-input-border {
  height: 0.2rem;
}
.brief-section__form.-focused-input-border-gradient, .brief-section__form.-filled .brief-section__form-input-border-gradient {
  opacity: 1;
}
.brief-section__form.-filled .brief-section__form-button {
  color: #ffffff;
}
.brief-section__form.-sent .brief-section__form-control {
  opacity: 0;
  visibility: hidden;
}
.brief-section__form.-sent .brief-section__form-input-border {
  height: 100%;
}
.brief-section__form.-sent .brief-section__form-input-border-gradient {
  opacity: 1;
}
.brief-section__form.-sent .brief-section__form-input-border .subhead {
  transform: translateY(-50%);
}

.brief {
  width: 100%;
  position: relative;
  margin: auto;
  transition: opacity 600ms 750ms;
}
.brief__input {
  height: 5rem;
  position: relative;
}
.brief__input input {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  font: 500 1.7rem/2.4rem "Sharp Sans No1", "Arial", sans-serif;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0 3rem 0.4rem 0;
}
@media (min-width: 768px) {
  .brief__input input {
    padding: 0 10rem 0 0;
  }
}
@media (min-width: 1024px) {
  .brief__input input {
    padding: 0 10rem 0.7rem 0;
  }
}
.brief__input input::-webkit-input-placeholder, .brief__input input::-moz-placeholder, .brief__input input:-ms-input-placeholder, .brief__input input:-moz-placeholder {
  color: #ffffff;
}
.brief__input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem #0e0f0f inset;
  -webkit-text-fill-color: #ffffff !important;
}
.brief__input-placeholder {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 0.4rem;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.4rem;
}
.brief__input-placeholder b {
  font-weight: 600;
}
@media (min-width: 1024px) {
  .brief__input-placeholder {
    left: 0.7rem;
  }
}
.brief__input-border {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 0.2rem;
  transition: height 450ms;
}
.brief__input-border-gradient {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 450ms;
}
.brief__input-border .subhead {
  color: #ffffff;
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(100%);
  transition: transform 300ms 300ms;
  font-size: 2rem;
  margin-top: -0.2rem;
}
.brief__control {
  position: relative;
  display: block;
  position: absolute;
  z-index: 3;
  transition-property: visibility, opacity;
  transition-duration: 300ms;
  top: 1.2rem;
  right: 0.5rem;
}
.brief__button {
  border: none;
  background: none;
  font: 500 1.7rem/2.4rem "Sharp Sans No1", "Arial", sans-serif;
  display: block;
  height: 2.4rem;
  transition: color 450ms;
  color: rgba(255, 255, 255, 0.29);
}
.brief__button[disabled] {
  pointer-events: none;
}
.brief__error {
  position: absolute;
  display: none;
  color: #ff001f;
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: left;
  visibility: hidden;
  box-sizing: border-box;
}
.brief__error img {
  position: relative;
  top: 0.1rem;
  margin-right: 0.6rem;
  flex: 0 0 auto;
}
.brief__error.-active {
  display: flex;
  visibility: visible;
  padding-top: 0.9rem;
}
.brief.-active .brief__input:before, .brief.-active .brief__input:after {
  opacity: 0.5;
}
.brief.-active .brief__input:before {
  transform: translateX(-100%);
}
.brief.-active .brief__input:after {
  transform: translateX(100%);
}
.brief.-active .brief__control {
  transform: none;
}
.brief.-active .brief__button {
  color: #717171;
}
.brief.-focused .brief__input-border, .brief.-filled .brief__input-border {
  height: 0.2rem;
}
.brief.-focused .brief__input-border-gradient, .brief.-filled .brief__input-border-gradient {
  opacity: 1;
}
.brief.-filled .brief__button {
  color: #ffffff;
}
.brief.-filled .brief__input-placeholder {
  display: none;
}
.brief.-sent .brief__control {
  opacity: 0;
  visibility: hidden;
}
.brief.-sent .brief__input-border {
  height: 100%;
}
.brief.-sent .brief__input-border-gradient {
  opacity: 1;
}
.brief.-sent .brief__input-border .subhead {
  transform: translateY(-50%);
}

.clients {
  text-align: center;
  margin: 5.2rem 0 9.1rem;
}
@media (min-width: 768px) {
  .clients {
    margin: 8.2rem 0 8rem;
  }
}
.clients__group + .clients__group {
  margin-top: 5.6rem;
}
@media (min-width: 768px) {
  .clients__group + .clients__group {
    margin-top: 12.9rem;
  }
}
.clients__group-title {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .clients__group-title {
    font-size: 2rem;
    line-height: 3.1rem;
    margin-bottom: 3.4rem;
  }
}
.clients__group-logos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .clients__group-logos {
    flex-wrap: nowrap;
    justify-content: center;
  }
}
.clients__group-logo {
  position: relative;
  width: 42%;
}
.clients__group-logo:last-child:nth-child(2n-1) {
  width: 100%;
}
@media (min-width: 768px) {
  .clients__group-logo {
    width: 20.3rem;
  }
  .clients__group-logo:last-child:nth-child(2n-1) {
    width: 20.3rem;
  }
  .clients__group-logo + .clients__group-logo:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0.1rem;
    height: 4rem;
    margin-top: -2rem;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.clients__group-logo figure {
  display: flex;
  align-items: center;
  position: relative;
  height: 10rem;
  font-size: 0;
}
@media (min-width: 768px) {
  .clients__group-logo figure {
    padding: 0 1rem;
  }
}
.clients__group-logo figure img {
  display: block;
  margin: auto;
  max-width: 100%;
}
.clients__group-logo figure .resize {
  display: block;
  margin: auto;
  max-width: 12.5rem;
  max-height: 100%;
  object-fit: contain;
}
.clients__group-logo figure figcaption {
  display: none;
  position: absolute;
  bottom: -0.4rem;
  left: 0;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: rgba(113, 113, 113, 0.64);
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .clients__group-logo figure figcaption {
    display: block;
  }
}

.complex-link {
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
}
.complex-link__line {
  display: block;
  position: absolute;
  top: 85%;
  left: 0;
  width: 100%;
  height: 0.1rem;
  pointer-events: none;
}
.complex-link__blank {
  display: inline-block;
  vertical-align: middle;
  width: 1.3rem;
  height: 1.3rem;
  font-size: 0;
  line-height: 0;
}
.complex-link__blank img {
  display: block;
}
html.mobile .complex-link__blank {
  background-color: #a4a4a4;
}
html.no-mobile .complex-link__blank {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
}
.complex-link.-skin-white {
  color: #ffffff;
}
.complex-link.-skin-white .complex-link__line {
  background-color: #ffffff;
}
html.mobile .complex-link.-skin-gray {
  color: #a4a4a4;
}
html.no-mobile .complex-link.-skin-gray {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.complex-link.-skin-gray .complex-link__line {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
}
html.mobile .complex-link.-skin-black {
  color: #000000;
}
html.no-mobile .complex-link.-skin-black {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.complex-link.-skin-black .complex-link__line {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
}
.complex-link.-skin-gradient {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.complex-link.-skin-gradient .complex-link__line {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
}
.complex-link.-skin-gradient .complex-link__blank {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
}
.complex-link.-role-more {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 0.292rem;
}
@media (min-width: 768px) {
  .complex-link.-role-more {
    font-size: 1.6rem;
    letter-spacing: 0.333rem;
  }
}

html.no-mobile .complex-link.-skin-gray:not(:hover) {
  -webkit-text-fill-color: #a4a4a4;
  background: none;
}

html.no-mobile .complex-link.-skin-black:not(:hover) {
  -webkit-text-fill-color: #ffffff;
  background: none;
}

@media (min-width: 768px) {
  html.no-mobile .complex-link.-skin-black:not(:hover) {
    -webkit-text-fill-color: #000000;
  }
}
html.no-mobile .complex-link.-skin-gradient .complex-link__blank:not(:hover) {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
}

html.no-mobile .complex-link:not(:hover) .complex-link__blank {
  background: #a4a4a4;
}

.contact-buttons__button {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
  display: block;
  max-width: 18.2rem;
  height: 4.3rem;
  color: #000000 !important;
  box-sizing: border-box;
  padding: 0 2.4rem;
  border-radius: 0.1rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 3.6rem;
  margin-top: 1rem;
  position: relative;
  text-align: center;
}
.contact-buttons__button:first-of-type {
  letter-spacing: 0.05rem;
}
.contact-buttons__button:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  transition: opacity 150ms;
}
.contact-buttons__button-icon {
  font-size: 0;
  line-height: 0;
  margin-right: 0.2rem;
}
.contact-buttons__button-icon .ico {
  width: 1.4rem;
}
.contact-buttons__button-icon .ico.-discuss-phone {
  height: 1.4rem;
}
.contact-buttons__button-icon .ico.-discuss-email {
  height: 1.1rem;
}
.contact-buttons__button-icon .ico.-discuss-text {
  height: 1.2rem;
}
.contact-buttons__button-icon, .contact-buttons__button-text {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 2;
}
@media (hover: hover) {
  .contact-buttons__button:hover {
    color: #ffffff !important;
  }
}
@media (hover: hover) {
  .contact-buttons__button:hover:before {
    opacity: 0;
  }
}

.contact-form {
  width: 100%;
  position: relative;
  margin: auto;
  transition: opacity 600ms 750ms;
}
.contact-form-container {
  margin: 6.8rem 5rem 0 0;
}
.contact-form__input {
  height: 5rem;
  position: relative;
}
.contact-form__input label {
  display: none;
}
.contact-form__input input {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  font: 500 1.7rem/2.4rem "Sharp Sans No1", "Arial", sans-serif;
  color: #ffffff;
  box-sizing: border-box;
  padding-bottom: 0.4rem;
}
.contact-form__input input::-webkit-input-placeholder, .contact-form__input input::-moz-placeholder, .contact-form__input input:-ms-input-placeholder, .contact-form__input input:-moz-placeholder {
  color: #ffffff;
}
.contact-form__input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem #0e0f0f inset;
  -webkit-text-fill-color: #ffffff !important;
}
.contact-form__input input.-focused__input-border, .contact-form__input input.-filled .contact-form__input-border {
  height: 0.2rem;
}
.contact-form__input input.-focused__input-border-gradient, .contact-form__input input.-filled .contact-form__input-border-gradient {
  opacity: 1;
}
.contact-form__input input.-filled + .contact-form__button {
  color: #ffffff;
}
.contact-form__input input.-filled + .contact-form__input-placeholder {
  display: none;
}
.contact-form__input input.-sent .contact-form__control {
  opacity: 0;
  visibility: hidden;
}
.contact-form__input input.-sent .contact-form__input-border {
  height: 100%;
}
.contact-form__input input.-sent .contact-form__input-border-gradient {
  opacity: 1;
}
.contact-form__input input.-sent .contact-form__input-border .subhead {
  transform: translateY(-50%);
}
.contact-form__input .submit-button {
  position: relative;
  border: 0.2rem solid #ffffff;
  border-radius: 0.1rem;
  background: none;
  font: 500 1.7rem/2.4rem "Sharp Sans No1", "Arial", sans-serif;
  display: block;
  width: 18.2rem;
  height: 4.3rem;
  box-sizing: border-box;
  padding: 0 2.4rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 3.6rem;
  margin-top: 2.4rem;
  text-align: center;
  transition: color 450ms;
  color: #ffffff;
}
.contact-form__input .submit-button[disabled] {
  pointer-events: none;
}
.contact-form__input-placeholder {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 0.4rem;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.4rem;
}
@media (min-width: 1024px) {
  .contact-form__input-placeholder {
    left: 0.7rem;
  }
}
.contact-form__input-placeholder b {
  font-weight: 600;
}
.contact-form__input-border {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 0.2rem;
  transition: height 450ms;
}
.contact-form__input-border-gradient {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 450ms;
}
.contact-form__input-border .subhead {
  color: #ffffff;
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(100%);
  transition: transform 300ms 300ms;
  font-size: 2rem;
  margin-top: -0.2rem;
}
.contact-form__input ul.errors.suppressErrorMsg {
  display: none;
}
.contact-form__control {
  position: relative;
  display: block;
  z-index: 3;
  transition-property: visibility, opacity;
  transition-duration: 300ms;
  top: 3.5rem;
}
.contact-form__error {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  color: #ff001f;
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-top: 1.2rem;
  text-align: left;
  visibility: visible;
  box-sizing: border-box;
}
.contact-form__error img {
  position: relative;
  top: 0.1rem;
  margin-right: 0.6rem;
  flex: 0 0 auto;
}
.contact-form__error.-active {
  display: flex;
  visibility: visible;
  padding-top: 0.9rem;
}
.contact-form.-active .contact-form__input:before, .contact-form.-active .contact-form__input:after {
  opacity: 0.5;
}
.contact-form.-active .contact-form__input:before {
  transform: translateX(-100%);
}
.contact-form.-active .contact-form__input:after {
  transform: translateX(-100%);
}
.contact-form.-active .contact-form__control {
  transform: none;
}
.contact-form.-active .contact-form__button {
  color: #717171;
}
.contact-form__confirmation {
  position: relative;
  color: #ffffff;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.6rem;
  margin: 2.4rem 0 0 0;
}

.cover {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.dropdown {
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .dropdown {
    margin-bottom: 9rem;
  }
}
.dropdown__tabs {
  padding-bottom: 4rem;
}
.dropdown__category + .dropdown__category {
  border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
}
.dropdown__category-header {
  display: flex;
  align-items: center;
  position: relative;
  padding: 3.5rem 0 3.6rem;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
}
.dropdown__category-name {
  flex: 1 1 auto;
  font-weight: 500;
  line-height: 1em;
  font-size: 2.7rem;
}
@media (min-width: 768px) {
  .dropdown__category-name {
    font-size: 4rem;
  }
}
.dropdown__category-openings {
  display: none;
  flex: 0 0 auto;
  width: 41.6rem;
  color: #3c3c3c;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2rem;
  padding-top: 0.4rem;
}
@media (min-width: 768px) {
  .dropdown__category-openings {
    display: initial;
  }
}
.dropdown__category-toggler {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.7rem;
  width: 2rem;
  height: 2rem;
  font-size: 0;
  line-height: 0;
  margin-top: 0.4rem;
}
.dropdown__category-toggler i {
  display: block;
  position: absolute;
  background-color: #a4a4a4;
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transform: rotate(-90deg);
}
.dropdown__category-toggler i:first-child {
  width: 0.2rem;
  height: 100%;
  top: 0;
  left: 0.9rem;
}
.dropdown__category-toggler i:last-child {
  width: 100%;
  height: 0.2rem;
  top: 0.9rem;
  left: 0;
}
.dropdown__category-body {
  overflow: hidden;
  max-height: 0;
  transition: max-height 300ms;
}
@media (min-width: 768px) {
  .dropdown__category-list {
    float: right;
    width: 41.6rem;
  }
}
.dropdown__category-list-item {
  border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
  padding: 2.4rem 0 2.7rem;
}
.dropdown__category-list-item-title {
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 2.4rem;
}
.dropdown__category-list-item-subtitle {
  font-size: 2rem;
}
.dropdown__category.-active .dropdown__category-toggler i {
  transform: rotate(90deg);
}
.dropdown__category.-active .dropdown__category-toggler i:last-child {
  opacity: 0;
}
.dropdown__category.-active .dropdown__category-body {
  max-height: none;
}

.footer {
  background-color: #000000;
  color: #a4a4a4;
  font-weight: 500;
  font-size: 1.5rem;
}
.footer hr {
  display: block;
  padding: 0;
  margin: 0 -5rem;
  border: none;
  height: 0.1rem;
  background-color: rgba(151, 151, 151, 0.15);
}
.footer__main {
  padding: 5rem 0 0 0;
}
@media (min-width: 768px) {
  .footer__main {
    display: flex;
    justify-content: space-between;
    padding: 8rem 0;
  }
}
.footer__main-glyphs {
  padding-right: 2rem;
  display: none;
}
@media (min-width: 1280px) {
  .footer__main-glyphs {
    display: revert;
  }
}
.footer__main-glyphs img {
  display: block;
  max-width: 100%;
}
@media (min-width: 768px) {
  .footer__main-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
@media (min-width: 1280px) {
  .footer__main-nav {
    width: auto;
    justify-content: flex-start;
  }
}
.footer__main-nav-block {
  margin: 0 0 4.6rem 0;
}
@media (min-width: 768px) {
  .footer__main-nav-block {
    margin: 0 0 0 1rem;
  }
}
@media (min-width: 1280px) {
  .footer__main-nav-block {
    width: 20.2rem;
  }
}
.footer__main-nav-block-title {
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #ffffff;
  margin-bottom: 3.2rem;
}
@media (min-width: 768px) {
  .footer__main-nav-block-title {
    margin-bottom: 1.5rem;
  }
}
.footer__main-nav-block-link {
  margin-bottom: 1.1rem;
}
@media (min-width: 768px) {
  .footer__main-nav-block-link {
    margin-bottom: 0.2rem;
  }
}
.footer__main-nav-block .contact-buttons {
  margin-top: 2.7rem;
}
@media (min-width: 768px) {
  .footer__secondary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 6.3rem 0 5.6rem;
  }
}
@media (min-width: 768px) {
  .footer__tertiary {
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.footer__clutch {
  display: flex;
  align-items: center;
  padding: 3.8rem 0;
}
@media (min-width: 768px) {
  .footer__clutch {
    max-width: 33rem;
    padding: 0;
  }
}
.footer__clutch-logo {
  flex: 0 0 auto;
  margin-right: 1.5rem;
}
@media (min-width: 768px) {
  .footer__clutch-logo {
    margin-right: 2rem;
  }
}
.footer__clutch-logo img {
  display: block;
  width: 8rem;
}
@media (min-width: 768px) {
  .footer__clutch-logo img {
    width: auto;
  }
}
.footer__clutch-caption {
  line-height: 1.9rem;
}
.footer__clutch-caption img {
  display: block;
  margin: 0.2rem 0 1.2rem;
}
.footer__brief {
  padding: 3.7rem 0 5rem;
}
@media (min-width: 768px) {
  .footer__brief {
    padding: 0;
    flex: 1 1 auto;
    margin: 0 0 1.8rem 3.5rem;
  }
}
@media (min-width: 1024px) {
  .footer__brief {
    margin: 0 0 1.8rem 9.7rem;
  }
}
.footer__service {
  line-height: 2.2rem;
  padding: 3.7rem 0 2.7rem;
  margin-right: -2rem;
}
@media (min-width: 768px) {
  .footer__service {
    padding: 0;
    margin-right: 0;
    flex: 1 1 auto;
    order: 1;
  }
}
@media (min-width: 1024px) {
  .footer__service {
    white-space: nowrap;
  }
}
.footer__service-link {
  display: inline-block;
  vertical-align: top;
  margin: 0 2rem 1rem 0;
}
@media (min-width: 768px) {
  .footer__service-link {
    margin: 0 1.4vw 0 0;
  }
}
.footer__copyright {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.3rem;
  letter-spacing: 0.04rem;
  color: rgba(255, 255, 255, 0.25);
  padding: 1.3rem 0 3.5rem;
}
@media (min-width: 768px) {
  .footer__copyright {
    padding: 0;
    order: 2;
    text-align: right;
  }
}
.footer__social {
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  padding: 4rem 0 0;
}
@media (min-width: 768px) {
  .footer__social {
    padding: 0;
    order: 3;
  }
}
.footer__social-link {
  display: inline-block;
  vertical-align: middle;
  margin: 0 3rem 0 0;
}
@media (min-width: 768px) {
  .footer__social-link {
    margin: 0 0 0 2vw;
  }
}
html.mobile .footer__social-link {
  background-color: #ffffff;
}
html.no-mobile .footer__social-link {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
}
.footer__social-link img {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
}

html.no-mobile .footer__social-link:not(:hover) {
  background: #ffffff;
}

.fos {
  margin: -2.2rem;
  padding: 2.2rem;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow: -moz-scrollbars-none;
}
@media (min-width: 768px) {
  .fos {
    margin: 0;
    padding: 0;
    overflow: visible;
  }
}
.fos::-webkit-scrollbar {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 1024px) {
  .fos::-webkit-scrollbar {
    width: auto;
    height: auto;
    opacity: initial;
    visibility: initial;
  }
}
.fos__links {
  display: grid;
  width: calc(300vw - 11rem);
  border-right: 2.2rem solid transparent;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
@media (min-width: 768px) {
  .fos__links {
    width: auto;
    border-right: initial;
  }
}
@media (min-width: 1024px) {
  .fos__links {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.1rem 1.3rem;
  }
}
.fos__link {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0, 0.03), 0 0.3rem 1.5rem 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.1rem;
  font-weight: 600;
  color: #000000;
  transition-property: box-shadow, transform;
  transition-duration: 150ms;
  font-size: 1.3rem;
  line-height: 1.8rem;
  height: 5rem;
  padding: 0 2rem 0.4rem;
}
@media (min-width: 768px) {
  .fos__link {
    font-size: 1.7rem;
    line-height: 2.8rem;
    height: 6.8rem;
    padding: 0 9.5% 0.4rem;
  }
}
.fos__link > span {
  display: block;
}
.fos__link-anchor {
  background: none;
}
html.no-mobile .fos__link-anchor {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

html.no-mobile .fos__link:not(:hover) .fos__link-anchor {
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: #000000;
}

html.no-mobile .fos__link:hover {
  box-shadow: 0 0.3rem 2rem 0 rgba(0, 0, 0, 0.17);
  transform: translateY(-0.5rem);
}

.graph {
  text-align: center;
  padding: 0.6rem 0;
}
@media (min-width: 768px) {
  .graph {
    padding: 4.3rem 0;
  }
}
.graph h2 {
  font-size: 2.5rem;
  line-height: 3rem;
}
@media (min-width: 768px) {
  .graph h2 {
    font-size: initial;
    line-height: initial;
  }
}
.graph > p {
  font-weight: 500;
  color: #8a8a8a;
}
@media (min-width: 768px) {
  .graph > p {
    font-size: 2.4rem;
    line-height: 3.6rem;
    padding: 1.4rem 0 3.4rem;
  }
}
.graph__body {
  background-color: #f6f6f6;
  margin: auto;
  max-width: 105.7rem;
  box-sizing: border-box;
  padding: 3.6rem 5rem 2.2rem 2rem;
}
@media (min-width: 768px) {
  .graph__body {
    padding: 6.4rem 8.8rem 2.5rem 5.5rem;
  }
}
.graph__body-chart {
  position: relative;
  margin-bottom: 4.8rem;
}
@media (min-width: 768px) {
  .graph__body-chart {
    margin-bottom: 7.2rem;
  }
}
.graph__body-chart p {
  font-weight: 500;
  line-height: 1;
  color: #8a8a8a;
  font-size: 1.3rem;
}
@media (min-width: 768px) {
  .graph__body-chart p {
    font-size: 2rem;
  }
}
.graph__body-chart-abscisses-line {
  position: relative;
  height: 0.1rem;
  background-color: #d2d2d2;
}
.graph__body-chart-abscisses-line + .graph__body-chart-abscisses-line {
  margin-top: 5.9rem;
}
@media (min-width: 768px) {
  .graph__body-chart-abscisses-line + .graph__body-chart-abscisses-line {
    margin-top: 8.5rem;
  }
}
.graph__body-chart-abscisses-line p {
  position: absolute;
  top: 0;
  left: 100%;
  margin: -0.8rem 0 0 1.2rem;
}
@media (min-width: 768px) {
  .graph__body-chart-abscisses-line p {
    margin: -1.4rem 0 0 1.8rem;
  }
}
.graph__body-chart-ordinates {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: space-between;
  left: 0.1rem;
  right: -3rem;
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .graph__body-chart-ordinates {
    left: 0.2rem;
    right: -0.8rem;
    margin-top: 2.3rem;
  }
}
.graph__body-chart-drawing {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.graph__body-chart-drawing path {
  stroke-width: 3;
}
.graph__body-chart-drawing-population {
  stroke: #000000;
}
.graph__body-chart-drawing-devices {
  stroke: url("#fluxGradient");
}
.graph__body-legend {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin: 0 -5rem 0 -2rem;
}
@media (min-width: 768px) {
  .graph__body-legend {
    font-size: 2rem;
    line-height: 3rem;
    margin: 0;
  }
}
.graph__body-legend-item + .graph__body-legend-item:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 0.1rem;
  background-color: #d2d2d2;
  position: relative;
  top: -0.2rem;
  height: 2rem;
  margin: 0 1.1rem;
}
@media (min-width: 768px) {
  .graph__body-legend-item + .graph__body-legend-item:before {
    height: 3rem;
    margin: 0 2.1rem;
  }
}
.graph__body-legend-item + .graph__body-legend-item.-gradient {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.graph__body-legend-item + .graph__body-legend-item.-black {
  color: #000000;
}
.graph__body-source {
  font-weight: 500;
  line-height: 1.8rem;
  color: #8a8a8a;
  text-align: center;
  margin: 1.7rem -5rem 0 -2rem;
  font-size: 1.1rem;
}
@media (min-width: 768px) {
  .graph__body-source {
    margin: 3.9rem -5rem 0 -2rem;
    font-size: 1.3rem;
  }
}

.grid-tile {
  background-color: #ffffff;
  box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0, 0.03), 0 0.3rem 1.5rem 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.1rem;
  height: 100%;
  box-sizing: border-box;
  color: #000000;
  transition-property: box-shadow, transform;
  transition-duration: 150ms;
  display: block;
  padding: 2rem 2.1rem 3.6rem;
}
@media (min-width: 768px) {
  .grid-tile {
    padding: 2rem 3rem 4.5rem;
  }
}
.grid-tile,
.grid-tile > span {
  display: block;
}
.grid-tile__icon {
  width: 9.4rem;
  height: 9.4rem;
  font-size: 0;
  line-height: 9.4rem;
  margin-bottom: 1.6rem;
  text-align: center;
}
html.cssmask .grid-tile__icon {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}
html.no-cssmask .grid-tile__icon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
html.cssmask .grid-tile__icon.-branding {
  mask-image: url("https://web-cdn-prod.levinriegner.com/img/services/branding.svg");
}
html.no-cssmask .grid-tile__icon.-branding {
  background-image: url("https://web-cdn-prod.levinriegner.com/img/services/branding.svg");
}
html.cssmask .grid-tile__icon.-consulting {
  mask-image: url("https://web-cdn-prod.levinriegner.com/img/services/consulting.svg");
}
html.no-cssmask .grid-tile__icon.-consulting {
  background-image: url("https://web-cdn-prod.levinriegner.com/img/services/consulting.svg");
}
html.cssmask .grid-tile__icon.-design {
  mask-image: url("https://web-cdn-prod.levinriegner.com/img/services/design.svg");
}
html.no-cssmask .grid-tile__icon.-design {
  background-image: url("https://web-cdn-prod.levinriegner.com/img/services/design.svg");
}
html.cssmask .grid-tile__icon.-innovation {
  mask-image: url("https://web-cdn-prod.levinriegner.com/img/services/innovation.svg");
}
html.no-cssmask .grid-tile__icon.-innovation {
  background-image: url("https://web-cdn-prod.levinriegner.com/img/services/innovation.svg");
}
html.cssmask .grid-tile__icon.-mobile {
  mask-image: url("https://web-cdn-prod.levinriegner.com/img/services/mobile.svg");
}
html.no-cssmask .grid-tile__icon.-mobile {
  background-image: url("https://web-cdn-prod.levinriegner.com/img/services/mobile.svg");
}
html.cssmask .grid-tile__icon.-research {
  mask-image: url("https://web-cdn-prod.levinriegner.com/img/services/research.svg");
}
html.no-cssmask .grid-tile__icon.-research {
  background-image: url("https://web-cdn-prod.levinriegner.com/img/services/research.svg");
}
.grid-tile__title {
  font-weight: 600;
  line-height: 3.2rem;
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
}
@media (min-width: 768px) {
  .grid-tile__title {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
}
.grid-tile__desc {
  position: relative;
  overflow: hidden;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.6rem;
}
@media (min-width: 768px) {
  .grid-tile__desc {
    font-size: 2rem;
    line-height: 3.1rem;
  }
}
html.no-mobile .grid-tile__title, html.no-mobile .grid-tile__desc {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

html.no-mobile .grid-tile:not(:hover) .grid-tile__title,
html.no-mobile .grid-tile:not(:hover) .grid-tile__desc {
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: #000000;
}

html.no-mobile .grid-tile:hover {
  box-shadow: 0 0.3rem 2rem 0 rgba(0, 0, 0, 0.17);
  transform: translateY(-0.5rem);
}

html.no-mobile.cssmask .grid-tile:not(:hover) .grid-tile__icon {
  background: #000000;
}

.grid__row {
  margin-bottom: 1rem;
}
.grid.-spacious .grid__row:first-child {
  margin-bottom: 3.4rem;
}
@media (min-width: 768px) {
  .grid {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem;
  }
  .grid__row {
    box-sizing: border-box;
    padding: 0.5rem;
  }
  .grid.-spacious .grid__row:first-child {
    margin-bottom: 0;
  }
  .grid.-centered {
    align-items: center;
  }
  .grid.-reverse {
    flex-direction: row-reverse;
  }
  .grid.-reverse .grid.-delimited:first-child {
    padding-left: 8.75%;
  }
  .grid.-reverse .grid.-delimited:last-child {
    padding-right: 8.75%;
  }
  .grid:not(.-reverse) .grid.-delimited:first-child {
    padding-right: 8.75%;
  }
  .grid:not(.-reverse) .grid.-delimited:last-child {
    padding-left: 8.75%;
  }
  .grid.-three .grid__row {
    width: 33.33333%;
  }
  .grid.-two .grid__row {
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid.-two-tablet .grid__row {
    width: 50%;
  }
}
.grid__row > img,
.grid__row > svg {
  display: block;
  max-width: 100%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .grid.-scrollable {
    margin-right: -5vw;
    margin-left: -5vw;
  }
  .grid.-scrollable:before, .grid.-scrollable:after {
    width: 5vw;
  }
  .grid.-scrollable .grid__row {
    width: 75vw;
  }
}
@media (max-width: 1023px) {
  .grid.-scrollable {
    overflow: auto;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-bottom: -1rem;
  }
  .grid.-scrollable:before, .grid.-scrollable:after {
    content: "";
    display: block;
    flex: 0 0 auto;
  }
  .grid.-scrollable .grid__row {
    flex: 0 0 auto;
    width: calc(100vw - 4.4rem);
    padding-left: 0;
    padding-right: 0;
  }
  .grid.-scrollable .grid__row + .grid__row {
    margin-left: 1rem;
  }
}
@media (max-width: 767px) {
  .grid.-scrollable {
    margin-right: -2.2rem;
    margin-left: -2.2rem;
  }
  .grid.-scrollable:before, .grid.-scrollable:after {
    width: 2.2rem;
  }
  .grid.-scrollable .grid__row {
    width: calc(100vw - 4.4rem);
  }
}

.header-landing {
  position: fixed;
  z-index: 100;
  top: 3rem;
  left: 0;
  width: 100%;
  text-align: right;
  font-weight: 600;
  font-size: 1.7rem;
}
@media (min-width: 768px) {
  .header-landing {
    font-size: 1.6rem;
  }
}
.header-landing__container {
  margin: auto;
  width: 88%;
  max-width: 126.8rem;
}
.header-landing__learn-more {
  display: inline;
}
.header-landing__learn-more a {
  color: #000000;
  padding: 0.5rem 2rem;
}
.header-landing__contact {
  display: inline;
}
.header-landing__contact a {
  display: inline-block;
  color: #000000;
  width: 8.6rem;
  height: 3.1rem;
  transition-property: background-color;
  transition-duration: 150ms;
}
.header-landing__contact a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: background-color 0.5s ease;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5.7rem;
}
@media (min-width: 768px) {
  .header__container {
    height: 9.8rem;
    transition: height 300ms;
    will-change: height;
  }
}
.header__logo {
  font-size: 0;
  line-height: 0;
  position: relative;
  z-index: 3;
}
@media (min-width: 768px) {
  .header__logo {
    position: static;
    z-index: auto;
    flex: 0 0 auto;
  }
}
.header__logo a {
  display: block;
  border: none;
  color: currentColor;
  transition: opacity 300ms;
}
.header__logo a:hover {
  opacity: 0.8;
}
.header__logo .ico {
  width: 5rem;
  height: 2.1rem;
}
@media (min-width: 768px) {
  .header__logo .ico {
    width: 6rem;
    height: 2.5rem;
  }
}
.header__logo .-white {
  color: #ffffff;
}
.header__logo .-black {
  color: #000000;
}
.header__fader {
  content: "";
  display: block;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 12rem;
  background: linear-gradient(to bottom, black 0%, black 30%, rgba(0, 0, 0, 0) 100%);
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 300ms;
}
@media (min-width: 768px) {
  .header__fader {
    content: "";
    display: block;
    position: static;
    z-index: auto;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background: initial;
    opacity: 1;
    visibility: hidden;
    transition-property: all;
    transition-duration: 0s;
  }
}
.header__overlay {
  position: fixed;
  z-index: 1;
  background-color: #000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 300ms;
}
.header__burger {
  display: block;
  position: relative;
  z-index: 3;
  border-style: solid;
  border-width: 0.1rem;
  background: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-size: 0;
  line-height: 0;
  color: rgba(255, 255, 255, 0.5);
  transition: all 300ms;
}
@media (min-width: 768px) {
  .header__burger {
    display: none;
  }
}
.header__burger .ico {
  width: 2.2rem;
  height: 2.2rem;
  position: relative;
  top: -0.2rem;
  left: -0.2rem;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 300ms;
}
.header__burger.-white {
  border-color: rgba(255, 255, 255, 0.5);
}
.header__burger.-black {
  border-color: rgba(0, 0, 0, 0.5);
}
.header__menu {
  font-weight: 600;
  font-size: 1.7rem;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility, transform;
  transition-duration: 300ms;
  background-color: #000000;
}
@media (min-width: 768px) {
  .header__menu {
    font-size: 1.6rem;
    position: static;
    z-index: auto;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    overflow: visible;
    -webkit-overflow-scrolling: auto;
    opacity: 1;
    visibility: visible;
    transition-property: all;
    transition-duration: 0s;
    background-color: initial;
  }
}
.header__menu-item {
  margin: 0 0 3.4rem 0;
}
@media (min-width: 768px) {
  .header__menu-item {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 2.8rem;
  }
}
.header__menu-item a {
  color: #ffffff !important;
}
.header__menu-item.-active {
  opacity: 0.5;
}
.header__menu-links {
  padding: 10.4rem 2.2rem 5rem;
}
@media (min-width: 768px) {
  .header__menu-links {
    padding: 0;
  }
}
.header__menu-link {
  background: none;
  -webkit-text-fill-color: currentColor;
  transition-property: background-color;
  transition-duration: 300ms;
  border: none;
  background: none;
  color: #ffffff;
  -webkit-text-fill-color: #ffffff;
}
.header__menu-link.-highlighted {
  padding: 0.5rem 1.4rem;
  background-color: rgba(255, 255, 255, 0.1);
}
@media (min-width: 768px) {
  .header__menu-link.-skin-white {
    color: #ffffff;
  }
  .header__menu-link.-skin-white.-highlighted {
    background-color: rgba(255, 255, 255, 0.1);
  }
  html.no-mobile .header__menu-link.-skin-white.-highlighted:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .header__menu-link.-skin-black {
    color: #000000;
    -webkit-text-fill-color: #000000;
  }
  .header__menu-link.-skin-black.-highlighted {
    background-color: rgba(0, 0, 0, 0.03);
  }
  html.no-mobile .header__menu-link.-skin-black.-highlighted:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
}
.header.-active {
  transform: none !important;
}
.header.-active .header__logo {
  color: #ffffff !important;
}
.header.-active .header__burger {
  border-color: transparent;
}
.header.-active .header__burger .ico {
  opacity: 1;
  visibility: visible;
}
.header.-active .header__overlay {
  transform: scale(1);
}
.header.-active .header__fader {
  opacity: 1;
  visibility: visible;
}
.header.-active .header__menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.header.-upscrolled:not(.-active) {
  transition: transform 300ms ease-in-out;
  background-color: #fdfdfd;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .header.-upscrolled:not(.-active) .header__container {
    height: 6.4rem;
  }
}
.header.-upscrolled:not(.-active) .header__logo,
.header.-upscrolled:not(.-active) .header__menu-link,
.header.-upscrolled:not(.-active) .complex-link {
  color: #000000 !important;
  -webkit-text-fill-color: #000000 !important;
}
.header.-upscrolled:not(.-active) .complex-link__line {
  background-color: #000000 !important;
}
.header.-upscrolled:not(.-active) .header__menu-link.-highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}
.header.-upscrolled:not(.-active) .header__menu-link.-highlighted:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.header.-upscrolled:not(.-active) .header__burger {
  border-color: rgba(0, 0, 0, 0.5);
}

.headline {
  display: block;
}
.headline__title, .headline__subtitle {
  font-size: 1.3rem;
  line-height: 1.6rem;
}
@media (min-width: 768px) {
  .headline__title, .headline__subtitle {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
}
.headline__title {
  color: currentColor;
  display: inline-block;
  vertical-align: baseline;
  font-weight: 500;
}
.headline__title:after {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: 0.1rem;
  background-color: currentColor;
  width: 2.4rem;
  margin: 0 0.2rem 0 0.7rem;
}
@media (min-width: 768px) {
  .headline__title:after {
    width: 3.1rem;
    margin: 0 0.5rem 0 1rem;
  }
}
.headline__subtitle {
  font-family: "GT America Mono", "Arial", sans-serif;
  color: #a4a4a4;
  text-transform: uppercase;
  font-weight: 300;
  font-style: normal;
}

.infopage {
  padding: 7.8rem 0 10rem 0;
}
@media (min-width: 768px) {
  .infopage {
    padding: 7.8rem 0 31.2rem 0;
  }
}
.infopage__date {
  color: #a4a4a4;
  font-family: "GT America Mono", "Arial", sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: -0.05rem;
  margin: -2.3rem 0 0.5rem;
}
.infopage__cookies {
  font-size: 0;
  line-height: 0;
  text-align: center;
}
.infopage__cookies img {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}
.infopage .text {
  padding-top: 1.4rem;
}
.infopage .grid + .grid {
  margin-top: 7.2rem;
}

.main__flow {
  padding-top: 5rem;
}
@media (min-width: 768px) {
  .main__flow {
    padding-top: 8.7rem;
  }
}
.main__flow-subpages {
  font-size: 1.6rem;
  font-weight: 500;
  padding: 2.9rem 0 3.4rem;
  line-height: 3.6rem;
}
@media (min-width: 768px) {
  .main__flow-subpages {
    padding: 3.9rem 0 9.6rem;
  }
}
.main__flow-subpages-link {
  margin: 0 0 -0.6rem 0;
}
@media (min-width: 768px) {
  .main__flow-subpages-link {
    display: inline-block;
    vertical-align: top;
    margin: 0 1.5rem 0 0;
  }
}
@media (min-width: 768px) {
  .main__flow-subpages.-small-spacing {
    padding-bottom: 3.3rem;
  }
}
@media (min-width: 768px) {
  .main__flow-subpages + .main__flow-banner {
    margin-top: -5.6rem;
  }
}
.main__flow-banner {
  margin: 0 0 4rem;
}
@media (min-width: 768px) {
  .main__flow-banner {
    margin: 4rem 0 8.4rem;
  }
}
.main__flow-banner img {
  display: block;
  max-width: 100%;
}
.main__flow-banner-foe {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
  position: relative;
  font-size: 0;
  line-height: 0;
}
.main__flow-banner-foe:before {
  content: "";
  display: block;
  padding-top: 59.782609%;
}
@media (min-width: 768px) {
  .main__flow-banner-foe:before {
    padding-top: 37.674%;
  }
}
.main__flow-banner-foe-frame {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 1.2rem;
  left: 1.2rem;
  right: 1.2rem;
  bottom: 1.2rem;
}
@media (min-width: 768px) {
  .main__flow-banner-foe-frame {
    top: 2.2rem;
    left: 2.2rem;
    right: 2.2rem;
    bottom: 2.2rem;
  }
}
.main__flow-banner-foe svg {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.news-info {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #8a8a8a;
}
.news-info b {
  font-weight: 600;
}
.news-info p,
.news-info p + p:before {
  display: inline;
  vertical-align: baseline;
}
.news-info p + p:before {
  content: "/";
  padding: 0 0.55em 0 0.35em;
}

.news-tile {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0, 0.03), 0 0.3rem 1.5rem 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.1rem;
  height: 100%;
  box-sizing: border-box;
  color: #000000;
  transition-property: box-shadow, transform;
  transition-duration: 150ms;
}
.news-tile__preview, .news-tile__body, .news-tile__headline, .news-tile__title {
  display: block;
}
.news-tile__preview {
  flex: 0 0 auto;
  height: 50%;
}
@media (min-width: 1024px) {
  .news-tile__preview {
    height: 45%;
  }
}
@media (min-width: 1280px) {
  .news-tile__preview {
    height: 50%;
  }
}
@media (min-width: 1440px) {
  .news-tile__preview {
    height: 52.5%;
  }
}
.news-tile__preview img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-tile__body {
  flex: 1 1 auto;
  position: relative;
  margin: 1.9rem 2rem 2.5rem;
  padding-bottom: 3.9rem;
}
@media (min-width: 768px) {
  .news-tile__body {
    margin: 1.4rem 2.9rem 2.1rem;
    padding-bottom: 5.8rem;
  }
}
.news-tile__headline {
  margin-bottom: 0.6rem;
}
@media (min-width: 768px) {
  .news-tile__headline {
    margin-bottom: 0.3rem;
  }
}
.news-tile__title {
  line-height: 2.6rem;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  .news-tile__title {
    font-size: 1.7rem;
  }
}
.news-tile .news-info {
  position: absolute;
  bottom: 0;
  left: 0;
}
html.no-mobile .news-tile .headline__title:after {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
}
html.no-mobile .news-tile__title,
html.no-mobile .news-tile .headline__title,
html.no-mobile .news-tile .news-info {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

html.no-mobile .news-tile:hover {
  box-shadow: 0 0.3rem 2rem 0 rgba(0, 0, 0, 0.17);
  transform: translateY(-0.5rem);
}

html.no-mobile .news-tile:not(:hover) .headline__title:after {
  background: #000000;
}

html.no-mobile .news-tile:not(:hover) .news-tile__title, html.no-mobile .news-tile:not(:hover) .headline__title {
  -webkit-text-fill-color: #000000;
}

html.no-mobile .news-tile:not(:hover) .news-info {
  -webkit-text-fill-color: #8a8a8a;
}

html.no-mobile .news-tile:not(:hover) .news-tile__title, html.no-mobile .news-tile:not(:hover) .headline__title, html.no-mobile .news-tile:not(:hover) .news-info {
  background: none;
  -webkit-background-clip: initial;
}

.page-title {
  color: #000000;
}
.page-title__container {
  display: flex;
  flex-direction: column;
  margin-top: 6.8rem;
  margin-bottom: 9.3rem;
  padding: 2rem 0rem;
}
@media (min-width: 768px) {
  .page-title__container {
    flex-direction: row;
    margin-top: 16.3rem;
    margin-bottom: 8.8rem;
    padding: 1rem 0rem;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .page-title__container.-centered {
    align-items: center;
  }
}
.page-title__container.-inverted-mobile {
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .page-title__container.-inverted-mobile {
    flex-direction: row;
  }
}
.page-title__container.-inverted-mobile .page-title__text {
  padding-top: 0;
}
.page-title__container.-inverted-mobile .page-title__headlines {
  padding-top: 2.5rem;
}
.page-title h1, .page-title__title {
  font-size: 4rem;
  line-height: 6rem;
}
@media (min-width: 768px) {
  .page-title h1, .page-title__title {
    font-size: 7rem;
    line-height: 4rem;
  }
}

@media (min-width: 768px) {
  .pillars {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -2.9rem -1rem;
  }
}
.pillars__item {
  display: flex;
  align-items: center;
  padding: 0 0 2.3rem 0;
}
@media (min-width: 768px) {
  .pillars__item {
    width: 33.33333%;
    padding: 2.1rem 0 3.4rem 1rem;
    box-sizing: border-box;
  }
}
.pillars__item-icon {
  flex: 0 0 auto;
  font-size: 0;
  line-height: 0;
  padding-right: 1.2rem;
}
@media (min-width: 768px) {
  .pillars__item-icon {
    padding-right: 1.5rem;
  }
}
.pillars__item-icon picture,
.pillars__item-icon img {
  width: 2.7rem;
  height: 2.7rem;
}
@media (min-width: 768px) {
  .pillars__item-icon picture,
.pillars__item-icon img {
    width: 4.2rem;
    height: 4.2rem;
  }
}
.pillars__item-value {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.6rem;
}
@media (min-width: 768px) {
  .pillars__item-value {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.progressbar {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  height: 0.3rem;
  overflow: hidden;
  max-width: 100vw;
}
.progressbar__line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-image: linear-gradient(to right, #00b2ff 0%, #6600cf 20%, #ff0000 40%, #ff9900 60%, #ffff00 80%, #00c800 100%);
}

.quote {
  text-align: center;
  margin: 8.6rem 0 8.6rem;
}
@media (min-width: 768px) {
  .quote {
    margin: 14.6rem 0 18.9rem;
  }
}
@media (min-width: 768px) {
  .quote:first-child {
    margin-top: 10.6rem;
  }
}
.quote blockquote {
  margin: auto;
  max-width: 103.8rem;
}
.quote blockquote .quote__content:before {
  content: open-quote;
}
.quote blockquote .quote__content:after {
  content: close-quote;
}

.rainbow-reveal {
  position: relative;
  width: 28rem;
}
.rainbow-reveal.canvas-container {
  padding-bottom: 28rem;
}
.rainbow-reveal.button-container {
  display: flex;
  justify-content: space-between;
}
.rainbow-reveal .rainbow-canvas {
  display: block;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.rainbow-reveal .black-canvas {
  display: block;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border: 0.1rem solid #ffffff;
}
.rainbow-reveal button:disabled {
  cursor: not-allowed;
}
.rainbow-reveal button {
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #ffffff;
  background-color: transparent;
  border: none;
  font-weight: 600;
}
.rainbow-reveal button.save-drawing {
  padding-left: 1rem;
}
.rainbow-reveal button.submit-drawing.contact-buttons__button {
  height: 2.6rem;
  font-size: 1.3rem;
  text-transform: none;
  letter-spacing: normal;
  padding: 0 1.3rem;
}
.rainbow-reveal button.submit-drawing.contact-buttons__button .contact-buttons__button-text {
  vertical-align: unset;
  font-weight: 500;
}
.rainbow-reveal button.submit-drawing.contact-buttons__button .contact-buttons__button-icon {
  padding-left: 0.6rem;
  margin-bottom: 0.2rem;
  margin-right: 0;
}
.rainbow-reveal button.submit-drawing.contact-buttons__button:hover path {
  stroke: #ffffff;
}
.rainbow-reveal .canvas-message {
  cursor: default;
  position: absolute;
  color: #ffffff;
  z-index: 2;
  left: 7.7rem;
  top: 11.6rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  white-space: nowrap;
  line-height: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
}
.rainbow-reveal .canvas-message.success {
  left: 8.9rem;
  text-transform: none;
  letter-spacing: normal;
  font-size: 2rem;
}
.rainbow-reveal .canvas-message.saved {
  left: 10.8rem;
}
@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rainbow-reveal .loading-ring {
  display: none;
  position: absolute;
  width: 6.6rem;
  height: 6.6rem;
  z-index: 3;
  left: 10.7rem;
  top: 10.7rem;
}
.rainbow-reveal .loading-ring.htmx-request {
  display: inline-block;
}
.rainbow-reveal .loading-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 5rem;
  height: 5rem;
  margin: 0.8rem;
  border: 0.6rem solid #ffffff;
  border-radius: 50%;
  animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffffff transparent transparent transparent;
}
.rainbow-reveal .loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.rainbow-reveal .loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.rainbow-reveal .loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.rainbow-wrapper hr.show__mobile {
  display: block;
}
.rainbow-wrapper .footer__main-drawings {
  display: none;
  width: 100%;
}
.rainbow-wrapper .footer__main-drawings.show__mobile {
  display: block;
  padding-bottom: 6rem;
}
.rainbow-wrapper .footer__main-drawings img {
  width: 8rem;
  height: 8rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.rainbow-wrapper .footer__main-drawings img:first-child {
  padding-left: 0;
}
.rainbow-wrapper .footer__main-drawings img:last-child {
  padding-right: 0;
}
@media (min-width: 768px) {
  .rainbow-wrapper .footer__main-drawings {
    padding-top: 0.2rem;
  }
}
.rainbow-wrapper .footer__main-glyphs {
  display: block;
  padding-bottom: 4rem;
  width: 100%;
}
.rainbow-wrapper .footer__main-glyphs .rainbow-reveal {
  margin-left: auto;
  margin-right: auto;
}
.rainbow-wrapper .footer__main {
  flex-wrap: wrap;
  display: block;
}
@media (min-width: 1280px) {
  .rainbow-wrapper .footer__main-glyphs {
    width: unset;
    padding-bottom: 0;
  }
  .rainbow-wrapper .footer__main-drawings {
    display: block;
  }
  .rainbow-wrapper .footer__main-drawings.show__mobile {
    display: none;
  }
  .rainbow-wrapper .footer__main {
    flex-wrap: unset;
    display: flex;
    justify-content: space-between;
  }
  .rainbow-wrapper hr.show__mobile {
    display: none;
  }
}

.section-fade {
  background-image: linear-gradient(-180deg, #ffffff 0rem, rgba(0, 0, 0, 0) 29.5rem);
  padding-top: 10rem;
}
@media (min-width: 768px) {
  .section-fade {
    padding-top: 13.7rem;
  }
}

.section-gradient {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  background-color: #0369FB;
  color: #ffffff;
}
.section-gradient a {
  color: #ffffff;
}
.section-gradient__container {
  display: flex;
  flex-direction: column;
  padding-bottom: 9.2rem;
}
@media (min-width: 768px) {
  .section-gradient__container {
    flex-direction: row;
    padding-bottom: 18.8rem;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .section-gradient__container.-centered {
    align-items: center;
  }
}
.section-gradient__container.-inverted-mobile {
  flex-direction: column-reverse;
}
.section-gradient__container.-inverted-mobile .section-gradient__text {
  padding-top: 0;
}
.section-gradient__container.-inverted-mobile .section-gradient__headlines {
  padding-top: 2.5rem;
}
@media (min-width: 768px) {
  .section-gradient__container.-inverted-mobile {
    flex-direction: row;
  }
  .section-gradient__container.-inverted-mobile .section-gradient__text {
    padding-top: 0;
  }
  .section-gradient__container.-inverted-mobile .section-gradient__headlines {
    padding-top: 0;
  }
}
.section-gradient__headlines img {
  display: block;
  max-width: 100%;
  margin-bottom: 1.5rem;
}
.section-gradient__headlines.-spacious {
  margin-bottom: 5.3rem;
}
@media (min-width: 768px) {
  .section-gradient__headlines.-spacious {
    margin-bottom: 0;
  }
}
.section-gradient__text {
  padding: 2.5rem 0 0 0;
}
@media (min-width: 768px) {
  .section-gradient__text {
    flex: 0 0 auto;
    padding: 2rem 0 0 2rem;
    width: 43%;
  }
}
@media (min-width: 1024px) {
  .section-gradient__text {
    width: 41.5rem;
  }
}
.section-gradient__text-more {
  margin-top: 4.4rem;
}
@media (min-width: 768px) {
  .section-gradient__text-more {
    margin-top: 3.9rem;
  }
}
.section-gradient__offer {
  text-align: center;
  padding: 9.3rem 0 10rem;
}
@media (min-width: 768px) {
  .section-gradient__offer {
    padding: 10.4rem 0 11.9rem;
  }
}
.section-gradient__offer-buttons {
  margin-top: 1.2rem;
}
.section-gradient__offer-button {
  display: inline-block;
  vertical-align: top;
  width: 20.2rem;
  height: 4.3rem;
  box-sizing: border-box;
  border: 0.1rem solid rgba(255, 255, 255, 0.5);
  border-radius: 0.1rem;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 4.1rem;
  letter-spacing: 0.25rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  transition: background-color 150ms;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .section-gradient__offer-button {
    margin-top: 2.9rem;
  }
}
html.mobile .section-gradient__offer-button {
  color: #ffffff;
}
html.no-mobile .section-gradient__offer-button-value {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section-gradient:first-child .section-gradient__container {
  padding-top: 9rem;
}
@media (min-width: 768px) {
  .section-gradient:first-child .section-gradient__container {
    padding-top: 16.3rem;
  }
}
.section-gradient:last-child:not(.-black) {
  box-shadow: 0 0 1rem rgba(255, 255, 255, 0.15);
}
.section-gradient.-black {
  background: #000000;
  color: #ffffff;
}

html.no-mobile .section-gradient__offer-button:not(:hover) .section-gradient__offer-button-value {
  -webkit-text-fill-color: #ffffff;
  background: none;
}

html.no-mobile .section-gradient__offer-button:hover {
  background-color: #ffffff;
}

.section {
  margin-bottom: 8.2rem;
}
@media (min-width: 768px) {
  .section {
    margin-bottom: 13.6rem;
  }
}
.section__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.3rem;
}
.section__header-integrated {
  display: flex;
  margin-bottom: 2.3rem;
  justify-content: center;
  font-size: 4rem;
  line-height: 4rem;
}
@media (min-width: 768px) {
  .section__header-integrated {
    display: flex;
    margin-bottom: 2.3rem;
    justify-content: center;
    font-size: 4.5rem;
    line-height: 0;
  }
}
.section__footer {
  padding-top: 2.2rem;
}
@media (min-width: 768px) {
  .section__footer {
    position: absolute;
    top: 0.8rem;
    right: 0;
    padding-top: 0;
  }
}

.stats {
  font-weight: 600;
  letter-spacing: 0;
  margin: 13rem 0 9.4rem;
}
@media (min-width: 768px) {
  .stats {
    margin: 17.6rem 0;
  }
}
.stats__data {
  padding: 0 2.2rem;
}
@media (min-width: 768px) {
  .stats__data {
    display: flex;
    padding: 0;
  }
}
.stats__data-block {
  text-align: center;
  padding: 6.5rem 0 7.4rem;
}
@media (min-width: 768px) {
  .stats__data-block {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-height: 20rem;
    box-sizing: border-box;
    padding: 0 3.2rem 2.6rem;
  }
}
.stats__data-block:first-child {
  padding-top: 6rem;
}
@media (min-width: 768px) {
  .stats__data-block:first-child {
    padding-top: 0;
  }
}
.stats__data-block + .stats__data-block {
  border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .stats__data-block + .stats__data-block {
    border-top: none;
    border-left: 0.1rem solid rgba(0, 0, 0, 0.1);
  }
}
.stats__data-block-subheadline {
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.5rem;
  margin-bottom: 0.9rem;
}
.stats__data-block-headline {
  font-size: 4rem;
  line-height: 4rem;
}
.stats__data-block-number {
  width: 100%;
  font-size: 6rem;
  line-height: 7rem;
  text-align: center;
  padding: 1rem 0;
}

.subhead {
  display: block;
  color: #000000;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .subhead {
    margin-bottom: 1.5rem;
    font-size: 1.7rem;
    line-height: 2.8rem;
  }
}
@media (min-width: 1440px) {
  .subhead {
    margin-bottom: 0;
  }
}
.subhead__title, .subhead__value {
  display: inline-block;
  vertical-align: baseline;
}
.subhead__title {
  font-weight: 500;
}
.subhead__title:after {
  content: "____";
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  letter-spacing: -0.15em;
  position: relative;
  top: -0.058824em;
  -webkit-font-smoothing: none;
  font-smooth: never;
  width: 2.6rem;
  margin: 0 0.5rem;
}
@media (min-width: 768px) {
  .subhead__title:after {
    width: 3.1rem;
    margin: 0 0.8rem;
  }
}
.subhead__value {
  font-family: "Ogg", "Times New Roman", serif;
}

.tabs-content__entry {
  display: none;
}
.tabs-content__entry.-active {
  display: block;
}

.tabs {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}
.tabs__list {
  margin-bottom: -0.1rem;
}
.tabs__list-item {
  display: inline-block;
  vertical-align: top;
  border: none;
  background: none;
  border-bottom: 1px solid transparent;
  font: 500 1.6rem/2rem "Sharp Sans No1", "Arial", sans-serif;
  padding: 1.4rem 0;
  letter-spacing: 0;
  margin-right: 2.7rem;
}
.tabs__list-item.-active {
  border-bottom-color: #000000;
}
.tabs__list > h1 {
  font-size: 1.2rem;
}

.teaching-articles {
  height: 61rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: -0.5rem 0;
}
.teaching-articles__entry {
  box-sizing: border-box;
  padding: 0.5rem 0;
  height: 33.33333%;
  transition: transform 150ms;
}
.teaching-articles__entry-link {
  display: flex;
  align-items: center;
  color: #000000;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0.3rem 0.5rem 1.4rem rgba(0, 0, 0, 0.06);
}
.teaching-articles__entry-preview {
  display: block;
  flex: 0 0 auto;
  width: 45.5%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
  object-fit: contain;
}
.teaching-articles__entry-details {
  display: block;
  flex: 1 1 auto;
  margin: 0 2.5%;
}
@media (min-width: 1024px) {
  .teaching-articles__entry-details {
    margin: 0 5.5%;
  }
}
.teaching-articles__entry-title {
  display: block;
  position: relative;
  overflow: hidden;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.6rem;
  margin: 0.8rem 0 0 0;
}
@media (min-width: 1024px) {
  .teaching-articles__entry-title {
    margin: 0 0 0.9rem 0;
  }
}
html.no-mobile .teaching-articles__entry-title,
html.no-mobile .teaching-articles__entry .news-info {
  background: linear-gradient(to left, #0369FB 0%, #72DA0E 16.67%, #F7C233 33.34%, #EE7E33 50.01%, #E93F33 66.68%, #A552F2 83.35%, #0369FB 100%);
  background-size: 2000% 400%;
  animation: gradient 30s linear infinite;
  color: #0369FB;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.teaching-articles__entry-title p,
.teaching-articles__entry .news-info p {
  font-size: 1.3rem;
}

html.no-mobile .teaching-articles__entry-link:not(:hover) .teaching-articles__entry-title {
  -webkit-text-fill-color: #000000;
}

html.no-mobile .teaching-articles__entry-link:not(:hover) .news-info {
  -webkit-text-fill-color: #8a8a8a;
  background: none;
  -webkit-background-clip: initial;
}

html.no-mobile .teaching-articles__entry-link:not(:hover) .teaching-articles__entry-title {
  background: none;
  -webkit-background-clip: initial;
}

html.no-mobile .teaching-articles__entry:hover {
  transform: translateY(-0.5rem);
}

.teaching-tile {
  display: block;
  position: relative;
  color: #ffffff;
  transition-property: opacity, transform;
  transition-duration: 150ms;
  background-position: 50% 50%;
  background-size: cover;
  height: 47.7rem;
}
@media (min-width: 768px) {
  .teaching-tile {
    height: 100%;
  }
}
.section:not(.aos-animate) .teaching-tile {
  background: none !important;
}
.teaching-tile__details {
  display: block;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2.6rem 2.1rem;
}
@media (min-width: 768px) {
  .teaching-tile__details {
    padding: 1.6rem 3rem;
  }
}
.teaching-tile__details-headline {
  display: block;
  font-family: "Ogg", "Times New Roman", serif;
  font-size: 2.2rem;
  line-height: 3.4rem;
  padding-bottom: 1.6rem;
}
@media (min-width: 768px) {
  .teaching-tile__details-headline {
    font-size: 3rem;
    line-height: 4.1rem;
    padding-bottom: 2rem;
  }
}

html.no-mobile .teaching-tile:hover {
  transform: translateY(-0.5rem);
  opacity: 0.85;
}

.teasr {
  padding: 1rem 0 0 0;
}
@media (min-width: 768px) {
  .teasr {
    padding: 1.5rem 0;
  }
}
.teasr__cover {
  margin-bottom: 2.9rem;
}
.teasr__cover img {
  width: 100%;
}
@media (min-width: 768px) {
  .teasr__cover {
    margin-bottom: 0;
  }
}
.teasr__text {
  padding: 0 0 1rem 0;
}
@media (min-width: 768px) {
  .teasr__text {
    padding: 0 0 0 17.2%;
  }
}
.teasr__text h2 {
  margin-bottom: 2.3rem;
}
@media (min-width: 768px) {
  .teasr__text h2 {
    margin-bottom: 3.5rem;
  }
}

h1 {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-family: "Sharp Sans No1", "Arial", sans-serif;
  font-size: 3rem;
  line-height: 4rem;
}
@media (min-width: 768px) {
  h1 {
    font-size: 6rem;
    line-height: 7rem;
  }
}

h2 {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-family: "Sharp Sans No1", "Arial", sans-serif;
  font-size: 2.7rem;
  line-height: 3.5rem;
}
@media (min-width: 768px) {
  h2 {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}

h3 {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.4rem;
}
@media (min-width: 768px) {
  h3 {
    font-size: 3rem;
    line-height: 4.5rem;
  }
}

h4 {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.6rem;
}

h5 {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3.2rem;
}
@media (min-width: 768px) {
  h5 {
    font-size: 2.2rem;
    line-height: 3.6rem;
  }
}

h6 {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.5rem;
}

blockquote {
  display: block;
  padding: 0;
  margin: 0;
}
blockquote p {
  font-family: "Ogg", "Times New Roman", serif;
  font-size: 2.7rem;
  line-height: 3.7rem;
  margin-bottom: 2.3rem;
}
@media (min-width: 768px) {
  blockquote p {
    font-size: 4rem;
    line-height: 5.2rem;
    margin-bottom: 2.1rem;
  }
}
blockquote cite {
  display: inline-block;
  vertical-align: top;
  font-style: normal;
}
@media (max-width: 767px) {
  blockquote cite {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
blockquote cite:before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  height: 0.1rem;
  background-color: currentColor;
  width: 1.8rem;
  margin: 0 0.8rem 0 0.1rem;
}
@media (min-width: 768px) {
  blockquote cite:before {
    width: 3.1rem;
    margin: 0 0.9rem 0 0.2rem;
  }
}

.text-roman {
  font-family: "Ogg", "Times New Roman", serif;
  font-weight: normal;
}

.text b {
  font-weight: 600;
}
.text > p {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin: 2.6rem 0;
}
@media (min-width: 768px) {
  .text > p {
    font-size: initial;
    line-height: initial;
    margin: 0;
  }
}
.text .contact-buttons {
  margin: 1.4rem 0 5rem;
}
@media (min-width: 768px) {
  .text .contact-buttons {
    margin: 0 0 6.8rem;
  }
}
.text .complex-link.-role-more {
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .text .complex-link.-role-more {
    padding-top: 0.2rem;
  }
}
.text.-size-small > p {
  font-size: 1.4rem;
  line-height: 2.5rem;
  margin: 2.5rem 0;
}
@media (min-width: 768px) {
  .text.-size-small > p {
    font-size: initial;
    line-height: initial;
    margin: 0;
  }
}
.text.-size-small h6 {
  margin: 3.2rem 0 0.1rem;
}
.text.-size-small h6 + p {
  margin-top: 0;
}
@media (min-width: 768px) {
  .text.-size-normal > p {
    font-size: 2rem;
    line-height: 3.6rem;
    margin: 3.6rem 0;
  }
}
.text.-size-normal h2 + p {
  margin-top: 2.4rem;
}
.text.-size-normal h4 {
  margin: 8rem 0rem 1rem;
}
.text.-size-normal h4 + p {
  margin-top: 1rem;
}
.text.-size-normal h5 {
  margin: 4.6rem 0 0.7rem;
}
.text.-size-normal h5 + p {
  margin-top: 0;
}
@media (min-width: 768px) {
  .text.-size-normal h5 {
    margin: 0;
  }
}
.text.-size-normal blockquote {
  margin: 8rem 0;
}
.text.-size-normal blockquote p:first-child {
  text-indent: -1.6rem;
}
.text.-size-normal .headline + p {
  margin-top: 2.4rem;
}
@media (min-width: 768px) {
  .text.-size-normal .headline + p {
    margin-top: 1.6rem;
  }
}
.text.-size-normal.-spacious p {
  line-height: 3rem;
}
@media (min-width: 768px) {
  .text.-size-normal.-spacious p {
    line-height: 4rem;
  }
}
@media (min-width: 768px) {
  .text.-size-big > p {
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin: 3.6rem 0;
  }
}
@media (min-width: 768px) {
  .text.-size-bigger > p {
    font-size: 2.2rem;
    line-height: 3.5rem;
    margin: 3.5rem 0;
  }
}
.text > :first-child {
  margin-top: 0 !important;
}
.text > :last-child {
  margin-bottom: 0 !important;
}

.video-embed__iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.video-embed__iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.video-embed__cover {
  margin-bottom: 2.9rem;
}
@media (min-width: 768px) {
  .video-embed__cover {
    margin-bottom: 0;
  }
}
.video-embed__text {
  padding: 0 0 1rem 0;
}
@media (min-width: 768px) {
  .video-embed__text {
    padding: 0 0 0 17.2%;
  }
}
.video-embed__text .video-embed__title {
  font-family: "Sharp Sans No1", "Arial", sans-serif;
  font-weight: 600;
  color: #000000;
  padding: 0;
  margin: 0 0 2.3rem 0;
  font-size: 2.7rem;
  line-height: 3.5rem;
}
@media (min-width: 768px) {
  .video-embed__text .video-embed__title {
    margin: 0;
    font-size: 3.5rem;
    line-height: 1.3em;
  }
}
@media (min-width: 1024px) {
  .video-embed__text .video-embed__title {
    font-size: 4rem;
    line-height: 1.3em;
  }
}

.work-slider {
  padding-top: 0.4rem;
}
.work-slider + .work-slider {
  margin-top: 3.5rem;
}
@media (min-width: 768px) {
  .work-slider + .work-slider {
    margin-top: 6.4rem;
  }
}
.work-slider__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.9rem;
}
@media (min-width: 768px) {
  .work-slider__header {
    padding-bottom: 2.3rem;
  }
}
.work-slider__header-title {
  font-weight: 600;
  line-height: 3.2rem;
  font-size: 2rem;
}
.work-slider__header-title a {
  padding-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .work-slider__header-title {
    font-size: 2.2rem;
  }
}
.work-slider__header-nav {
  font-size: 0;
  line-height: 0;
  display: none;
}
@media (min-width: 768px) {
  .work-slider__header-nav {
    display: initial;
  }
}
.work-slider__header-nav button {
  display: inline-block;
  vertical-align: top;
  border: none;
  background: none;
  font-size: 0;
  line-height: 0;
}
.work-slider__header-nav button + button {
  margin-left: 4rem;
}
.work-slider__header-nav button .ico {
  width: 3.6rem;
  height: 2.3rem;
}
.work-slider__header-nav button[disabled] {
  opacity: 0.1;
}
.work-slider__scrollable {
  position: relative;
}
html.mobile .work-slider__scrollable {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow: -moz-scrollbars-none;
}
html.mobile .work-slider__scrollable::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
html.no-mobile .work-slider__scrollable {
  overflow: hidden;
}
.work-slider__scrollable .ps__rail-x {
  position: absolute;
  bottom: 0;
  left: 0;
}
.work-slider__scrollable-slides {
  display: flex;
}
.work-slider__slide {
  flex: 0 0 auto;
  min-width: 28rem;
  width: calc(30vw - 0.65rem);
}
@media (min-width: 1440px) {
  .work-slider__slide {
    width: 41.6rem;
  }
}
.work-slider__slide .work-tile-big {
  margin-bottom: 0;
}
.work-slider__slide + .work-slider__slide {
  margin-left: 1rem;
}
.work-slider__slide:first-child {
  padding-left: 2.2rem;
}
@media (min-width: 768px) {
  .work-slider__slide:first-child {
    padding-left: 5vw;
  }
}
@media (min-width: 1440px) {
  .work-slider__slide:first-child {
    padding-left: calc((100vw - 126.8rem) / 2);
  }
}
.work-slider__slide:last-child {
  padding-right: 2.2rem;
}
@media (min-width: 768px) {
  .work-slider__slide:last-child {
    padding-right: 5vw;
  }
}
@media (min-width: 1440px) {
  .work-slider__slide:last-child {
    padding-right: calc((100vw - 126.8rem) / 2);
  }
}

.work-tile-big {
  display: block;
  margin-bottom: 3.5rem;
}
:last-child > .work-tile-big {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .work-tile-big {
    margin-bottom: 6.4rem;
  }
  :nth-last-child(1) > .work-tile-big, :nth-last-child(2) > .work-tile-big {
    margin-bottom: 0.7rem;
  }
}
.work-tile-big__cover {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.2rem;
}
@media (min-width: 768px) {
  .work-tile-big__cover {
    margin-bottom: 1.1rem;
  }
}
.work-tile-big__cover:before {
  content: "";
  display: block;
  padding-top: 73%;
}
@media (min-width: 768px) {
  .work-tile-big__cover:before {
    padding-top: 73.131955%;
  }
}
.work-tile-big__cover:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0 solid #fdfdfd;
  box-sizing: border-box;
  transition: border-width 300ms;
  will-change: border-width;
}
.work-tile-big__cover-image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  transition: transform 300ms;
}
.work-slider__scrollable-slides:not(.aos-animate) .work-tile-big__cover-image, .grid__row:not(.aos-animate) .work-tile-big__cover-image {
  background: none !important;
}
.work-tile-big__details {
  display: block;
  transition: transform 150ms;
}
.work-tile-big__headline {
  display: block;
  font-weight: 500;
  line-height: 3.1rem;
  color: #000000;
  font-size: 1.7rem;
  margin-bottom: 0.4rem;
}
@media (min-width: 768px) {
  .work-tile-big__headline {
    font-size: 2rem;
    margin-bottom: 0.7rem;
  }
}
.work-tile-big__desc {
  display: block;
  font-weight: 500;
  color: #8a8a8a;
  font-size: 1.3rem;
  line-height: 2rem;
}
@media (min-width: 768px) {
  .work-tile-big__desc {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
}

html.no-mobile .work-tile-big:hover .work-tile-big__cover:after {
  border-width: 1.5rem;
}

html.no-mobile .work-tile-big:hover .work-tile-big__cover-image {
  transform: scale(1.03);
}

html.no-mobile .work-tile-big:hover .work-tile-big__details {
  transform: translateX(1.5rem);
}