@keyframes rotateColor {
    0% {
        filter: hue-rotate(360deg);
    }

    100% {
        filter: hue-rotate(0deg);
    }
}

.page-landing {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .wrapper {
        @include medium {
            margin-bottom: 0;
        }
    }
  
    .main {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
}

.landing {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100%;
    background-color: $brandColor2;

    &__canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        animation: 10s rotateColor linear infinite;
      
        canvas {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__rasters {
        display: none;
    }

    &__link {
        display: block;
        position: absolute;
        z-index: 100010;
        width: 12.5rem;
        height: 5rem;
        bottom: 3.3rem;
        cursor: pointer;
        border: none;
        background: none;
        right: 5.5%;

        @include xxlarge {
            right: initial;
            left: 50%;
            margin-left: 63.9rem;
            transform: translateX(-100%);
        }
    }
}

#strategyAestheticsBlk {
    animation: 5s rotateColor linear infinite;
}
  