// ****** Breakpoints ****** //
// $small: 0; 
// $smedium: 480px;
// $medium: 768px; 
// $tablet: 1023px;
// $large: 1024px; 
// $xlarge: 1280px; 
// $xxlarge: 1440px;
// $uber: 2000px;
@mixin small {
    @media (min-width: #{$small}) {
        @content;
    }
}

@mixin smedium {
    @media (min-width: #{$smedium}) {
        @content;
    }
}

@mixin belowmedium {
    @media(max-width: #{$mobile}) {
        @content;
    }
}

@mixin medium {
    @media (min-width: #{$medium}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$medium}) and (max-width: #{$tablet}) {
        @content;
    }
}

@mixin belowlarge {
    @media (max-width: #{$tablet}) {
        @content;
    }
}

@mixin large {
    @media (min-width: #{$large}) {
        @content;
    }
}

@mixin xlarge {
    @media (min-width: #{$xlarge}) {
        @content;
    }
}

@mixin xxlarge {
    @media (min-width: #{$xxlarge}) {
        @content;
    }
}

@mixin uber {
    @media (min-width: #{$uber}) {
        @content;
    }
}



// NOTE • FOr developers
// If youre wanting to convert old-stylus code into new media queries - 'min-width' translates to this breakpoint and upwards. 'max-width' translates to this breakpoint and down. You should never need to use max-width or youve written your code wrong.
// You would write your code like:
// @include medium {
//     example: item;
// }
// This targets the medium breakpoint and anything upwards - to target desktop or higher, simply use @include large or xxlarge or whatever you nee dto overwrite (mobile first approach).




// Example class usage (This shows the color of text changing at each specified breakpoint)
// .example {
//     color: blue;

//     // On medium up
//     @include medium { color: red; }

//     // On large up
//     @include large { color: blue; }

//     // On huge up
//     @include huge { color: pink; }
// }