$ns: ".grid-tile";

#{$ns} {
    background-color: $brandColor2;
    box-shadow: 0 0 0.6rem 0 rgba($brandColor1, 0.03), 0 0.3rem 1.5rem 0 rgba($brandColor1, 0.06);
    border-radius: 0.1rem;
    height: 100%;
    box-sizing: border-box;
    color: $brandColor1;
    transition-property: box-shadow, transform;
    transition-duration: $speed;
    display: block;
    padding: 2rem 2.1rem 3.6rem;

    @include medium {
        padding: 2rem 3rem 4.5rem;
    }
  
    &,
    > span {
        display: block;
    }

    &__icon {
        width: 9.4rem;
        height: 9.4rem;
        font-size: 0;
        line-height: 9.4rem;
        margin-bottom: 1.6rem;
        text-align: center;

        html.cssmask & {
            @include fluxBg;
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
        }

        html.no-cssmask & {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        $icons: "branding", "consulting", "design", "innovation", "mobile", "research";
        
        @each $icon in $icons {
            &.-#{$icon} {
                html.cssmask & {
                    mask-image: url($assetBaseUrl + "/img/services/" + #{$icon} + ".svg");
                }

                html.no-cssmask & {
                    background-image: url($assetBaseUrl + "/img/services/" + #{$icon} + ".svg");
                }
            }
        }
    }

    &__title {
        font-weight: 600;
        line-height: 3.2rem;
        font-size: 1.8rem;
        margin-bottom: 0.8rem;

        @include medium {
            font-size: 2.2rem;
            margin-bottom: 1rem;
        }
    }

    &__desc {
        position: relative;
        overflow: hidden;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.6rem;

        @include medium {
            font-size: 2rem;
            line-height: 3.1rem;
        }
    }

    &__title,
    &__desc {
        html.no-mobile & {
            @include fluxText;
        }
    }
}

// isMobile library CSS override 

html.no-mobile #{$ns}:not(:hover) #{$ns}__title, 
html.no-mobile #{$ns}:not(:hover) #{$ns}__desc {
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: $brandColor1;
}

html.no-mobile #{$ns}:hover {
    box-shadow: 0 0.3rem 2rem 0 rgba($brandColor1, 0.17);
    transform: translateY(-0.5rem);
}

html.no-mobile.cssmask #{$ns}:not(:hover) #{$ns}__icon {
    background: $brandColor1; 
}