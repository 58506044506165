$schumacherSecondary: #fff7b1;

.work-schumacher {
    background-color: $brandColor2;

    &__image {
        img {
            display: block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }

        &.-intro {
            .cover {
                height: 56rem;

                @include medium {
                    height: 50rem;
                }

                @include large {
                    height: 68rem;
                }

                @include xxlarge {
                    height: 80rem;
                }

                @include uber {
                    height: 110rem;
                }
            }
        }

        &.-background {
            .cover {
                height: 40.4rem;

                @include smedium {
                    height: 55.3rem;
                }

                @include medium {
                    height: 81.4rem;
                }

                @include large {
                    height: 101.7rem;
                } 
            }
        }

        &.-solution {
            .cover {
                height: 55rem;
                background-position: 64.5% 0;

                @include medium {
                    height: 67rem;
                    background-position: top center;
                }

                @include xxlarge {
                    height: 94.2rem;
                }

                @include uber {
                    height: 150rem;
                }
            }
        }

        &.-experience {
            img {
                height: 67rem;
                margin-left: 2.5rem;

                @include medium {
                    width: 97.1%;
                    height: initial;
                    margin-left: -0.4rem;
                }

                @include large {
                    width: 91.7%;
                    margin-left: -0.5rem;
                }

                @include xlarge {
                    width: 92.5%;
                }

                @include xxlarge {
                    width: 82%;
                }
            }
        }

        &.-filters {
            img {    
                height: 54rem;

                @include medium {
                    height: initial;
                    width: 97.8%;
                }

                @include large {
                    width: 100.3%;
                }

                @include xlarge {
                    width: 92.5%;
                }

                @include xxlarge {
                    width: 88%;
                }
            }
        }

        &.-search {
            img {
                height: 71.4rem;
                margin-left: 3.8rem;

                @include medium {
                    height: initial;
                    width: 117.8%;
                    margin-left: 2rem;
                }

                @include large {
                    width: 108.4%;
                    margin-left: 2.3rem;
                }

                @include xlarge {
                    width: 134%;
                    margin-left: 2.4rem;
                }

                @include xxlarge {
                    width: 81%;
                    margin-left: 2.6rem;
                }
            }
        }

        &.-showcase {
            img {
                width: 111.1%;

                @include medium {
                    width: 81.3%;
                }

                @include large {
                    width: initial;
                    height: 63.9rem;
                }

                @include xxlarge {
                    height: 80rem; 
                }
            }
        }
    }

    &__text {
        &.-assignment {
            margin: 10.4rem 0 11.7rem;

            @include medium {
                margin: 10.6rem 0 12.3rem;
            }

            @include large {
                margin: 30.6rem 0 23.6rem;
            }

            @include xlarge {
                margin: 26.8rem 0 27.2rem;
            }
        }

        &.-background {
            margin: 10.7rem 0 11.5rem;

            @include medium {
                margin: 10.6rem 0 12.3rem;
            }

            @include large {
                margin: 26.7rem 0 14.6rem;
            }

            @include xlarge {
                margin: 26.7rem 0 14.3rem;
            }
        }

        &.-challenge {
            margin: 10.3rem 0 9.9rem;

            @include medium {
                margin: 10.6rem 0 10.5rem;
            }

            @include large {
                margin: 26.7rem 0 26.5rem;
            }

            @include xlarge {
                margin: 26.7rem 0 25.9rem;
            }
        }

        &.-breakthrough {
            margin: 9.9rem 0 10.5rem;

            @include medium {
                margin: 10.5rem 0 11.8rem;
            }

            @include large {
                margin: 26.5rem 0 25.3rem;
            }

            @include xlarge {
                margin: 25.9rem 0 24.2rem;
            }
        }

        &.-solution {   
            margin: 10.5rem 0 7.4rem;
            
            @include medium {
                margin: 11.8rem 0 12.7rem;
            }

            @include large {
                margin: 24.2rem 0 14.8rem;
            }

            @include xlarge {
                margin: 24.2rem 0 14.5rem;
            }
        }

        &.-experience {
            margin: 11rem 0 5.4rem;

            @include medium {
                margin: 10.6rem 0 21.8rem;
            }

            @include large {
                margin: 26.7rem 0 21.6rem;
            }

            @include xlarge {
                margin: 26.6rem 0 26.1rem;
            }
        }

        &.-filters {
            margin: -1.8rem 0 10.2rem;

            @include medium {
                margin: 17.4rem 0 19.5rem;
            }

            @include large {
                margin: 21.1rem 0 23.9rem;
            }

            @include xlarge {
                margin: 24.8rem 0 25.3rem;
            }
        }

        &.-search {
            margin: 10.1rem 0 3.3rem;

            @include medium {
                margin: 25.7rem 0 14.6rem;
            }

            @include large {
                margin: 43rem 0 15.8rem;
            }

            @include xlarge {
                margin: 37.6rem 0 15.9rem;
            }
        }

        &.-showcase {
            margin: 6.1rem 0 11.5rem;

            @include medium {
                margin: 12.9rem 0 10.1rem;
            }

            @include large {
                margin: 28.2rem 0 14.3rem;
            }

            @include xlarge {
                margin: 28rem 0 14.2rem;
            }
        }

        &.-strategy {
            position: relative;
            padding: 19.3rem 0 22.2rem;

            @include medium {
                padding: 29.6rem 0 32.9rem;
            }

            @include large {
                padding: 39rem 0 35.5rem;
            }

            @include xlarge {
                padding: 39rem 0 40.1rem;
            }

            @include xxlarge {
                position: static;
            }
  
            .container {
                position: static;

                @include xxlarge {
                    position: relative;
                }
            }

            .work-single__text-triangle {
                border-top-color: $schumacherSecondary;
            }
        }
    }

    &__quote {
        .container {
            padding: 3rem 0;
            box-sizing: border-box;
            background-color: $schumacherSecondary;
            color: $brandColor1;
            position: relative;
            display: flex;
            align-items: center;
            min-height: 41rem;
            width: 92.2%;

            @include medium {
                min-height: 56.8rem;
            }

            @include large {
                width: 94.043%;
            }

            @include xlarge {
                width: 92.2%;
            }
        }

        .cover {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        .work-single__quote {
            position: relative;
            z-index: 2;
        }

        &.-assignment {
            .cover {
                opacity: 0.13;
                background-position: 111% 50%;

                @include large {
                    opacity: 1;
                    background-position: 75.5% 50%;
                }

                @include xlarge {
                    background-position: 111% 50%;
                }  
            }

            .work-single__quote {
                @include large {
                    padding: 0 0 2.6rem 6.5%;
                    width: 50%;
                }

                @include xlarge {
                    padding: 0 0 2.6rem 8.3%;
                    width: 48%;
                }

                &-text {
                    width: 78%;
                    padding: 0.6rem 0 1.4rem;
                    margin: auto;
                    text-align: center;

                    @include medium {
                        width: 74%;
                        padding: 0.2rem 0 7.1rem;
                    }

                    @include large {
                        width: initial;
                        padding: 0 0 7.1rem;
                        // padding-top: initial;
                        margin: initial;
                        text-align: initial;
                    }

                    @include xlarge {
                        padding: 0 0 7.4rem;
                    }
                }

                &-cite {
                    margin: 0 0 0 25%;
                    width: 55%;

                    @include medium {
                        margin: 0 0 0 57.6%;
                        width: initial;
                    }

                    @include large {
                        margin: 0 0 0.6rem 50%;
                    }
                }
            }
        }

        &.-strategy {
            .container {
                overflow: hidden;

                @include large {
                    overflow: visible;
                } 
            }

            img {
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 90%;
                opacity: 0.95;

                @include large {
                    left: 50%;
                    margin-left: -24.5%;
                    max-width: 46%;
                    transform: translate(-50%, -50%);
                    height: initial;
                    opacity: initial;
                }

                @include xxlarge {
                    left: 4.7%;
                    max-width: 39.9842%;
                    transform: none;
                    margin-left: 0;
                }

                &:nth-child(1) {
                    left: 0;
                    transform: translate(-69%, -50%);

                    @include large {
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    @include xxlarge {
                        left: 25%;
                    }
                }
            
                &:nth-child(2) {
                    right: 0;
                    transform: translate(69%, -50%);
                }
            }

            .work-single__quote {   
                @include large {
                    padding-left: 50.5%;
                    width: 43%;
                }        

                &-text {
                    width: 78%;
                    padding: 0.4rem 0 3.6rem;
                    margin: auto;
                    text-align: center;

                    @include medium {
                        width: 68%;
                        padding: 0.2rem 0 7.1rem;
                    }

                    @include large {
                        width: initial;
                        padding: initial;
                        margin: initial;
                        text-align: initial;
                        padding: 0 0 7.4rem;
                    }
                }

                &-cite {
                    margin: 0 0 0 25%;
                    width: 55%;

                    @include medium {
                        margin: 0 0 0 57.9%;
                        width: initial;
                    }

                    @include large {
                        margin: 0 0 2.2rem 50%;
                    }
                }
            }
        }
    }
}
 
  