$ns: ".contact-form";
$contactPrimary: #0e0f0f;

#{$ns} {
    width: 100%;
    position: relative;
    margin: auto;
    transition: opacity $speed * 4 $speed * 5;
    
    &-container {
        margin: 6.8rem 5rem 0 0;
    }

    &__input {
        label {
            display: none;
        }
        height: 5rem;
        position: relative;
        input {
            display: block;
            width: 100%;
            height: 100%;
            background: none;
            border: none;
            font: 500 1.7rem/2.4rem $SharpSans;
            color: $brandColor2;
            box-sizing: border-box;
            padding-bottom: .4rem;
            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder {
                color: $brandColor2;
            }
            &:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 3rem $contactPrimary inset;
                -webkit-text-fill-color: $brandColor2 !important;
            }
            &.-focused,
            &.-filled
            #{$ns} {
                &__input-border {
                    height: .2rem;
                    &-gradient {
                        opacity: 1;
                    }
                }
            }
            &.-filled {
                + #{$ns} {
                    &__button {
                        color: $brandColor2;
                    }
                    &__input-placeholder {
                        display: none;
                    }
                }
            }
            &.-sent {
                #{$ns} {
                    &__control {
                        opacity: 0;
                        visibility: hidden;
                    }
                    &__input-border {
                        height: 100%;
                        &-gradient {
                            opacity: 1;    
                        }
                        .subhead {
                            transform: translateY(-50%);
                        }
                    }

                }
            }
        }
        .submit-button {
            position: relative;
            border: .2rem solid $brandColor2;
            border-radius: .1rem;
            background: none;
            font: 500 1.7rem/2.4rem $SharpSans;
            display: block;
            width: 18.2rem;
            height: 4.3rem;
            box-sizing: border-box;
            padding: 0 2.4rem;
            text-transform: uppercase;
            letter-spacing: .25rem;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 3.6rem;
            margin-top: 2.4rem;
            text-align: center;
            transition: color $speed * 3;
            // color: rgba($brandColor2, 0.29);
            color: $brandColor2;
            &[disabled] {
                pointer-events: none;
            }
        }
        &-placeholder {
            pointer-events: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-bottom: .4rem;
            color: $brandColor2;
            font-weight: 500;
            font-size: 1.7rem;
            line-height: 2.4rem;

            @include large {
                left: .7rem;
            }

            b {
                font-weight: 600;
            }
        }
        &-border {
            @include fluxBg();
            position: absolute;
            z-index: 4;
            bottom: 0;
            left: 0;
            overflow: hidden;
            width: 100%;
            height: .2rem;
            transition: height $speed * 3;
            &-gradient {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity $speed * 3;
            }
            .subhead {
                color: $brandColor2;
                position: absolute;
                left: 2rem;
                top: 50%;
                transform: translateY(100%);
                transition: transform $speed * 2 $speed * 2;
                font-size: 2rem;
                margin-top: -.2rem;
            }
        }
        ul.errors.suppressErrorMsg {
            display: none;
        }
    }
    &__control {
        position: relative;
        display: block;
        z-index: 3;
        transition-property: visibility, opacity;
        transition-duration: $speed * 2;
        top: 3.5rem;
    }
    &__error {
        position: relative;
        display: flex;
        justify-content: left;
        align-items: flex-start;
        color: $error;
        font-size: 1.4rem;
        line-height: 2.4rem;
        margin-top: 1.2rem;
        text-align: left;
        visibility: visible;
        box-sizing: border-box;
        img {
            position: relative;
            top: .1rem;
            margin-right: .6rem;
            flex: 0 0 auto;
        }
        &.-active {
            display: flex;
            visibility: visible;
            padding-top: .9rem;
        }
    }
    &.-active {
        #{$ns} {
            &__input {
                &:before,
                &:after {
                    opacity: 0.5;
                }
                &:before {
                    transform: translateX(-100%);
                }
                &:after {
                    transform: translateX(-100%);
                }
            }
            &__control {
                transform: none;
            }
            &__button {
                color: $brandColor3;
            }
        }
    }
    &__confirmation {
        position: relative;
        color: $brandColor2;
        font-weight: 500;
        font-size: 2rem;
        line-height: 3.6rem;
        margin: 2.4rem 0 0 0;
    }
}