h1 {
	padding: 0;
	margin: 0;
	font-weight: 600;
	font-family: $SharpSans;
	font-size: 3rem;
	line-height: 4rem;

	@include medium {
		font-size: 6rem;
		line-height: 7rem;
	}
}

h2 {
	padding: 0;
	margin: 0;
	font-weight: 600;
	font-family: $SharpSans;
	font-size: 2.7rem;
	line-height: 3.5rem;

	@include medium {
		font-size: 4rem;
		line-height: 4.8rem;
	}
}

h3 {
	padding: 0;
	margin: 0;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3.4rem;

	@include medium {
		font-size: 3rem;
		line-height: 4.5rem;
	}
}

h4 {
	padding: 0;
	margin: 0;
	font-weight: 600;
	font-size: 2.4rem;
	line-height: 3.6rem;
}

h5 {
	padding: 0;
	margin: 0;
	font-weight: 600;
	font-size: 1.8rem;
	line-height: 3.2rem;

	@include medium {
		font-size: 2.2rem;
		line-height: 3.6rem;
	}
}

h6 {
	padding: 0;
	margin: 0;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 2.5rem;
}

blockquote {
	display: block;
	padding: 0;
	margin: 0;

	p {
		font-family: $Ogg;
		font-size: 2.7rem;
		line-height: 3.7rem;
		margin-bottom: 2.3rem;

		@include medium {
			font-size: 4rem;
			line-height: 5.2rem;
			margin-bottom: 2.1rem;
		}
	}

	cite {
		display: inline-block;
		vertical-align: top;
		font-style: normal;

		@include belowmedium {
			font-size: 1.2rem;
			line-height: 1.6rem;
		}

		&:before {
			content: "";
			display: inline-block;
			vertical-align: baseline;
			height: 0.1rem;
			background-color: currentColor;
			width: 1.8rem;
			margin: 0 0.8rem 0 0.1rem;

			@include medium {
				width: 3.1rem;
				margin: 0 0.9rem 0 0.2rem;
			}
		}
	}
}

.text-roman {
	font-family: $Ogg;
	font-weight: normal;
}

.text {
	
	b {
		font-weight: 600;
	}

	> p {
		font-weight: 500;
		font-size: 1.6rem;
		line-height: 2.6rem;
		margin: 2.6rem 0;

		@include medium {
			font-size: initial;
			line-height: initial;
			margin: 0;
		}
	}
	
	.contact-buttons {
		margin: 1.4rem 0 5rem;

		@include medium {
			margin: 0 0 6.8rem;
		}
	}

	.complex-link.-role-more {
		padding-top: 2rem;

		@include medium {
			padding-top: 0.2rem;
		}
	}

	&.-size-small {
		> p {
			font-size: 1.4rem;
			line-height: 2.5rem;
			margin: 2.5rem 0;

			@include medium {
				font-size: initial;
				line-height: initial;
				margin: 0;
			}
		}

		h6 {
			margin: 3.2rem 0 0.1rem;
			+ p {
				margin-top: 0;
			}
		}
	}

	&.-size-normal {
		> p {


			@include medium {
				font-size: 2rem;
				line-height: 3.6rem;
				margin: 3.6rem 0;
			}
		}

		h2 {
			+ p {
				margin-top: 2.4rem;
			}
		}

		h4 {
			margin: 8rem 0rem 1rem;
			+ p {
				margin-top: 1rem;
			}
		}

		h5 {
			+ p {
				margin-top: 0;
			}

			margin: 4.6rem 0 0.7rem;

			@include medium {
				margin: 0;
			}
		}

		blockquote {
			margin: 8rem 0;
			p:first-child {
				text-indent: -1.6rem;
			}
		}

		.headline {
			+ p {
				margin-top: 2.4rem;

				@include medium {
					margin-top: 1.6rem;
				}
			}
		}

		&.-spacious {
			p {
				line-height: 3rem;

				@include medium {
					line-height: 4rem;
				}
			}
		}
	}

	&.-size-big {
		> p {
			@include medium {
				font-size: 2.4rem;
				line-height: 3.6rem;
				margin: 3.6rem 0; 
			}
		}
	}

	&.-size-bigger {
		> p {
			@include medium {
				font-size: 2.2rem;
				line-height: 3.5rem;
				margin: 3.5rem 0; 
			}
		}
	}

	> :first-child {
		margin-top: 0 !important;
	}
	
	> :last-child {
		margin-bottom: 0 !important;
	}
}