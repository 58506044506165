.main {
	&__flow {
		padding-top: 5rem;

		@include medium {
			padding-top: 8.7rem;
		}

		&-subpages {
			font-size: 1.6rem;
			font-weight: 500;
			padding: 2.9rem 0 3.4rem;
			line-height: 3.6rem;

			@include medium {
				padding: 3.9rem 0 9.6rem;
			}

			&-link {
				margin: 0 0 -0.6rem 0;

				@include medium {
					display: inline-block;
					vertical-align: top;
					margin: 0 1.5rem 0 0;
				}
			}

			&.-small-spacing {
				@include medium {
					padding-bottom: 3.3rem;
				}
			}
		}

		&-subpages + &-banner {
			@include medium {
				margin-top: -5.6rem;
			}
		}


		&-banner {
			margin: 0 0 4rem;

			@include medium {
				margin: 4rem 0 8.4rem;
			}

			img {
				display: block;
				max-width: 100%;
			}

			&-foe {
				@include fluxBg;
				position: relative;
				font-size: 0;
				line-height: 0;

				&:before {
					content: "";
					display: block;
					padding-top: 59.782609%;

					@include medium {
						padding-top: 37.674%;
					}
				}

				&-frame {
					display: block;
					position: absolute;
					overflow: hidden;
					top: 1.2rem;
					left: 1.2rem;
					right: 1.2rem;
					bottom: 1.2rem;

					@include medium {
						top: 2.2rem;
						left: 2.2rem;
						right: 2.2rem;
						bottom: 2.2rem;
					}
				}

				svg {
					display: block;
					position: absolute;
					top: 0;
					height: 100%;
					left: 50%;
					transform: translateX(-50%);
				}
				  
			}
		}
	}
}