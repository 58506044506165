.stats {
    font-weight: 600;
    letter-spacing: 0;
    margin: 13rem 0 9.4rem;

    @include medium {
        margin: 17.6rem 0;
    }

    &__data {
        padding: 0 2.2rem;

        @include medium {
            display: flex;
            padding: 0;
        }

        &-block {
            text-align: center;
            padding: 6.5rem 0 7.4rem;

            @include medium {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                width: 100%;
                min-height: 20rem;
                box-sizing: border-box;
                padding: 0 3.2rem 2.6rem;
            }
              
            &:first-child {
                padding-top: 6rem;

                @include medium {
                    padding-top: 0;
                }
            }

            & + & {
                border-top: 0.1rem solid rgba($brandColor1, 0.1);

                @include medium {
                    border-top: none;
                    border-left: 0.1rem solid rgba($brandColor1, 0.1);
                }
            }

            &-subheadline {
                font-weight: 500;
                font-size: 1.7rem;
                line-height: 2.5rem;
                margin-bottom: 0.9rem;
            }

            &-headline {
                font-size: 4rem;
                line-height: 4rem;
            }

            &-number {
                width: 100%;
                font-size: 6rem;
                line-height: 7rem;
                text-align: center;
                padding: 1rem 0;
            }    
        }
    }
}