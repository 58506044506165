$ns: ".footer";
$footerPrimary: #979797;

#{$ns} {
    background-color: $brandColor1;
    color: $brandColor4;
    font-weight: 500;
    font-size: 1.5rem;
  
    hr {
      display: block;
      padding: 0;
      margin: 0 -5rem;
      border: none;
      height: 0.1rem;
      background-color: rgba($footerPrimary, 0.15);
    }

    &__main {
        padding: 5rem 0 0 0;

        @include medium {
            display: flex;
            justify-content: space-between;
            padding: 8rem 0;
        }

        &-glyphs {
            padding-right: 2rem;
            display: none;

            @include xlarge {
                display: revert;
            }

            img {
                display: block;
                max-width: 100%;
            }
        }

        &-nav {
            @include medium {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }

            @include xlarge {
                width: auto;
                justify-content: flex-start;
            }

            &-block {
                // margin-bottom: 4.6rem;
                margin: 0 0 4.6rem 0;

                @include medium {
                    margin: 0 0 0 1rem;
                }

                @include xlarge {
                    width: 20.2rem;
                }
                
                &-title {
                    text-transform: uppercase;
                    letter-spacing: 0.25rem;
                    font-weight: bold;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    color: $brandColor2;
                    margin-bottom: 3.2rem;

                    @include medium {
                        margin-bottom: 1.5rem;
                    }
                }

                &-link {
                    margin-bottom: 1.1rem;

                    @include medium {
                        margin-bottom: 0.2rem;
                    }
                }

                .contact-buttons {
                    margin-top: 2.7rem;
                }
            }
        }
    }

    &__secondary {
        @include medium {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: 6.3rem 0 5.6rem;
        }
    }

    &__tertiary {
        @include medium {
            height: 7rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__clutch {
        display: flex;
        align-items: center;
        padding: 3.8rem 0;

        @include medium {
            max-width: 33rem;
            padding: 0;
        }

        &-logo {
            flex: 0 0 auto;
            margin-right: 1.5rem;

            @include medium {
                margin-right: 2rem;
            }
  
            img {
                display: block;
                width: 8rem;

                @include medium {
                    width: auto;
                }     
            }
        }

        &-caption {
            line-height: 1.9rem;
  
            img {
                display: block;
                margin: 0.2rem 0 1.2rem;
            }
        }
          
    }

    &__brief {
        padding: 3.7rem 0 5rem;

        @include medium {
            padding: 0;
            flex: 1 1 auto;
            margin: 0 0 1.8rem 3.5rem;
        }

        @include large {
            margin: 0 0 1.8rem 9.7rem;
        }
    }

    &__service {
        line-height: 2.2rem;
        padding: 3.7rem 0 2.7rem;
        margin-right: -2rem;

        @include medium {
            padding: 0;
            margin-right: 0;
            flex: 1 1 auto;
            order: 1;
        }

        @include large {
            white-space: nowrap;
        }

        &-link {
            display: inline-block;
            vertical-align: top;
            margin: 0 2rem 1rem 0;

            @include medium {
                margin: 0 1.4vw 0 0;
            }
        }
    }

    &__copyright {
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.3rem;
        letter-spacing: 0.04rem;
        color: rgba($brandColor2, 0.25);
        padding: 1.3rem 0 3.5rem;

        @include medium {
            padding: 0;
            order: 2;
            text-align: right;
        }
    }

    &__social {
        font-size: 0;
        line-height: 0;
        white-space: nowrap;
        padding: 4rem 0 0;

        @include medium {
            padding: 0;
            order: 3;
        }
        
        &-link {
            display: inline-block;
            vertical-align: middle;
            // margin-right: 3rem;
            margin: 0 3rem 0 0;

            @include medium {
                margin: 0 0 0 2vw;
            }

            html.mobile & {
                background-color: $brandColor2;
            }

            html.no-mobile & {
                @include fluxBg;
            }

            img {
                display: block;
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }
}

// isMobile library CSS override 

html.no-mobile #{$ns}__social-link:not(:hover) {
    background: $brandColor2;
}