$ns: ".work-single";

#{$ns} {
    padding-top: 14rem;

    @include medium {
        border-top: 0.1rem solid rgba($brandColor9, 0.25);
    }  

    h1 {
        font-weight: 600;
        color: $black;
        padding: 0;
        margin: 0;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: 0.027rem;

        @include medium {
            font-size: 3.8rem;
            line-height: 5.1rem;
            letter-spacing: 0.03rem;
        }

        @include large {
            font-size: 4.5rem;
            line-height: 5.1rem;
            letter-spacing: 0.035rem;
        }

        @include xxlarge {
            font-size: 6rem;
            line-height: 1.09em;
            letter-spacing: 0.047rem;
        }
    }

    h2 {
        font-family: $Ogg;
        font-weight: normal;
        color: $brandColor1;
        padding: 0;
        margin: 0;
        font-size: 2.8rem;
        line-height: 3.1rem;

        @include medium {
            font-size: 3.4rem;
            line-height: 5.2rem;
        }

        @include large {
            font-size: 4rem;
            line-height: 1.3em;
        }

        + h4 {
            padding-top: 0.2rem;

            @include medium {
                padding-top: 0.6rem;
            } 
        }   
    }

    h3 {
        font-weight: 500;
        color: $black;
        padding: 0;
        margin: 0;
        font-size: 2.4rem;
        line-height: 3.1rem;

        @include medium {
            font-size: 2.8rem;
            line-height: 3.8rem;
        }

        @include large {
            font-size: 3.2rem;
            line-height: 4.2rem;
        }

        @include xxlarge {
            font-size: 4rem;
            line-height: 5rem;
        }
    }

    h4 {
        font-weight: 300;
        color: $brandColor4;
        padding: 0;
        margin: 0;
        font-size: 2.8rem;
        line-height: 3.2rem;

        @include medium {
            font-size: 3.4rem;
            line-height: 4.1rem;
        }

        @include large {
            font-size: 4rem;
            line-height: 1.3em;
        }

        img {
            display: inline-block;
            vertical-align: middle;
        }
    }

    h5 {
        font-weight: 600;
        color: $black;
        padding: 0;
        font-size: 1.8rem;
        line-height: 2.7rem;
        margin: 2.7rem 0;

        @include medium {
            font-size: 2.2rem;
            line-height: 1.636364em;
            margin: 1.7em 0;
        }
    }

    &__header {
        margin-bottom: 8rem;

        @include large {
            margin-bottom: 12.2rem;
        }

        .container {
            @include large {
                display: flex;
            }
        }

        &-main {
            @include large {
                flex: 1 1 auto;
                padding-right: 5rem;
            }

            @include xlarge {
                padding-right: 2.5rem;
            }

            .headline {
                margin-bottom: 1.5rem;
            }
        }

        &-aside {
            flex: 0 0 auto;
            padding-left: 1.1rem;
            display: none;
            width: 15.8rem;

            @include large {
                display: initial;
            }

            @include xlarge {
                width: 20.2rem;
            }

            &:last-child {
                display: none;

                @include xlarge {
                    display: initial;
                }
            }

            &-block {
                & + & {
                    margin-top: 4.7rem;
                }

                .headline {
                    margin-bottom: 1.3em;
                }

                img {
                    display: block;
                    margin-top: 1.9rem;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }

                p {
                    font-size: 1.5rem;
                    line-height: 2.1rem;
                    color: $brandColor3;
                    margin-bottom: 2.8rem;

                    a {
                        color: currentColor;

                        &:not(:last-child) {
                            &:after {
                                display: none;
                            }

                            &:hover ~ a:last-child:after {
                                @include fluxText;
                                transform: translateX(0.2rem);
                            }
                        }

                        &:after {
                            color: $brandColor10;
                        }
                    }
                }
            }
        }
    }

    &__text {
        @include large {
            display: flex;
        }

        @include xlarge {
            padding: 0 7.8%;
        }

        &-column {
            position: relative;

            @include large {
                width: 50%;
            }

            @include xlarge {
                box-sizing: border-box;
                padding: 0 0.7rem;
            }

            ul {
                padding-left: 2rem;
            }

            &:nth-child(1) {
                margin-bottom: 4.5rem;

                @include medium {
                    margin-bottom: 3.7rem;
                }

                @include large {
                    padding-right: 0.5rem;
                    margin-bottom: 0;
                }

                @include xlarge {
                    padding-right: 0;
                }

                #{$ns}__text-triangle {
                    right: 6rem;
                    bottom: -0.2rem;

                    @include medium {
                        right: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 100%;
                        margin: 0 0 0.8rem 0.7rem;
                    }

                    @include large {
                        bottom: initial;
                        margin: 7.9rem 0 0 -1.5rem;
                        top: 100%;
                    }

                    @include xlarge {
                        margin: 11.4rem 0 0 0;
                    }  
                }
            }

            &:nth-child(2) {
                @include medium {
                    padding: 1.3rem 0 0 0;
                }

                @include large {
                    padding: 1.3rem 0 0 0.5rem;
                }

                @include xlarge {
                    padding: 1.3rem 0 0 0;
                }

                #{$ns}__text-triangle {
                    left: 0;
                    display: none;
                    top: 100%;
                    margin: 9.6rem 0 0 0.1rem;

                    @include medium {
                        display: initial;
                    }

                    @include large {
                        top: 0;
                        margin: 0 0 1.8rem -0.3rem;
                        bottom: 100%;
                    }

                    @include xlarge {
                        margin: 0 0 1.8rem 0.6rem;
                    }
                }
            }

            h2 {
                @include large {
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                }
            }

            h4 {
                &:last-child {
                    margin-bottom: -1.5rem;

                    @include medium {
                        margin-bottom: 0;
                    }
                }
            }

            h5 {
                &:first-child {
                    margin-top: 0 !important;
                }
            }

            p {
                letter-spacing: 0.01rem;
                margin: 2.7rem 0;

                @include medium {
                    margin: 1.8em 0;
                }
  
                b {
                    color: $black;
                    font-weight: 600;
                }
            
                &:first-of-type {
                    margin-top: 0 !important;
                }
            
                &:last-of-type {
                    margin-bottom: 0 !important;
                }
            }

        }

        &-decorator {
            position: absolute;
  
            img {
                display: block;
            }
          
            &.-top-left {
                bottom: 100%;
                left: 0;
                display: none;
                margin: 0 0 7.8rem 0.5rem;

                @include medium {
                    display: initial;
                }

                @include large {
                    margin: 0 0 11.2rem 0.2rem;
                }

                @include xlarge {
                    margin: 0 0 11.2rem 1.6rem;
                }
            
                + h2 {
                    height: 100%;
                }

                img {
                    width: 24.4rem;

                    @include large {
                        width: initial;
                    }
                }   
            }
          
            &.-top-right {
                bottom: 100%;
                right: 60%;
                margin-bottom: 12.1rem;

                @include medium {
                    right: -0.3rem;
                    margin: 0 0 11.1rem 0;
                }

                @include large {
                    right: -0.4rem;
                    margin: 0 0 3.7rem 0;
                }

                @include xlarge {
                    left: 50%;
                    right: 0;
                    transform: translateX(-50%);
                    margin: 0 0 3.7rem 1.4rem;
                }

                img {
                    width: 19rem;

                    @include medium {
                        width: 20.6rem;
                    }

                    @include large {
                        width: initial;
                    }
                }
            }

            &.-bottom {
                left: 2.4rem;
                bottom: 4.8rem;

                @include medium {
                    left: 36.3%;
                    bottom: 12.5rem;
                }

                @include large {
                    left: 42.3%;
                    bottom: 15rem;
                }

                @include xxlarge {
                    left: initial;
                    bottom: initial;
                    right: 7.8%;
                    top: 100%;
                    margin: 9.2rem 1.8rem 0 0;
                }

                img {
                    width: 54rem;

                    @include medium {
                        width: 65.4rem;
                    }

                    @include large {
                        width: initial;
                    }
                }
            }
        }

        &-triangle {
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            border-width: 0.7rem 0.7rem 0 0.7rem;

            @include large {
                border-width: 0.9rem 0.9rem 0 0.9rem;
            }
        }
    }

    &__quote {
        &-text {
            font-family: $Ogg;
            margin: 0 auto;
            width: 97%;
            font-size: 2.8rem;
            line-height: 3.8rem;

            @include medium {
                font-size: 4.4rem;
                line-height: 6rem;
            }

            @include xxlarge {
                font-size: 4.9rem;
                line-height: 6.7rem;
            }  
        }

        &-cite {
            font-size: 1.4rem;
            line-height: 1.5rem;

            @include medium {
                font-size: 1.7rem;
                line-height: 2.6rem;
            }

            b {
                font-weight: 600;
                position: relative;
                top: 1.6rem;
            
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0.3rem;
                    right: 100%;
                    height: 0.1rem;
                    background-color: currentColor;
                    width: 1.8rem;
                    margin: 0 0.8rem 0.1rem 0;

                    @include medium {
                        width: 3.1rem;
                        margin: 0 2rem 0.2rem 0;
                    }
                }
            }

        }
    }

    &__outcomes {
        margin: 10.1rem 0 8.3rem;

        @include medium {
            margin: 10.4rem 0 9.7rem;
        }

        @include large {
            margin: 26.6rem 0 22.8rem;
        }

        @include xlarge {
            margin: 26.6rem 0 22.5rem;
        }

        .container {
            @include large {
                display: flex;
                justify-content: space-between;
            }

            @include xlarge {
                padding-left: 7.3%;
            }

            @include xxlarge {
                padding-left: 10.5rem;
            }
        }

        &-content {
            padding-top: 4.9rem;

            @include medium {
                padding-top: 5.6rem;
            }

            @include large {
                display: flex;
                flex-wrap: wrap;
                padding-top: 2.2rem;
                width: 49.4%;
            }

            @include xlarge {
                width: 72.4%;
            }
        }

        &-block {
            font-size: 1.7rem;
            line-height: 2.1rem;
            box-sizing: border-box;
            padding: 0 0 3.3rem 0;

            @include large {
                padding: 0 2.5rem 5.2rem 0;

                &:nth-child(2n-1) {
                    width: 61.8%;
                }
                
                &:nth-child(2n) {
                    width: 38.2%;
                    padding-right: 0;
                }
            }

            @include xlarge {
                width: 38%;

                &:nth-child(3n) {
                    width: 24%;
                    padding-right: 0;
                } 

                &:nth-child(2n-1) {
                    width: 33.3%;
                }
                
                &:nth-child(2n) {
                    width: 33.3%;
                    padding-right: 0;
                }
            }

            &-title {
                color: $brandColor1;
                padding-bottom: 0.3rem;

                  
                .ico {
                    display: inline-block;
                    vertical-align: baseline;
                
                    &.-star {
                        width: 1.1rem;
                        height: 1.1rem;
                        margin-right: -0.1rem;
                    
                        &:first-child {
                            margin-left: 0.2rem;
                        }
                    }
                }
            }

            &-value {
                color: $brandColor3;

                a {
                    color: currentColor;
                
                    &:not(:last-child) {
                        &:after {
                            display: none;
                        }
                
                        &:hover ~ a:last-child:after {
                            @include fluxText;
                            transform: translateX(0.2rem);
                        }
                    }
                
                    &[data-with-arrow]:after {
                        color: $brandColor10;
                    }
                }
            }
              
        }
    }

    &__afterwords {
        border-top: 0.1rem solid rgba($brandColor9, 0.25);

        .container {
            width: 100%;

            @include large {
                width: initial;
                display: flex;
            }
        }

        &-block {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            height: 28rem;
            padding-bottom: 1rem;

            @include medium {
                height: 34.9rem;
                padding-bottom: 3.4rem;
            }

            @include large {
                width: 50%;
            }

            @include xlarge {
                height: 50rem;
            }

            &:first-child {
                .afterword {
                    @include xlarge {
                        padding-left: 17.6%;
                    }

                    @include xxlarge {
                        padding-left: 0;
                    }
                }
            }

            & + & {
                border-style: solid;
                border-color: rgba($brandColor9, 0.25);
                border-width: 0.1rem 0 0;

                @include large {
                    border-width: 0 0 0 0.1rem;
                }
                
                .afterword {
                    @include large {
                        padding-left: 23.4%;
                    }

                    @include xxlarge {
                        padding-left: 26%;
                    }
                }
            }  
        }

        .afterword {
            padding: 0 5.6%;

            @include medium {
                padding: 0 30.2%;
            }

            @include large {
                padding: 0 0 0 17.6%;
            }

            @include xxlarge {
                padding: 0 0 0 26%;
            }

            &__heading,
            &__subheading {
                font-size: 3rem;
                line-height: 4rem;

                @include medium {
                    font-size: 4rem;
                    line-height: 5.2rem;
                }

                .complex-link__line {
                    height: 0.2rem;
                    top: 95%;
                }
            }

            &__links {
                a {
                    margin-right: 2rem;
                }
            }
        }
    }

    &__header-byline {
        font-weight: 400 !important;
    }

    &-contact {
        padding: 5.5rem 0rem;
        width: 100% !important;
        text-align: center;
        padding-bottom: 6.8rem;
        text-align: left;

        @include medium {
            padding-bottom: 0;
            text-align: initial;
        }

        @include xxlarge {
            margin: 10rem 0rem 0rem 0rem;
        }

        &__grid {
            padding: 5.5rem 0rem;
            width: 100% !important;
            text-align: center;

            &-bond {  
                padding: 1rem 0rem;
                
                @include medium {
                    padding: 0;
                }
            }

            &__byline {
                width: 30rem;
                margin: 0 2.1rem;

                @include medium {
                    margin: 0 auto;
                }
            }
        }

        &__block {
            height: 35rem !important;
        }

        &__heading {
            width: 30rem;
            margin: 0 auto;

            &__app {
                padding-left: 0 !important;
            }
        }
    }

    &__apps {
        @include xxlarge {
            border-top: 0.1rem solid rgba($brandColor9, 0.25);
        }
    }

    &__dynamic {
        display: block;
        width: 100%;
        height: auto;

        @include xxlarge {
            display: initial;
            width: initial;
            height: initial;
    
        }
    }

    .section-gradient h3 {
        color: $brandColor2;
    }
}