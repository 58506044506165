.section {
    margin-bottom: 8.2rem;

    @include medium {
        margin-bottom: 13.6rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.3rem;

        &-integrated {
            display: flex;
            margin-bottom: 2.3rem;
            justify-content: center;
            font-size: 4rem;
            line-height: 4rem;

            @include medium {
                display: flex;
                margin-bottom: 2.3rem;
                justify-content: center;
                font-size: 4.5rem;
                line-height: 0;
            }   
        }
    }

    &__footer {
        padding-top: 2.2rem;

        @include medium {
            position: absolute;
            top: 0.8rem;
            right: 0;
            padding-top: 0;
        }
    }
}
  
