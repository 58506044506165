$graphPrimary: #f6f6f6;
$graphSecondary: #d2d2d2;

.graph {
    text-align: center;
    padding: 0.6rem 0;

    @include medium {
        padding: 4.3rem 0;
    }

    h2 {
        font-size: 2.5rem;
        line-height: 3rem;

        @include medium {
            font-size: initial;
            line-height: initial;
        }
    }
  
    > p {
        font-weight: 500;
        color: $brandColor6;

        @include medium {
            font-size: 2.4rem;
            line-height: 3.6rem;
            padding: 1.4rem 0 3.4rem;
        }
    }

    &__body {
        background-color: $graphPrimary;
        margin: auto;
        max-width: 105.7rem;
        box-sizing: border-box;
        padding: 3.6rem 5rem 2.2rem 2rem;

        @include medium {
            padding: 6.4rem 8.8rem 2.5rem 5.5rem;
        }

        &-chart {
            position: relative;
            margin-bottom: 4.8rem;

            @include medium {
                margin-bottom: 7.2rem;
            }
          
            p {
                font-weight: 500;
                line-height: 1;
                color: $brandColor6;
                font-size: 1.3rem;

                @include medium {
                    font-size: 2rem;
                }
            }

            &-abscisses {
                &-line {
                    position: relative;
                    height: 0.1rem;
                    background-color: $graphSecondary;

                    & + & {
                        margin-top: 5.9rem;

                        @include medium {
                            margin-top: 8.5rem;
                        }
                    }

                    p {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        margin: -0.8rem 0 0 1.2rem;

                        @include medium {
                            margin: -1.4rem 0 0 1.8rem;
                        }  
                    }
                    
                }
            }

            &-ordinates {
                position: absolute;
                top: 100%;
                display: flex;
                justify-content: space-between;
                left: 0.1rem;
                right: -3rem;
                margin-top: 2rem;

                @include medium {
                    left: 0.2rem;
                    right: -0.8rem;
                    margin-top: 2.3rem;
                }
            }

            &-drawing {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              
                path {
                  stroke-width: 3;
                }

                &-population {
                    stroke: $brandColor1;
                }

                &-devices {
                    stroke: url("#fluxGradient");
                }  
            }
        }

        &-legend {
            display: flex;
            justify-content: center;
            white-space: nowrap;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 1.8rem;
            margin: 0 -5rem 0 -2rem;

            @include medium {
                font-size: 2rem;
                line-height: 3rem;
                margin: 0;
            }

            &-item {
                & + & {
                    &:before {
                        content: "";
                        display: inline-block;
                        vertical-align: top;
                        width: 0.1rem;
                        background-color: $graphSecondary;
                        position: relative;
                        top: -0.2rem;
                        height: 2rem;
                        margin: 0 1.1rem;

                        @include medium {
                            height: 3rem;
                            margin: 0 2.1rem;
                        }
                    }

                    &.-gradient {
                        @include fluxText;
                    }

                    &.-black {
                        color: $brandColor1;
                    }
                }
            }
        }

        &-source {
            font-weight: 500;
            line-height: 1.8rem;
            color: $brandColor6;
            text-align: center;
            margin: 1.7rem -5rem 0 -2rem;
            font-size: 1.1rem;

            @include medium {
                margin: 3.9rem -5rem 0 -2rem;
                font-size: 1.3rem;
            }
        }
    }
}