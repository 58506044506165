$ns: ".rainbow-reveal";

#{$ns} {
    position: relative;
    width: 28rem;
    &.canvas-container {
        padding-bottom: 28rem;
    }
    &.button-container {
        display: flex;
        justify-content: space-between;
    }
    .rainbow-canvas {
        display: block;
        margin: auto;
        position: absolute; 
        left: 0; 
        top: 0; 
        z-index: 0;
    }
    .black-canvas {
        display: block;
        margin: auto;
        position: absolute; 
        left: 0; 
        top: 0; 
        z-index: 1;
        border: 0.1rem solid $white;
    }
    button:disabled {
        cursor: not-allowed;
    }
    button {
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: $white;
        background-color: transparent;
        border: none;
        font-weight: 600;
    }
    button.save-drawing {
        padding-left: 1rem;
    }
    button.submit-drawing.contact-buttons__button {
        height: 2.6rem;
        font-size: 1.3rem;
        text-transform: none;
        letter-spacing: normal;
        padding: 0 1.3rem;
        .contact-buttons__button-text {
            vertical-align: unset;
            font-weight: 500;
        }
        .contact-buttons__button-icon {
            padding-left: 0.6rem;
            margin-bottom: 0.2rem;
            margin-right: 0;
        }
        &:hover path {
            stroke: $white;
        }
    }
    .canvas-message {
        cursor: default;
        position: absolute;
        color: $white;
        z-index: 2;
        left: 7.7rem;
        top: 11.6rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        white-space: nowrap;
        line-height: 2.4rem;
        font-weight: 500;
        letter-spacing: 0.2rem;
        &.success {
            left: 8.9rem;
            text-transform: none;
            letter-spacing: normal;
            font-size: 2rem;
        }
        &.saved {
            left: 10.8rem;
        }
    }
    @keyframes loading-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .loading-ring {
        display: none;
        position: absolute;
        width: 6.6rem;
        height: 6.6rem;
        z-index: 3;
        left: 10.7rem;
        top: 10.7rem;
        &.htmx-request {
            display: inline-block;
        }
        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 5rem;
            height: 5rem;
            margin: 0.8rem;
            border: 0.6rem solid $white;
            border-radius: 50%;
            animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: $white transparent transparent transparent;
            &:nth-child(1) {
                animation-delay: -0.45s;
            }
            &:nth-child(2) {
                animation-delay: -0.3s;
            }
            &:nth-child(3) {
                animation-delay: -0.15s;
            }
        }
    }
}

.rainbow-wrapper {
    hr.show__mobile {
        display: block;
    }
    .footer__main-drawings {
        display: none;
        width: 100%;
        &.show__mobile {
            display: block;
            padding-bottom: 6rem;
        }
        img {
            width: 8rem;
            height: 8rem;
            padding-left: 2.4rem;
            padding-right: 2.4rem;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        @include medium {
            padding-top: 0.2rem;
        }
    }
    .footer__main-glyphs {
        display: block;
        padding-bottom: 4rem;
        width: 100%;
        #{$ns} {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .footer__main {
        flex-wrap: wrap;
        display: block;
    }
    @include xlarge {
        .footer__main-glyphs {
            width: unset;
            padding-bottom: 0;
        }
        .footer__main-drawings {
            display: block;
            &.show__mobile {
                display: none;
            }
        }
        .footer__main {
            flex-wrap: unset;
            display: flex;
            justify-content: space-between;
        }
        hr.show__mobile {
            display: none;
        }
    }
}
