$ns: ".work-tile-big";

#{$ns} {
    display: block;
    margin-bottom: 3.5rem;

    :last-child > & {
        margin-bottom: 0;
    }

    @include medium {
        margin-bottom: 6.4rem;

        :nth-last-child(1) > &,
        :nth-last-child(2) > & {
            margin-bottom: 0.7rem;
        }
    }

    &__cover {
        display: block;
        position: relative;
        overflow: hidden;
        margin-bottom: 1.2rem;

        @include medium {
            margin-bottom: 1.1rem;
        }
      
        &:before {
            content: "";
            display: block;
            padding-top: 73%;

            @include medium {
                padding-top: 73.131955%;
            }
        }
      
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0 solid $brandColor8;
            box-sizing: border-box;
            transition: border-width $speed * 2;
            will-change: border-width;
        }

        &-image {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: 50% 50%;
            transition: transform $speed * 2;

            .work-slider__scrollable-slides:not(.aos-animate) &,
            .grid__row:not(.aos-animate) & {
                background: none !important;
            }

        }
    }

    &__details {
        display: block;
        transition: transform $speed;
    } 

    &__headline {
        display: block;
        font-weight: 500;
        line-height: 3.1rem;
        color: $brandColor1;
        font-size: 1.7rem;
        margin-bottom: 0.4rem;

        @include medium {
            font-size: 2rem;
            margin-bottom: 0.7rem;
        }
    }

    &__desc {
        display: block;
        font-weight: 500;
        color: $brandColor6;
        font-size: 1.3rem;
        line-height: 2rem;

        @include medium {
            font-size: 1.7rem;
            line-height: 2.5rem;
        }
    }
}

// isMobile library CSS override 

html.no-mobile #{$ns}:hover #{$ns}__cover:after {
    border-width: 1.5rem;
}

html.no-mobile #{$ns}:hover #{$ns}__cover-image {
    transform: scale(1.03);
}

html.no-mobile #{$ns}:hover #{$ns}__details {
    transform: translateX(1.5rem);
}
