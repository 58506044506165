$ns: ".anniversary-post";

#{$ns} {
    .container {
        width: 100%;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
        min-height: calc(100vh - 15.1rem);
    }

    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        flex-direction: row;

        &.text__only {
            display: none;
        }

        &.reverse {
            flex-direction: row-reverse;

            button.contact-buttons__button.button__timeline {
                margin-right: 3rem;
                transform: translateX(0);
            }
        }
    }

    .main__timeline {
        .row {
            position: relative;
            border-top: 1px solid $black;
            margin-top: 15.1rem;
            //margin-left: 4.1rem;
        }
    }

    .half-page {
        transition: all 500ms linear;
        position: relative;
        width: 100%;
        min-height: 64.9rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;

        &.text-container {
            -ms-flex: 0 0 60%;
            flex: 0 0 60%;
            max-width: 60%;
            padding-left: 0;
            max-width: 847px;
            height: calc(100vh - 15.1rem);

            &.included-in-timeline {
                border-left: .1rem solid $black;
                padding-left: 2.4rem;

                button.btn-animated {
                    padding-bottom: 5rem;
                }

                .button__container {
                    //display: none;
                }

                button.contact-buttons__button.button__timeline.included-in-timeline {
                    opacity: 0;
                }

                &.slick-active {
                    padding-left: 0;
                    border-left: none;

                    button.contact-buttons__button.button__timeline {
                        display: inline-block;
                        opacity: 1;
                    }
                }
            }
        }

        &.timeline__section {
            padding-left: 0;
            left: 9rem;

            .slick-list {
                transform: translateX(0);
                transition: transform .3s linear;
            }

            .first-slide {
                .slick-list {
                    transform: translateX(-4.9rem);
                    transition: transform .3s linear;
                    overflow: hidden;
                }

                button.contact-buttons__button.button__timeline.included-in-timeline {
                    opacity: 1;
                    z-index: 20;
                }

                .first-timeline-item {

                    .timeline__snippet h4,
                    .timeline__snippet p {
                        opacity: 1;
                    }

                    .timeline-info {
                        button.scroll__bar {
                            background: $black;
                        }
                    }
                }

                &.timeline-slider {
                    .timeline-icon.first-timeline-item {
                        .year-button__container {
                            .year__button {
                                background: $black;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .small-text {
        padding-top: 2.4rem;
        width: 96%;
    }

    .small-text p {
        font-size: 1.6rem;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .sr-only {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: .1rem;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: .1rem;
    }

    .prev__button div,
    .next__button div {
        width: 74px;
        height: 74px;
        background-color: $white;
        box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slick-arrow {
        height: 118px;
        background: transparent;
        border: none;
        position: absolute;
        bottom: 0;
        top: 92%;
        z-index: 18;
        display: none !important;

        &.prev__button {
            left: -6rem;
        }

        &.next__button {
            // right: 19.4rem;
            right: calc(100vw - 6rem);
        }
    }

    .section-gradient {
        &.hero-gradient {
            &:first-child {
                .section-gradient__container {
                    padding-top: 2.4rem;
                    padding-bottom: 3rem;
                    height: 100%;
                    position: relative;

                    h1 {
                        font-weight: 500;
                    }
                }
            }

            background: $white;
            color: $black;
            animation: none;
        }
    }

    button.btn-animated {
        position: relative;
        background: transparent;
        top: -.6rem;
        border: none;
        transition: transform .8s linear;

        .drop__shadow {
            width: 7.2rem;
            height: 7.2rem;
            box-shadow: 0px 4px 23px rgb(0 0 0 / 10%);
            position: absolute;
            left: 4.9rem;
            top: 0.7rem;
            border-radius: 50%;
        }

        svg {
            position: absolute;
            left: 7.3rem;
            top: 2.7rem;
            right: 0;
            transition: all .8s;
        }
    }

    .button__container {
        padding-top: 4.5%;
        display: flex;
        position: absolute;
        bottom: 12.5rem;
        width: 100%;
        z-index: 20;
    }

    button.contact-buttons__button {
        &.button__timeline {
            margin-right: -3rem;
            max-width: none;
            margin-left: auto;
            display: inline-block;
            background: none;
            border: none;
            text-transform: none;
            height: 7.5rem;
            padding-left: 3.5rem;
            padding-right: 0;
            transition: all 1s;
            z-index: 20;
            margin-top: 0;

            &::before {
                transition: all 1s;
                background: transparent;
            }

            &:hover {
                color: inherit !important;
            }

            .contact-buttons__button-text {
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 2.4rem;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                transition: all .8s;
                margin-bottom: 0.6rem;
                font-size: 1.4rem;
                margin-right: 0;
                margin-left: .6rem;
                font-weight: 600;
            }

            .contact-buttons__button-icon {
                margin-left: 1rem;
                width: 7.4rem;
                height: 7.4rem;
                transition: all .8s;
                margin-bottom: 0.6rem;
                background: $white;
                box-shadow: 0rem .4rem 2.3rem rgba(0, 0, 0, 0.1);
                border-radius: 50%;

                svg {
                    margin: auto;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        }
    }

    .circle__anim {
        position: absolute;
        left: 3.5rem;
        right: 0;
        top: -1.2rem;
        margin: 0 auto;
        width: 10rem;
        height: 11rem;
        border-radius: 50%;
        font-weight: 600;
        color: $black;
        text-align: center;
        transform: rotateZ(-.25turn);
        animation: resetZ 2s linear;

        span {
            position: absolute;
            display: inline-block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            text-transform: uppercase;
            font-size: 1.1rem;
            transition: all .5s cubic-bezier(0, 0, 0, 1);
        }

        @keyframes spinZ {
            0% {
                transform: rotateZ(-.25turn);
            }

            100% {
                transform: rotateZ(-.5turn);
            }
        }

        @keyframes resetZ {
            0% {
                transform: rotateZ(-.5turn);
            }

            100% {
                transform: rotateZ(-.25turn);
            }
        }
    }

    .btn-animated:hover,
    .btn-animated.animate {
        .circle__anim {
            animation: spinZ 2s linear;

            @media (prefers-reduced-motion: reduce) {
                animation: none;
            }
        }
    }

    .btn-animated.resetting {
        .circle__anim {
            animation: resetZ 2s linear;

            @media (prefers-reduced-motion: reduce) {
                animation: none;
            }
        }
    }

    .timeline-slider {
        &.slick-slider {
            height: calc(100vh - 27.4rem);
        }

        .slick-track {
            display: flex;
        }

        .slick-slide {
            height: inherit;
        }

        .timeline-icon {
            overflow: visible;
            padding-top: 0;
            border-left: none;
            border-top: none;
            transition: all .8s;
            height: calc(100vh - 17.5rem);
            width: 66vh;
            background-color: $white;

            .year-button__container {
                border-bottom: 1px solid $black;
                border-left: 1px solid $black;

                .year__button {
                    transition: 500ms linear .5s;
                    width: fit-content;
                    width: max-content;
                    padding: 0rem 2.4rem;
                    border-right: .1rem solid $black;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    background: transparent;
                }
            }

            button.image__container {
                background: transparent;
                border: none;
                width: 100%;
                height: 74%;
                padding: 0 2.4rem;
                display: block;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .content__container {
                border-left: .1rem solid $black;
                height: calc(100% - 2.7rem);
                border-bottom: .1rem solid $black;
                overflow: visible;
            }

            &.slick-current {
                .year__button {
                    background: $black;
                    color: $white;
                }
            }
        }

        div.timeline-icon:last-of-type {
            border-right: .1rem solid $black;
        }
    }

    .timeline__nav {
        list-style: none;
        display: flex;
        padding-left: 0;
        margin: 0;
        margin-top: -27px;
        z-index: 5;
        bottom: 0;
        position: absolute;
        left: 0;

        li {
            width: 44.5rem;

            button.timeline__scroll {
                transition: 500ms linear 0s;
                width: 100%;
                background: $white;
                height: .9rem;
                border: none;
                border-top: 1px solid $black;
                border-left: 1px solid $black;
                border-bottom: 1px solid $black;
            }

            &:not(.slick-active) {
                button.timeline__scroll {
                    height: .9rem;
                    background: $white;
                }
            }
        }
    }

    .timeline__section {
        hr {
            margin-left: 1.6rem;
            margin-right: 1.6rem;
            border: none;
            height: .1rem;
            background-color: $black;
        }

        .timeline__snippet {
            position: relative;

            h4,
            p {
                transition: .5s linear 0s;
                opacity: 0;
            }

            &.timeline__active {
                display: block;

                &::after {
                    position: absolute;
                    width: 100%;
                    height: .8rem;
                    background: $black;
                    left: 0;
                    bottom: 0;
                    content: "";
                }
            }
        }

        .slick-active {

            .timeline__snippet h4,
            .timeline__snippet p {
                opacity: 1;
                transition: .5s linear .5s;
            }

            .timeline-info {
                button.scroll__bar {
                    background: $black;
                    transition: .5s linear .5s;
                }
            }
        }

        .timeline-info {
            padding-top: 1.6rem;
            padding-left: 2.4rem;
            padding-right: 2.4rem;
            border-top: .1rem solid $black;
            background: #fff;
            z-index: 10;
            height: calc(26% - 1.8rem);

            button.scroll__bar {
                background: $white;
                border: none;
                border-top: .1rem solid $black;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: -.1rem;
                height: .8rem;
                transition: .5s linear 0s;
            }

            .headline {
                margin-bottom: .8rem;
            }

            p {
                font-size: 1.6rem;
                line-height: 140%;
                letter-spacing: 0.01em;
            }

            .buttons {
                margin-bottom: 3.8rem;
                margin-top: 1.4rem;
                display: flex;

                button {
                    border: .1rem solid $black;
                    transition: all .15s;
                }

                .icon__button {
                    margin-left: 1.2rem;
                    margin-top: 1rem;
                    background: transparent;
                    width: 4.4rem;
                    height: 4.4rem;

                    svg {
                        width: 100%;

                        rect {
                            transition: all .15s;
                        }
                    }

                    &:hover {
                        background: $black;

                        svg {
                            rect {
                                stroke: $white;
                                fill: $white;
                            }
                        }
                    }
                }

                .contact-buttons__button {
                    background: transparent;
                    animation: none;
                    transition: all .15s;
                    max-width: 22.2rem;

                    &:hover,
                    &:focus {
                        background: $black;
                        color: $white;

                        .contact-buttons__button-icon {
                            svg path {
                                fill: $white;
                            }
                        }
                    }

                    .contact-buttons__button-icon {
                        margin-left: 0.6rem;
                        margin-right: 0;
                        margin-bottom: 0.6rem;
                    }

                    .contact-buttons__button-text {
                        vertical-align: unset;
                        font-size: 1.6rem;
                        letter-spacing: 0.2rem;
                    }
                }
            }
        }
    }

    .marquee__and__snippet {
        position: relative;
        top: 1px;
        height: 16vh;

        .image__marquee {
            height: 143px;
            position: absolute;
            top: 0;
            left: 0;
            width: max-content;
            overflow-x: hidden;
            display: flex;
            align-items: center;

            .scroll {
                width: 130px;
                animation: scroll-left 10s linear infinite;
            }

            img {
                max-height: 100%;
            }
        }
    }

    @keyframes scroll-left {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-135px);
        }
    }

    .show-full-timeline {
        .half-page.text-container {
            &.included-in-timeline {
                .button__container {
                    display: flex;
                }
            }
        }

        .slick-list {
            overflow: visible;
        }

        button.contact-buttons__button.button__timeline:not(.included-in-timeline) {
            transform: translateX(-100vw);
        }

        .half-page {
            &.timeline__section {
                max-width: 100%;
                flex: 0 0 100%;
                //transform: translateX(-50vw);
            }

            &.text-container:not(.included-in-timeline) {
                overflow: hidden;
                flex: 0 0 0%;
                max-width: 0%;
                padding: 0;

                .section-gradient__headlines {
                    display: none;
                }
            }
        }

        .reverse {
            .half-page {
                &.timeline__section {
                    flex: 0 0 40%;
                    max-width: 40%;
                    left: 0;
                }

                &.text-container {
                    flex: 0 0 60%;
                    max-width: 60%;
                }
            }

            .slick-arrow.next__button {
                transform: translateX(100vw);
            }
        }

        &.main__timeline {
            .row {
                border-top: none;
            }

            .timeline-slider {
                border-left: none;

                .slick-list {
                    .slick-track {
                        border-top: 1px solid $black;
                    }
                }
            }

            .timeline__section {
                .timeline__snippet {
                    &.timeline__active {
                        margin-left: -0.1rem;
                    }
                }
            }
        }

        .slick-arrow {
            display: inline-block !important;
        }

        .first-slide {
            .slick-arrow {
                display: none !important;
            }
        }
    }

    @media(max-height: 794px) and (min-width: 1399px) {
        .slick-arrow {
            top: 48rem;
        }

        .timeline-slider {
            .timeline-icon {
                min-width: 49.4rem;
                min-height: 62.5rem;
            }
        }
    }

    .mobile,
    .mobile__button {
        display: none;
    }

    .timeline-slider.mobile__only__timeline {
        display: none;
    }

    @media(max-width: 1400px) {
        .included-in-timeline {
            display: none !important;
        }

        .timeline-slider:not(.mobile__only__timeline) {
            display: none;
        }

        .timeline-slider.mobile__only__timeline {
            display: block;
        }

        .half-page.timeline__section {
            left: 0;
        }

        .show-full-timeline {
            .mobile__button {
                transform: translateX(-100%);
            }

            .half-page.timeline__section {
                transform: translateX(-19.8rem);
                border-top: none;

                .first-slide {
                    .slick-list {
                        transform: translateX(0);
                    }
                }
            }

            .half-page.text-container:not(.included-in-timeline) {
                display: block;
                flex: 0 0 100%;
                max-width: 100%;
                overflow: visible;

                .section-gradient__headlines {
                    display: block;
                }
            }
        }

        .slick-arrow {
            top: 45.5%;

            &.next__button {
                left: 83vw;
            }

            &.prev__button {
                left: -1.4rem;
            }
        }

        .small-text.mobile {
            padding-top: 0;
            width: 100%;
            padding-bottom: 2.4rem;

            p {
                line-height: 2.2rem;
            }
        }

        button.contact-buttons__button.button__timeline {
            margin-right: 0;
            padding-left: 0;

            .contact-buttons__button-text {
                text-align: left;
                margin-left: 0;
            }

            .contact-buttons__button-icon {
                margin-left: 0;
                margin-right: auto;
                display: block;
                width: 5rem;
                height: 5rem;

                svg {
                    width: 1.9rem;
                    height: 2.2rem;
                }
            }
        }

        .mobile,
        .mobile__button {
            display: block;
        }

        .mobile__button {
            -ms-flex: 0 0 19.4rem;
            flex: 0 0 19.4rem;
            max-width: 19.4rem;
            height: fit-content;
            height: max-content;
            margin-top: auto;
            margin-bottom: 5rem;
        }

        .container {
            min-height: unset;
            padding: 0;
        }

        .button__container {
            bottom: unset;
            padding-top: 2.8rem;
        }

        button.btn-animated {
            .drop__shadow {
                left: 1.4rem;
                width: 5rem;
                height: 5rem;
            }

            svg {
                left: 3.2rem;
                top: 2.2rem;
                width: 1.5rem;
                height: 1.9rem;
            }

            .circle__anim {
                left: -1.2rem;
                top: -1.05rem;
                height: 8.6rem;
            }
        }

        .main__timeline {
            .row {
                margin-left: 2.4rem;
                flex-wrap: wrap;
                border-top: none;
                margin-top: 6.9rem;
            }
        }

        .half-page {
            &.mobile {
                margin-right: 2.4rem;
            }

            min-height: unset;

            &.text-container,
            &.timeline__section {
                flex: 0 0 100%;
                max-width: 100%;
            }

            &.text-container {
                height: unset;

                .small-text:not(.mobile) {
                    display: none;
                }
            }

            &.timeline__section {
                border-top: .1rem solid $black;
                padding-bottom: 2.4rem;
                display: flex;

                .timeline-info {
                    height: calc(40% - 1.8rem);
                }
            }
        }

        button.contact-buttons__button.button__timeline:not(.mobile) {
            display: none;
        }

        .section-gradient__headlines {
            padding-bottom: 9.5rem;
        }

        .timeline-slider {
            &.slick-slider {
                height: unset;
            }

            .timeline-icon {
                height: 50.1rem;
                width: 33.4rem;

                button.image__container {
                    height: 60%;
                }
            }
        }

        .row {
            &.text__only {
                display: block;
                margin-top: 0;
                margin-right: 2.4rem;
            }
        }
    }

    @media(max-width: 500px) and (max-height: 800px) {
        .timeline-slider {
            .timeline-icon {
                height: 34.1rem;
                width: 23.6rem;

                button.image__container {
                    height: 49%;
                }
            }
        }

        .half-page.timeline__section {
            .timeline-info {
                height: calc(51% - 1.4rem) !important;
            }
        }
    }

    @media(max-width: 500px) {
        .show-full-timeline {
            .slick-arrow {
                display: none !important;
            }
        }

        .half-page.timeline__section {
            .timeline-info {
                height: calc(40% - 1.4rem);
                padding-left: 2rem;
                padding-right: 2rem;
                padding-top: 1.2rem;

                h4 {
                    font-size: 1.6rem;
                    line-height: 2rem;
                }

                p {
                    font-size: 1.4rem;
                    line-height: 1.7rem;
                }

                .headline {
                    margin-bottom: .5rem;
                }
            }
        }
    }
}