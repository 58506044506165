$ns: ".search__page";

#{$ns} {
    .justify-center {
        justify-content: center;
    }
    .search__form {
        width: 100%;
        position: relative;
        margin: auto;
        transition: opacity 0.6s 0.75s;
        padding-bottom: 22px;
    }
    .search__form-input {
        height: 50px;
        position: relative;
        input {
            display: block;
            width: 100%;
            height: 100%;
            background: none;
            border: none;
            font: 500 17px/24px Sharp Sans No1, Arial, sans-serif;
            color: #fff;
            box-sizing: border-box;
            padding-right: 100px;
        }
    }
    .search__form-input-border {
        position: absolute;
        z-index: 4;
        bottom: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 1px;
        background-color: #fff;
        transition: height 0.45s;
    }
    .search__form-input-border-gradient {
        background: linear-gradient(270deg, #0369fb 0, #72da0e 16.67%, #f7c233 33.34%, #ee7e33 50.01%, #e93f33 66.68%, #a552f2 83.35%, #0369fb);
        background-size: 2000% 400%;
        animation: gradient 5s linear infinite;
        background-color: #0369fb;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.45s;
    }
    .search__form-control {
        position: relative;
        display: block;
        position: absolute;
        z-index: 3;
        transition-property: visibility, opacity;
        transition-duration: 0.3s;
        top: 18px;
        right: 0;
    }
    .search__form-button {
        border: none;
        background: none;
        font: 500 17px/24px Sharp Sans No1, Arial, sans-serif;
        display: block;
        height: 24px;
        transition: color 0.45s;
        color: #fff;
    }
    .search__form-error {
        color: #ff001f;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        visibility: hidden;
        box-sizing: border-box;
        height: 99px;
        img {
            position: relative;
            top: 1px;
            margin-right: 6px;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
        }
    }
    .search-results {
        ul {
            list-style: none;
            margin-bottom: 0;
            padding-bottom: 20px;
            li {
                &.h3 {
                    font-size: 24px;
                    line-height: 34px;
                    border-bottom: 1px solid #a4a4a4;
                    padding-bottom: 10px;
                    a {
                        color: #fff;
                        padding-top: 30px;
                        padding-bottom: 20px;
                    }
                    .complex-link {
                        &.-skin-white {
                            .complex-link__line {
                                background: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    a {
        &.return-to-search {
            margin-top: 20px;
        }
    }
    @include medium {
        .search-results ul li.h3 {
            font-size: 30px;
            line-height: 45px;
        }
    }
}