$ns: ".news-tile";

#{$ns} {
    display: flex;
    flex-direction: column;
    background-color: $brandColor2;
    box-shadow: 0 0 0.6rem 0 rgba($brandColor1, 0.03), 0 0.3rem 1.5rem 0 rgba($brandColor1, 0.06);
    border-radius: 0.1rem;
    height: 100%;
    box-sizing: border-box;
    color: $brandColor1;
    transition-property: box-shadow, transform;
    transition-duration: $speed;

    &__preview,
    &__body,
    &__headline,
    &__title {
        display: block;
    }

    &__preview {
        flex: 0 0 auto;
        height: 50%;
  
        @include large {
          height: 45%;
        }
        
        @include xlarge {
          height: 50%;
        }
        
        @include xxlarge {
           height: 52.5%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        } 
    }

    &__body {
        flex: 1 1 auto;
        position: relative;
        margin: 1.9rem 2rem 2.5rem;
        padding-bottom: 3.9rem;

        @include medium {
            margin: 1.4rem 2.9rem 2.1rem;
            padding-bottom: 5.8rem;
        }
    }

    &__headline {
        margin-bottom: 0.6rem;

        @include medium {
            margin-bottom: 0.3rem;
        }
    }

    &__title {
        line-height: 2.6rem;
        font-weight: 500;
        letter-spacing: 0;
        font-size: 1.6rem;

        @include medium {
            font-size: 1.7rem;
        }
    }

    .news-info {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .headline__title:after {
        html.no-mobile & {
            @include fluxBg;
        }
    }

    &__title,
    .headline__title,
    .news-info {
        html.no-mobile & {
            @include fluxText;
        }
    }
}

// isMobile library CSS override 

html.no-mobile #{$ns}:hover {
    box-shadow: 0 0.3rem 2rem 0 rgba($brandColor1, 0.17);
    transform: translateY(-0.5rem);
}

html.no-mobile #{$ns}:not(:hover) .headline__title:after {
    background: $brandColor1;
}
html.no-mobile #{$ns}:not(:hover) #{$ns}__title, html.no-mobile #{$ns}:not(:hover) .headline__title {
    -webkit-text-fill-color: $brandColor1;
}
html.no-mobile #{$ns}:not(:hover) .news-info {
    -webkit-text-fill-color: $brandColor6;
}
html.no-mobile #{$ns}:not(:hover) #{$ns}__title, html.no-mobile #{$ns}:not(:hover) .headline__title, html.no-mobile #{$ns}:not(:hover) .news-info {
    background: none;
    -webkit-background-clip: initial;
}