.quote {
  text-align: center;
  margin: 8.6rem 0 8.6rem;

  @include medium {
    margin: 14.6rem 0 18.9rem;
  }

  &:first-child {
    @include medium {
      margin-top: 10.6rem;
    }
  }
  
  blockquote {
    margin: auto;
    max-width: 103.8rem;

    .quote__content {
      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }
}
