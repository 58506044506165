.work-short {
    &__image {
        img {
            display: block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: auto;
        }

        .cover {
            height: 56rem;

            @include medium {
                height: 50rem;
            }

            @include large {
                height: 68rem; 
            }

            @include xxlarge {
                height: 80rem;
            }

            @include uber {
                height: 110rem;
            }
        }

        .image {
            height: 25rem;

            @include medium {
                height: 40rem;
            }

            @include large {
                height: initial;
            }
        }
    }

    &__text {
        margin: 10.4rem 0 11.7rem;

        @include medium {
            margin: 10.6rem 0 12.3rem;
        }

        @include large {
            margin: 30.6rem 0 23.6rem;
        }

        @include xlarge {
            margin: 26.8rem 0 27.2rem;
        }
    }

    &__quote {
        .container {
            padding: 3rem 0;
            box-sizing: border-box;
            position: relative;
            display: flex;
            align-items: center;
            min-height: 41rem;
            width: 92.2%;

            @include medium {
                min-height: 56.8rem;
            }

            @include large {
                width: 94.043%;
            }

            @include xlarge {
                width: initial;
            }   
        }

        .cover {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            opacity: 1;
            margin: 0 auto;

            @include large {
                opacity: initial;
                margin: initial;
                background-position: 75.5% 50%;
            }

            @include xlarge {
                background-position: initial;
            }

            & + .work-single__quote {
                color: $brandColor2;
            }
        }

        .work-single__quote {
            position: relative;
            z-index: 2;
            margin: auto;

            @include large {
                padding: 0 0 2.6rem 6.5%;
                width: 90%;
            }

            @include xlarge {
                padding: 0 7% 2.6rem 7%;
            }

            &:only-child {
                transform: translateY(-25%);

                @include medium {
                    transform: translateY(-50%);
                }  
            }

            &-text {
                text-align: center;
                width: 80%;
                padding: 0.6rem 0 2.5rem;
                margin: auto;
                text-align: center;

                @include medium {
                    width: initial;
                    padding: 0 0 2.5rem 0;
                }

                @include large {
                    padding: 0 0 5rem 0;
                    margin: initial;
                    text-align: initial;
                }
            }

            &-cite {
                margin: 0 0 1.2rem 50.8%;
                line-height: 1.3;
            }
        }
    }

    &__video {
        width: 100%;
        height: 100%;  
        margin: 10.4rem 0 11.7rem;

        @include medium {
            margin: 10.6rem 0 12.3rem;
        }

        @include large {
            margin: 30.6rem 0 23.6rem;
        }

        @include xlarge {
            margin: 26.8rem 0 27.2rem;
        }
        

        video {
            /* Make video at least 100% wide and tall */
            width: 100%;
        }
    }
}