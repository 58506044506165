.headline {
    display: block;

    &__title,
    &__subtitle {
        font-size: 1.3rem;
        line-height: 1.6rem;

        @include medium {
            font-size: 1.7rem;
            line-height: 2.4rem;
        }
    }

    &__title {
        color: currentColor;
        display: inline-block;
        vertical-align: baseline;
        font-weight: 500;
      
        &:after {
            content: "";
            display: inline-block;
            vertical-align: baseline;
            height: 0.1rem;
            background-color: currentColor;
            width: 2.4rem;
            margin: 0 0.2rem 0 0.7rem;

            @include medium {
                width: 3.1rem;
                margin: 0 0.5rem 0 1rem;
            }
        }
    }

    &__subtitle {
        font-family: $GTAmericaMono;
        color: $brandColor4;
        text-transform: uppercase;
        font-weight: 300;
        font-style: normal;
    }
}
  