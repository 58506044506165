$ns: ".grid";

#{$ns} {
    &__row {
        margin-bottom: 1rem;
    }

    &.-spacious &__row:first-child {
        margin-bottom: 3.4rem;
    }

    @include medium {
        display: flex;
        flex-wrap: wrap;
        margin: -0.5rem;

        &__row {
            box-sizing: border-box;
            padding: 0.5rem;
        }

        &.-spacious &__row:first-child {
            margin-bottom: 0;
        }

        &.-centered {
            align-items: center;
        }

        &.-reverse {
            flex-direction: row-reverse;

            #{$ns}.-delimited {

                &:first-child {
                    padding-left: 8.75%;
                }

                &:last-child {
                    padding-right: 8.75%;
                }
            }
        }

        &:not(.-reverse) {
            #{$ns}.-delimited {

                &:first-child {
                    padding-right: 8.75%;
                }

                &:last-child {
                    padding-left: 8.75%;
                }
            }
        }

        &.-three &__row {
            width: 33.33333% 
        }

        &.-two &__row {
            width: 50%;
        }
    }

    @include tablet {
        &.-two-tablet &__row {
            width: 50%;
        }
    }

    &__row {
        > img,
        > svg {
            display: block;
            max-width: 100%;
        }
    }

    &.-scrollable {

        @include tablet {
            margin-right: -5vw;
            margin-left: -5vw;
          
            &:before, &:after {
                width: 5vw;
            }
          
            #{$ns}__row {
                width: 75vw;
            }
        }

        @include belowlarge {
            overflow: auto;
            overflow-y: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            display: flex;
            flex-wrap: nowrap;
            padding-bottom: 1rem;
            margin-bottom: -1rem;

            &:before, &:after {
                content: "";
                display: block;
                flex: 0 0 auto;
            }

            #{$ns} {
                &__row {
                    flex: 0 0 auto;
                    width: calc(100vw - 4.4rem);
                    padding-left: 0;
                    padding-right: 0;

                    + #{$ns}__row {
                        margin-left: 1rem;
                    }
                }
            }
        }

        @include belowmedium {
            margin-right: -2.2rem;
            margin-left: -2.2rem;

            &:before, &:after {
                width: 2.2rem;
            }

            #{$ns} {
                &__row {
                    width: calc(100vw - 4.4rem);
                }
            }
        }
    }
}

