@include webfont('Sharp Sans No1', 'SharpSansNo1-Light', 300);
@include webfont('Sharp Sans No1', 'SharpSansNo1-Book');
@include webfont('Sharp Sans No1', 'SharpSansNo1-Medium', 500);
@include webfont('Sharp Sans No1', 'SharpSansNo1-Semibold', 600);
@include webfont('Sharp Sans No1', 'SharpSansNo1-Bold', 700);
@include webfont('Sharp Sans No1', 'SharpSansNo1-Extrabold', 800);
@include webfont('Druk Wide', 'DrukWide-Bold', 700);
@include webfont('GT America Mono', 'gt-america-mono-light', 300, 'normal');
@include webfont('GT America Mono', 'gt-america-mono-regular-italic', 400, 'italic');
@include webfont('Ogg', 'Ogg-Roman');

$spacerPrimary: #161616;

@keyframes gradient {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -5% 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
	&,
	body {
		background-color: $brandColor8;
	}

	&.page-landing {
		&,
		body {
			background-color: $brandColor2;
		}
	}
}

html {
	&.is-scrolling {
		&,
		* {
			pointer-events: none !important;
		}
	}
}

body {
	font-weight: 500;
	font-family: $SharpSans;
	color: $text;
	font-size: 1.7rem;
	line-height: 2.7rem;

	@include medium {
		font-size: 2rem;
		line-height: 1.8em;
	}
}

a {
  text-decoration: none;
}

.layout {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: $brandColor8;
}

html.is-combined-homepage {
	.layout {
		transition: transform $speed * 5;
	}

	&.is-combined-homepage-wave {
		will-change: transform;
		position: fixed;

		.layout {
			transform: translateY(100vh);
		}
	}

	&.is-combined-homepage-altering {
		position: fixed;

		&,
		* {
			pointer-events: none;
		}
	}

	&.is-combined-homepage-altering-down .layout {
		transform: none;
	}

	&.is-combined-homepage-altering-up {
		position: fixed;

		.layout {
			transform: translateY(100vh);
		}
	}

	&.is-combined-homepage-content .landing {
		visibility: hidden;
		z-index: -1;
	}

	&.is-combined-homepage-prerender .layout {
		transition-duration: 100ms !important;
		z-index: -1 !important;

		&,
		* {
			pointer-events: none;
		}
	}
}

.container {
	position: relative;
	margin: auto;
	max-width: 126.8rem;
	box-sizing: border-box;
	width: 100%;
	padding: 0 2.2rem;

	@include medium {
		width: 90%;
		padding: 0;
	}
}

.hidden {
	display: none !important;

	&-b-sm {
		display: none !important;
	}

	&-b-m {
		display: none !important;
	}

	&-b-t {
		display: none !important;
	}

	&-b-d {
		display: none !important;
	}

	@include smedium {
		&-a-sm {
			display: none !important;
		}

		&-b-sm {
			display: block !important;
		}
	}

	@include medium {
		&-a-m {
			display: none !important;
		}

		&-b-m {
			display: block !important;
		}
	}

	@include large {
		&-a-t {
			display: none !important;
		}

		&-b-t {
			display: block !important;
		}
	}

	@include xxlarge {
		&-a-d {
			display: none !important;
		}

		&-b-d {
			display: block !important;
		}
	}
}

.hr {
	margin: 8.2rem 0 9.7rem;

	@include medium {
		margin: 19rem 0 18.8rem;
	}

	&__container {
		height: 0.1rem;
		background-color: rgba($brandColor1, 0.1);
	}
}

.ico {
  font: 0/0 'a';
  display: inline-block;
  vertical-align: middle;
  flex: 0 0 auto;

  svg {
	width: 100%;
	height: 100%;
	transition-duration: $speed;
	transition-property: fill, stroke, color;
	fill: currentcolor;

	[fill] {
		fill: currentcolor;
	}

	[stroke] {
		stroke: currentcolor;
	}

	&:not(:root) {
		overflow: hidden;
	}
  }
}

.js-hover {
	* {
		pointer-events: none;
	}

	&.-animate .js-hover__line {
		transition: transform $speed * 3;
	}

	&.-animate-in .js-hover__line {
		transform: translateX(0);
	}

	&.-animate-out .js-hover__line {
		transform: translateX(105%);
	}

	&.-preactivated {
		.js-hover__line {
			transition: opacity $speed;
			transform: translateX(0) !important;
		}	

		html.no-mobile a.js-hover.-preactivated:hover .js-hover__line {
			opacity: 0;
		}
	}

	html.no-mobile .js-hover:active .js-hover__line {
		transition-duration: $speed;
		transform: translateX(1rem);
	}  

	&__container {
		position: relative;
		overflow: hidden;
	}

	&__line {
		transform: translateX(-105%);

		&.-multi {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;

			i {
				@include fluxBg;
				display: inline-block;
				vertical-align: bottom;
				width: 0;
				height: 0.1rem;
			}
		}
	}

	&__rect-arrow:after {
		content: ">";
		display: block;
		font-size: 2.5rem;
	}
} 

.spacer {
	display: block;
	border: none;
	background: none;
	width: 100%;
	height: 0.1rem;
	font-size: 0;
	line-height: 0;
	padding: 0;
	margin: 0;

	&.-black {
		background-color: $spacerPrimary;
	}
}

